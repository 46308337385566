import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'hooks/redux'
import { getImpersonateLogin } from 'store/slice/loginSlice'
import { getInit, setCurrentOfficeTests } from 'store/slice/initSlice'
import { useLazyGetDmeOfficeTestsQuery } from 'store/services/office'
import * as authHelper from 'helpers/authHelper'

export const useImpersonation = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [getDmeOffice] = useLazyGetDmeOfficeTestsQuery()

  const impersonate = async (officeId: string) => {
    await dispatch(getImpersonateLogin({ officeId }))
    const initData = await dispatch(getInit())
    getDmeOffice({ id: Number(initData?.payload?.primaryOffice) })
      .unwrap()
      .then((tests) => dispatch(setCurrentOfficeTests(tests)))
    navigate('/dme')
  }

  const stopImpersonate = async () => {
    await authHelper.revertImpersonateToken()
    await dispatch(getInit())
    navigate('/lab')
  }

  return { impersonate, stopImpersonate }
}
