import routes from './routes'
import createQueryString from 'components/customHooks/useQueryString'

export const MenuData = [
  {
    type: 'button',
    icon: 'fal fa-tachometer-alt',
    label: 'Dashboard / Statistics',
    to: `/`,
    extraStyle: { backgroundColor: 'unset', border: 'none' },
  },
  {
    to: `${routes.index}${routes.patient.root}`,
    label: 'Patients Directory',
    open: false,
    // linkClass: 'active',
  },
  {
    type: 'button',
    label: 'New Order',
    to: `${routes.index}${routes.order.newOrder}`,
    linkClass: 'active',
  },
  {
    label: 'Need Attention',
    open: true,
    alwaysOpen: true,
    to: false,
    menu: 'needAttention',
    sub: [
      {
        label: 'Unread Messages',
        to: `${routes.index}${routes.order.rootHub}?bucket=UNREAD_DMS&testType=ALL`,
        badgeParams: routes.order.all.unreadMessages,
        badgeClass: 'badge-danger',
        labelClass: 'ml-2 pl-0',
        menu: 'UNREAD_DMS',
      },
      {
        label: 'Lab Verification Failed',
        to: `${routes.index}${routes.order.rootHub}?bucket=FAILED_VERIFICATION&testType=ALL`,
        badgeParams: routes.order.all.failedVerification,
        badgeClass: 'badge-danger',
        labelClass: 'ml-2 pl-0',
        menu: 'FAILED_VERIFICATION',
      },
    ],
  },
  {
    label: 'Pulse-Ox',
    open: true,
    to: false,
    menu: 'pulseox',
    linkClass: 'border-top active',
    sub: [
      {
        label: 'Active',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.pulseOx.activeOrders,
        )}`,
        badgeParams: routes.order.pulseOx.activeOrders,
        badgeClass: 'badge-warning',
        menu: 'pulseox--active-orders',
      },
      {
        label: 'Open',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.pulseOx.open,
        )}`,
        badgeParams: routes.order.pulseOx.open,
        badgeClass: 'badge-warning',
        labelClass: 'ml-2',
        menu: 'pulseox--open',
      },
      // {
      //   label: 'Driver Delivery',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.pulseOx.driverDelivery,
      //   )}`,
      //   badgeParams: routes.order.pulseOx.driverDelivery,
      //   badgeClass: 'badge-warning',
      //   labelClass: 'ml-2',
      //   menu: 'pulseox--user-delivery',
      // },
      // {
      //   label: 'Patient Pick Up',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.pulseOx.patientPickup,
      //   )}`,
      //   badgeParams: routes.order.pulseOx.patientPickup,
      //   badgeClass: 'badge-warning',
      //   labelClass: 'ml-2',
      //   menu: 'pulseox--patient-pickup',
      // },
      {
        label: 'Patient Testing',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.pulseOx.patientTesting,
        )}`,
        badgeParams: routes.order.pulseOx.patientTesting,
        badgeClass: 'badge-warning',
        labelClass: 'ml-2',
        menu: 'pulseox--patient-testing',
      },
      // {
      //   label: 'Driver Pick Up',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.pulseOx.driverPickup,
      //   )}`,
      //   badgeParams: routes.order.pulseOx.driverPickup,
      //   badgeClass: 'badge-warning',
      //   labelClass: 'ml-2',
      //   menu: 'pulseox--driver-pickup',
      // },
      // {
      //   label: 'Patient Drop Off',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.pulseOx.patientDropoff,
      //   )}`,
      //   badgeParams: routes.order.pulseOx.patientDropoff,
      //   badgeClass: 'badge-warning',
      //   labelClass: 'ml-2',
      //   menu: 'pulseox--patient-drop-off',
      // },
      {
        label: 'Finalize Orders',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.pulseOx.finalizeOrder,
        )}`,
        badgeParams: routes.order.pulseOx.finalizeOrder,
        badgeClass: 'badge-danger',
        labelClass: 'ml-2',
        menu: 'pulseox--finalize-orders',
      },
      // {
      //   label: 'NightOwl',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.pulseOx.nightowl,
      //   )}`,
      //   badgeParams: routes.order.pulseOx.nightowl,
      //   badgeClass: 'badge-info',
      //   menu: 'pulseox--nightowl',
      // },
      {
        label: 'Completed',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.pulseOx.completed,
        )}`,
        badgeParams: routes.order.pulseOx.completed,
        badgeClass: 'badge-info',
        menu: 'pulseox--completed',
      },
      {
        label: 'Cancelled',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.pulseOx.cancelled,
        )}`,
        badgeParams: routes.order.pulseOx.cancelled,
        badgeClass: 'badge-info',
        menu: 'pulseox--cancelled',
      },
      // {
      //   label: 'Archived',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.pulseOx.archived,
      //   )}`,
      //   badgeParams: routes.order.pulseOx.archived,
      //   badgeClass: 'badge-info',
      //   menu: 'pulseox--archived',
      // },
      {
        label: 'All Orders',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(routes.order.pulseOx.all)}`,
        badgeParams: routes.order.pulseOx.all,
        badgeClass: 'badge-info',
        menu: 'pulseox--all',
      },
    ],
  },
  {
    label: 'Capnography',
    open: false,
    to: false,
    menu: 'capno',
    linkClass: 'border-top active',
    sub: [
      {
        label: 'Active',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.capno.activeOrders,
        )}`,
        badgeParams: routes.order.capno.activeOrders,
        badgeClass: 'badge-warning',
        menu: 'capno--active-orders',
      },
      {
        label: 'Open',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(routes.order.capno.open)}`,
        badgeParams: routes.order.capno.open,
        badgeClass: 'badge-warning',
        menu: 'capno--open',
        labelClass: 'ml-2',
      },
      // {
      //   label: 'Driver Delivery',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.capno.driverDelivery,
      //   )}`,
      //   badgeParams: routes.order.capno.driverDelivery,
      //   menu: 'capno--driver-delivery',
      // },
      // {
      //   label: 'Patient Pick Up',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.capno.patientPickup,
      //   )}`,
      //   badgeParams: routes.order.capno.patientPickup,
      //   menu: 'capno--patient-pickup',
      // },
      {
        label: 'Patient Testing',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.capno.patientTesting,
        )}`,
        badgeParams: routes.order.capno.patientTesting,
        badgeClass: 'badge-warning',
        menu: 'capno--patient-testing',
        labelClass: 'ml-2',
      },
      // {
      //   label: 'Driver Pick Up',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.capno.driverPickup,
      //   )}`,
      //   badgeParams: routes.order.capno.driverPickup,
      //   menu: 'capno--driver-pickup',
      // },
      // {
      //   label: 'Patient Drop Off',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.capno.patientDropoff,
      //   )}`,
      //   badgeParams: routes.order.capno.patientDropoff,
      //   menu: 'capno--patient-dropoff',
      // },
      {
        label: 'Finalize Orders',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.capno.finalizeOrder,
        )}`,
        badgeParams: routes.order.capno.finalizeOrder,
        badgeClass: 'badge-warning',
        menu: 'capno--finalize-orders',
        labelClass: 'ml-2',
      },
      {
        label: 'Completed',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.capno.completed,
        )}`,
        badgeParams: routes.order.capno.completed,
        badgeClass: 'badge-info',
        menu: 'capno--completed',
      },
      {
        label: 'Cancelled',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.capno.cancelled,
        )}`,
        badgeParams: routes.order.capno.cancelled,
        badgeClass: 'badge-info',
        menu: 'capno--cancelled',
      },
      // {
      //   label: 'Archived',
      //   to: `${routes.index}${routes.order.rootHub}?${createQueryString(
      //     routes.order.capno.archived,
      //   )}`,
      //   badgeParams: routes.order.capno.archived,
      //   badgeClass: 'badge-info',
      //   menu: 'capno--archived',
      // },
      {
        label: 'All Orders',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(routes.order.capno.all)}`,
        badgeParams: routes.order.capno.all,
        badgeClass: 'badge-info',
        menu: 'capno--all',
      },
    ],
  },
  {
    label: 'Sleep Testing',
    open: false,
    to: false,
    menu: 'hst',
    linkClass: 'border-top active',
    sub: [
      {
        label: 'Open',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(routes.order.hst.open)}`,
        badgeParams: routes.order.hst.open,
        badgeClass: 'badge-warning',
        labelClass: 'ml-2',
        menu: 'hst--open',
      },
      {
        label: 'Pending Verification',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.hst.verifyingPatient,
        )}`,
        badgeParams: routes.order.hst.verifyingPatient,
        badgeClass: 'badge-warning',
        labelClass: 'ml-2',
        menu: 'hst--verifying',
      },
      {
        label: 'Processing',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.hst.processing,
        )}`,
        badgeParams: routes.order.hst.processing,
        badgeClass: 'badge-warning',
        labelClass: 'ml-2',
        menu: 'hst--processing',
      },
      {
        label: 'Report Complete',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(
          routes.order.hst.reportComplete,
        )}`,
        badgeParams: routes.order.hst.reportComplete,
        badgeClass: 'badge-info',
        menu: 'hst--report-complete',
      },
      {
        label: 'All',
        to: `${routes.index}${routes.order.rootHub}?${createQueryString(routes.order.hst.all)}`,
        badgeParams: routes.order.hst.all,
        badgeClass: 'badge-info',
        menu: 'hst--all',
      },
    ],
  },
  {
    type: 'hr',
    className: 'mt-1 mb-2 border-light',
  },
  {
    label: 'Users',
    icon: 'fal fa-hdd',
    to: `${routes.index}${routes.user.root}`,
    linkClass: 'active',
  },
  {
    label: 'Device Inventory',
    icon: 'fal fa-hdd',
    to: `${routes.index}${routes.device.deviceDirectory}`,
    badgeClass: 'badge-warning',
    badgeParams: 'activeDevices',
  },
  {
    label: 'Device Reset',
    icon: 'fal fa-hdd',
    to: `${routes.index}${routes.device.deviceReset}`,
  },
  {
    label: 'Printable Forms',
    icon: 'fal fa-hdd',
    linkClass: 'active',
    to: `${routes.index}${routes.printableForms.root}`,
  },
  {
    label: 'Physician Management',
    open: false,
    linkClass: 'active',
    to: `${routes.index}${routes.physician.root}`,
  },
  {
    label: 'Help & Support',
    open: false,
    to: false,
    linkClass: 'active',
    sub: [
      {
        to: `https://testsmarter.screenconnect.com`,
        label: 'Remote Assistance',
        labelClass: 'ml-2',
      },
      {
        to: `${routes.index}${routes.ticket.root}`,
        label: 'Support Tickets',
        labelClass: 'ml-2',
      },
      // {
      //   to: `${routes.index}${routes.help.helpRemote}`,
      //   label: 'Remote Support',
      //   labelClass: 'ml-2',
      // },
      // {
      //   to: `${routes.index}${routes.help.helpKnowledge}`,
      //   label: 'Knowledge Base',
      //   labelClass: 'ml-2',
      // },
      {
        to: `${routes.index}${routes.help.helpDownload}`,
        label: 'Downloads',
        labelClass: 'ml-2',
      },
      {
        to: `${routes.index}${routes.help.videos}`,
        label: 'Videos',
        labelClass: 'ml-2',
      },
    ],
  },
]
