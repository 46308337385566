import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'helpers/api'
import * as authHelper from 'helpers/authHelper'
import { baseApi } from 'store/services/baseApi'
import { userApi } from 'store/services/users'

// ------------------------------------------------------------
export const getLogin = createAsyncThunk(
  'login',
  async (params: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const data = await api.post('/jwt/login', params)
      console.log(data)
      if (data) {
        authHelper.setToken(data)
      } else {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
      if ([401, 404].includes(error?.response?.status)) {
        throw new Error('Invalid Credentials')
      } else {
        throw new Error(error?.message)
      }
    }
  },
)

export const getImpersonateLogin = createAsyncThunk(
  'impersonateLogin',
  async (params: { officeId: string }, { rejectWithValue }) => {
    try {
      const data = await api.post('/jwt/impersonate', params)
      console.log(data)
      if (data) {
        authHelper.setImpersonateToken(data)
      } else {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
      if ([401, 404].includes(error?.response?.status)) {
        throw new Error('No allowed to impersonate')
      } else {
        throw new Error(error?.message)
      }
    }
  },
)

export const getLoginWithNonce = createAsyncThunk(
  'loginWithNonce',
  async (params: { nonce: string }, { rejectWithValue }) => {
    try {
      const data = await api.post(`/lab/users/password-reset/auth`, params)
      if (data) {
        authHelper.setToken(data)
      } else {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        throw new Error('Invalid Authentication')
      }
      console.log('error', error)
    }
  },
)

export const getLogout = createAsyncThunk('logout', async (_, { dispatch }) => {
  try {
    if (localStorage.getItem('access_token') || localStorage.getItem('lab_access_token')) {
      dispatch(userApi.endpoints.logout.initiate())
    }
    authHelper.logout()
    sessionStorage.removeItem('ts-dme-menu')
    sessionStorage.removeItem('ts-lab-menu')
    return dispatch(baseApi.util.resetApiState())
  } catch (error) {
    console.log('error', error)
  }
})

export const setLang = createAsyncThunk('setLang', async (lang) => {
  try {
    return lang
  } catch (error) {
    console.log('error', error)
  }
})

// ------------------------------------------------------------
interface LoginSliceState {
  pending: boolean
  rejected: boolean
  impersonate: boolean
  error: ''
  data?: {
    language?: string
    access_token?: string
  }
}
const initialState: LoginSliceState = {
  impersonate: false,
  pending: false,
  data: {},
  rejected: false,
  error: '',
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLogin.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.error = action?.error?.message
      state.data = undefined
    })
    builder.addCase(getLogin.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.data = undefined
      state.impersonate = false
    })
    builder.addCase(getLogin.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.data = action.payload
    })
    builder.addCase(getImpersonateLogin.fulfilled, (state, action) => {
      state.impersonate = true
    })
    builder.addCase(getLoginWithNonce.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.data = undefined
      state.error = ''
    })
    builder.addCase(getLoginWithNonce.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.data = action.payload
    })
    builder.addCase(getLoginWithNonce.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.error = action?.error?.message
      state.data = undefined
    })
    builder.addCase(getLogout.fulfilled, (state, action) => {
      return { ...initialState }
    })
    builder.addCase(setLang.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.data = undefined
    })
    builder.addCase(setLang.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.data = undefined
    })
    builder.addCase(setLang.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.data.language = action.payload
    })
  },
})

export default localSlice.reducer
