const routes = {
  landing: {
    root: 'landing/',
    verifyContract: 'verify-contract/',
  },
  index: '/dme/',
  login: 'login/',
  upsert: 'upsert/',
  profile: {
    root: 'profile/',
  },
  route: {
    root: 'route/',
    edit: 'edit/',
  },
  manager: {
    root: 'manager/',
    role: 'managerRole',
  },
  user: {
    root: 'user',
    edit: 'userEdit/',
  },
  offices: {
    root: 'offices/',
    officeDetail: 'officeDetail/',
  },
  patient: {
    root: 'patient',
    edit: 'patientEdit/',
    new: 'newPatient',
  },
  device: {
    root: 'device/',
    deviceReset: 'deviceReset/',
    deviceCategory: 'deviceCategory/',
    deviceDirectory: 'deviceDirectory/',
    deviceDetail: 'deviceDetailed/',
  },
  printableForms: {
    root: 'printable-forms/',
  },
  help: {
    root: 'help/',
    helpRemote: 'helpRemote/',
    helpKnowledge: 'helpKnowledge/',
    helpDownload: 'helpDownload/',
    videos: 'videos/',
  },
  ticket: {
    root: 'ticket',
  },
  order: {
    root: 'order/',
    rootHub: 'order/hub/',
    all: {
      unreadMessages: {
        bucket: 'UNREAD_DMS',
        testType: 'ALL',
      },
      finalizeUpload: {
        bucket: 'FINALIZE_UPLOAD',
        testType: 'ALL',
      },
      failedVerification: {
        bucket: 'FAILED_VERIFICATION',
        testType: 'ALL',
      },
    },
    pulseOx: {
      open: {
        testType: 'POX',
        bucket: 'OPEN',
      },
      driverDelivery: {
        testType: 'POX',
        bucket: 'DRIVER_DELIVERY',
      },
      patientPickup: {
        testType: 'POX',
        bucket: 'PATIENT_PICKUP',
      },
      patientTesting: {
        testType: 'POX',
        bucket: 'PATIENT_TESTING',
      },
      driverPickup: {
        testType: 'POX',
        bucket: 'DRIVER_PICKUP',
      },
      patientDropoff: {
        testType: 'POX',
        bucket: 'PATIENT_DROP_OFF',
      },
      finalizeOrder: {
        testType: 'POX',
        bucket: 'FINALIZE_ORDER',
      },
      activeOrders: {
        testType: 'POX',
        bucket: 'ACTIVE_ORDERS',
      },
      nightowl: {
        testType: 'POX',
        bucket: 'NIGHT_OWL',
      },
      completed: {
        testType: 'POX',
        bucket: 'COMPLETED',
      },
      cancelled: {
        testType: 'POX',
        bucket: 'CANCELLED',
      },
      archived: {
        testType: 'POX',
        bucket: 'ARCHIVED',
      },
      all: {
        testType: 'POX',
        bucket: 'ALL_ORDERS',
      },
    },
    capno: {
      open: {
        testType: 'COX',
        bucket: 'OPEN',
      },
      driverDelivery: {
        testType: 'COX',
        bucket: 'DRIVER_DELIVERY',
      },
      patientPickup: {
        testType: 'COX',
        bucket: 'PATIENT_PICKUP',
      },
      patientTesting: {
        testType: 'COX',
        bucket: 'PATIENT_TESTING',
      },
      driverPickup: {
        testType: 'COX',
        bucket: 'DRIVER_PICKUP',
      },
      patientDropoff: {
        testType: 'COX',
        bucket: 'PATIENT_DROP_OFF',
      },
      finalizeOrder: {
        testType: 'COX',
        bucket: 'FINALIZE_ORDER',
      },
      activeOrders: {
        testType: 'COX',
        bucket: 'ACTIVE_ORDERS',
      },
      nightowl: {
        testType: 'COX',
        bucket: 'NIGHT_OWL',
      },
      completed: {
        testType: 'COX',
        bucket: 'COMPLETED',
      },
      cancelled: {
        testType: 'COX',
        bucket: 'CANCELLED',
      },
      archived: {
        testType: 'COX',
        bucket: 'ARCHIVED',
      },
      all: {
        testType: 'COX',
        bucket: 'ALL_ORDERS',
      },
    },
    hst: {
      open: {
        testType: 'HST',
        bucket: 'OPEN',
      },
      verifyingPatient: {
        testType: 'HST',
        bucket: 'VERIFICATION',
      },
      processing: {
        testType: 'HST',
        bucket: 'PROCESSING',
      },
      reportComplete: {
        testType: 'HST',
        bucket: 'COMPLETED',
      },
      failed: {
        testType: 'HST',
        bucket: 'FAILED',
      },
      all: {
        testType: 'HST',
        bucket: 'ALL_ORDERS',
      },
    },
    add: 'orderAdd/',
    edit: 'orderEdit/',
    test: 'orderTest/',
    physician: 'physician/',
    insurance: 'insurance/',
    patient: 'patient/',
    rx: 'RxForm/',
    aob: 'AOBForm/',
    upload: 'upload/',
    report: 'report/',
    newOrder: 'newOrder/',
  },
  physician: {
    root: 'physician/',
    edit: 'physicianEdit/',
  },
}

export default routes
