/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { useGetPhysicianGroupsListQuery } from "../../../store/services/physician";
import DashboardContent from '../atoms/DashboardContent'
import ButtonNew from '../../atoms/ButtonNew'
import Input from '../../atoms/Input'
import routes from '../constants/routes'

const StyledTableWrapper = styled(TableWrapper)`
  max-height: calc(100vh - 230px);
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PAGE_SIZE = 10

const PhysicianGroupsDirectory = () => {
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const { data: physicianGroups, isLoading } = useGetPhysicianGroupsListQuery({
		...(search && { search }),
		page: currentPage,
		size: PAGE_SIZE,
	})

	const header = [
		{
			accessor: 'id',
			Header: 'Id',
		},
		{ accessor: 'groupName', Header: 'Physician Group' },
		{ accessor: 'npi', Header: 'NPI' },
		{ accessor: 'telephone', Header: 'Phone' },
		{ accessor: 'fax', Header: 'Fax' },
		{
			accessor: 'address',
			Header: 'Address',
			Cell: ({ row }) => (
				<div className="d-flex flex-column">
          <span>
            {row.original.addressStreetLine1} {row.original.addressStreetLine2}
          </span>
					<span>
            {row.original.addressCity}, {row.original.addressState} {row.original.addressZip}
          </span>
				</div>
			),
		},
	]

	const onOpenPhysicianGroup = (id: number) => {
		navigate(`${routes.index}${routes.physicianGroups.root}${routes.upsert}${id}`)
	}

	return (
		<DashboardContent
			title="Physician Groups"
			icon="fas fa-building"
			content={
				<div className="card card-primary">
					<form>
						<div className="card-header row">
							<div className="col-10 input-group mb-3">
								<Input
									autoFocus={true}
									value={search}
									label={false}
									placeholder="Search..."
									name="search"
									parentDiv={null}
									handleChange={(event) => setSearch(event.target.value)}
								/>

								<div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-search" />
                  </span>
								</div>
							</div>

							<div className="col-2 text-right">
								<ButtonNew to={`${routes.index}${routes.physicianGroups.root}${routes.upsert}`} />
							</div>
						</div>
					</form>
					<div className="card-body align-items-center">
						<StyledTableWrapper className="card-body row">
							<DataTable
								data={physicianGroups}
								status={isLoading}
								tableColumns={header}
								pageSize={PAGE_SIZE}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								setSortOptions={() => {}}
								entityName="physician groups"
								onRowClick={(row) => onOpenPhysicianGroup(row.original.id)}
							/>
						</StyledTableWrapper>
					</div>
				</div>
			}
			breadcrumb={[
				{
					label: 'Physician Groups',
				},
			]}
		/>
	)
}

export default PhysicianGroupsDirectory
