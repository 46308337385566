import { useState } from 'react'
import { useParams } from 'react-router-dom'

import DobUpsert from '../verifyContract/DobUpsert'
import Loading from 'components/atoms/Loading'
import * as api from 'helpers/api'
import { tsOfficePhone } from 'constants/misc'

interface IProps {
  nextStep: () => void
  getOrder: (id: number) => void
}

const Verify = ({ nextStep, getOrder }: IProps) => {
  const { orderId, nonce } = useParams()
  const [error, setError] = useState('')

  const verifyDob = async (dob: string) => {
    try {
      setError('')
      const result = await api.get(`/lab/orders/${orderId}/paperwork/${nonce}`, {
        dob,
      })
      if (result?.access_token) {
        localStorage.setItem('access_token', result.access_token)
        await getOrder(Number(orderId))
        nextStep()
      }
    } catch (err) {
      if (err.response.status >= 400 && err.response.status < 500) {
        setError('Invalid date of birth entered.')
      }
      console.log(err)
    }
  }

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3">
        <h2>Home Sleep Test (HST) - Patient Questionnaire and eSignatures</h2>
        <DobUpsert verifyDob={verifyDob} />
        <p className="text-danger">{error}</p>
        <div className="mt-5 mb-5 pt-3 border-top">
          <p>
            <span className="font-weight-bold">Terms and Conditions:</span> Please be aware that
            most standard text messages and emails are not a secure means of communication. This
            means that there is risk that your protected health information can be accessed through
            text messages and be intercepted and read by, or disclosed to, unauthorized third
            parties. Use of alternative and more secure methods of communication with us, such as
            telephone, fax, or the U.S. Postal Service are available to you. If you do not wish to
            accept the risks associated with non-secure unecrypted email communications from us
            containing your protected health information, please indicate that you do not wish to
            receive such text messages from us by calling us at {tsOfficePhone}.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Verify
