import { baseApi } from './baseApi'
import {
  CompaniesListQuery,
  CompanyDto,
  CreateCompanyDto,
  CreateOfficeDto,
  Offices,
  OutputOffice,
  UpdateOfficeDto,
} from 'store/types'

export const officeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CompanyDto, { name?: string; limit?: number }>({
      query: (params) => ({ url: `/lab/companies`, params }),

      providesTags: ['Company'],
    }),
    getCompanyList: builder.query<CompanyDto[], CompaniesListQuery>({
      query: (params) => ({ url: `/lab/companies/list`, params }),
      providesTags: ['Company'],
    }),
    getCompany: builder.query<CompanyDto, { id: number }>({
      query: ({ id }) => ({ url: `/lab/companies/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    getDmeCompany: builder.query<CompanyDto, void>({
      query: () => ({ url: `/dme/company` }),
      providesTags: ['Company'],
    }),
    createCompany: builder.mutation<CompanyDto, CreateCompanyDto>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/companies`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    updateCompany: builder.mutation<CompanyDto, Partial<CompanyDto>>({
      query: (data) => ({
        method: `PUT`,
        url: `/lab/companies/${data.id}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    addCompanyRegion: builder.mutation<void, { id: number; regionName: string }>({
      query: ({ id, regionName }) => ({
        method: `POST`,
        url: `/lab/companies/${id}/regions`,
        body: { regionName },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    updateCompanyRegion: builder.mutation<
      void,
      { id: number; data: { uuid: string; regionName: string } }
    >({
      query: ({ id, data }) => ({
        method: `PUT`,
        url: `/lab/companies/${id}/regions/${data.uuid}`,
        body: { regionName: data.regionName },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    deleteCompanyRegion: builder.mutation<void, { id: number; uuid: string }>({
      query: ({ id, uuid }) => ({
        method: `DELETE`,
        url: `/lab/companies/${id}/regions/${uuid}`,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    getLabOffices: builder.query<Offices, { name?: string; npi?: string; companyId?: number }>({
      query: (params) => ({ url: `/lab/offices`, params }),
      providesTags: ['Office'],
    }),
    getLabOfficeList: builder.query<
      Offices,
      { name?: string; npi?: string; companyId?: number; page: number }
    >({
      query: (params) => ({ url: `/lab/offices/list`, params }),
      providesTags: ['OfficeList'],
    }),
    getLabOffice: builder.query<OutputOffice, { id: number }>({
      query: ({ id }) => ({ url: `/lab/offices/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'Office', id }],
    }),
    createOffice: builder.mutation<OutputOffice, CreateOfficeDto>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/offices`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Office', id }, { type: 'OfficeList' }],
    }),
    updateOffice: builder.mutation<OutputOffice, UpdateOfficeDto>({
      query: (data) => ({
        method: `PUT`,
        url: `/lab/offices/${data.id}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Office', id }, { type: 'OfficeList' }],
    }),
    getOffices: builder.query<Offices, { company_id: number }>({
      query: ({ company_id }) => ({ url: `/dme/office`, params: { company_id } }),
    }),
    getDmeOffices: builder.query<Offices, void>({
      query: () => ({ url: `/dme/offices` }),
    }),
    getOfficeDocument: builder.query<string, { officeId: number; documentKey: string }>({
      query: ({ officeId, documentKey }) => ({
        url: `/dme/office/${officeId}/documents`,
        params: { documentKey },
      }),
    }),
    getDmeOfficeTests: builder.query<OutputOffice, { id: number }>({
      query: ({ id }) => ({ url: `/dme/offices/${id}/test-types` }),
      providesTags: (result, error, { id }) => [{ type: 'Office', id }],
    }),
    updateOfficeAvailableTests: builder.mutation<
      void,
      { id: number; availableTests: { POX: boolean; COX: boolean; HST: boolean } }
    >({
      query: ({ id, availableTests }) => ({
        method: `PUT`,
        url: `/lab/offices/${id}`,
        body: {
          id,
          availableTests,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Office', id }],
    }),
    deleteOffice: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: `DELETE`,
        url: `/lab/offices/${id}`,
      }),
      invalidatesTags: ['OfficeList'],
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useGetCompanyListQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useAddCompanyRegionMutation,
  useUpdateCompanyRegionMutation,
  useDeleteCompanyRegionMutation,
  useGetOfficesQuery,
  useGetLabOfficesQuery,
  useGetLabOfficeListQuery,
  useGetLabOfficeQuery,
  useGetOfficeDocumentQuery,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
  useUpdateCompanyMutation,
  useUpdateOfficeAvailableTestsMutation,
  useDeleteOfficeMutation,
  useGetDmeOfficeTestsQuery,
  useLazyGetDmeOfficeTestsQuery,
  useGetDmeOfficesQuery,
  useGetDmeCompanyQuery,
} = officeApi
