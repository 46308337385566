/** @jsxImportSource @emotion/react */
import { Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import {
  useCapturePatientConsentMutation,
  useSetOrderFollowUpDateMutation,
} from 'store/services/labOrder'
import { useSendLabNoteMutation } from 'store/services/notes'
import { Order, FollowUpNamespace } from 'store/types'
import { FollowUpForm } from './FollowUpForm'
import PageIcon from 'assets/img/page-icon.svg'

const formValidation = Yup.object().shape({
  followUpDate: Yup.date().required('Required'),
  notes: Yup.string(),
})

interface IProps {
  order: Order
}

export const CaptureConsent = ({ order }: IProps) => {
  const [capturePatientConsent] = useCapturePatientConsentMutation()
  const [setOrderFollowUpDate] = useSetOrderFollowUpDateMutation()
  const [sendLabNote] = useSendLabNoteMutation()

  const followUps = order?.followUpsList
    ?.filter((f) => f.namespace === FollowUpNamespace.HST_PATIENT_CONSENT)
    ?.sort((a, b) => moment(b.addedAt).diff(moment(a.addedAt)))

  const followUpDate = followUps?.[0]?.date ? moment(followUps?.[0]?.date).toDate() : ''

  const captureConsent = (value: 'PROVIDED' | 'DECLINED') => () =>
    capturePatientConsent({
      orderId: Number(order.id),
      result: 'PROVIDED',
    })

  return (
    <div className="w-100" css={{ marginLeft: 0 }}>
      <div className="row">
        <div className="col-sm-10 p-0 d-flex align-items-center">
          <h4 className="m-0">Capture Patient Consent</h4>
          <Button variant="primary" className="ml-3" onClick={captureConsent('PROVIDED')}>
            <i className="fas fa-check-circle mr-1" /> Patient Agreed to Proceed
          </Button>
          {/* <Button variant="outline-danger" className="ml-3" onClick={captureConsent('DECLINED')}>
            <i className="fas fa-minus-circle mr-1" /> Patient Declined
          </Button> */}
        </div>
      </div>
      <div className="row mt-2" css={{ background: '#F5F5F5' }}>
        <div className="col-lg-4 pt-3">
          <Formik
            enableReinitialize={true}
            validationSchema={formValidation}
            initialValues={{
              followUpDate,
              notes: '',
            }}
            onSubmit={async (values, { resetForm }) => {
              await Promise.all([
                setOrderFollowUpDate({
                  orderId: Number(order.id),
                  namespace: FollowUpNamespace.HST_PATIENT_CONSENT,
                  date: moment(values.followUpDate).format('YYYY-MM-DD'),
                  comment: values.notes,
                }).unwrap(),
                await sendLabNote({
                  orderId: Number(order.id),
                  text: `${values.notes} (follow-up: ${moment(values.followUpDate).format(
                    'MM/DD/YYYY',
                  )})`,
                }).unwrap(),
              ])
              resetForm()
            }}>
            {({ values, setFieldValue, errors, touched }) => (
              <FollowUpForm {...{ values, setFieldValue, errors, touched }} />
            )}
          </Formik>
        </div>
        <div className="col-lg-8 p-3">
          <div
            className="p-3"
            css={{ backgroundColor: '#00000005', maxHeight: 200, overflowY: 'auto' }}>
            <h4>Notes</h4>
            {followUps
              ?.filter((i) => i.comment)
              ?.map((f) => (
                <div
                  key={f.id}
                  className="p-2 mt-1"
                  css={{ backgroundColor: '#C2C8CF99', borderRadius: 5 }}>
                  <img src={PageIcon} className="mr-2" alt="icon" />
                  <span className="mr-2" css={{ opacity: 0.5, fontWeight: '600' }}>
                    {moment(f.addedAt).format('MM/DD/YYYY hh:mm a')}
                  </span>
                  Note: {f.comment}. Follow-up Date: {moment(f.date).format('MM/DD/YYYY')}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
