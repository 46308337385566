/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import Typography from '@mui/material/Typography'

import { useAppDispatch } from 'hooks/redux'
import OrderTestModal from './OrderTest.modal'
import ButtonModal from 'components/atoms/ButtonModal'
import { setModal } from 'components/../store/slice/modalSlice'
import { Order } from 'store/types'

interface IProps {
  order: Order
}
const testNames = {
  HST: 'Home Sleep Test',
  POX: 'Pulse Oximetry',
  COX: 'Capnography',
}

const TestConditionWidget = ({ order = {} }: IProps) => {
  const dispatch = useAppDispatch()
  const [modalKey, setModalKey] = useState<number>(0)
  const { tests, reports } = order
  const editDisabled = !!Object.keys(order?.reports || {})?.length
  const testName = testNames[order?.testType]

  const openOrderTestModal = (id: string) => {
    setModalKey((prev) => prev + 1)
    dispatch(
      setModal({
        id,
        patient_id: null,
        device_type_id: null,
      }),
    )
  }

  return (
    <div
      className="d-flex flex-column bg-white pr-3 justify-content-between"
      id="testConditions"
      css={{ flex: 1 }}>
      <OrderTestModal
        modalId="order_test_modal"
        title="Order Test"
        order={order}
        modalKey={modalKey}
      />
      <div className="tcWidget_header">
        <Typography sx={{ font: '600 14px/16px Source Sans Pro', marginRight: '12px' }}>
          Test Conditions - {testName}
        </Typography>
        {!editDisabled && (
          <span className="button">
            <ButtonModal
              label="Edit"
              className={`btn btn-outline-primary btn-sm`}
              css={{ paddingTop: 2, paddingBottom: 2, float: 'right' }}
              data_toggle="modal"
              data_target="#order_test_modal"
              onClick={() => {
                openOrderTestModal('order_test_modal')
              }}
            />
          </span>
        )}
      </div>
      <ul css={{ paddingInlineStart: 0 }}>
        {Array.isArray(tests) ? (
          tests.map((t, i) => (
            <li className="tcWidget_item" key={i}>
              <span className="night">{`Night ${i + 1}`}</span>
              <span className={`setting ${!t.oxygen ? 'setting-air' : ''}`}>
                {t.oxygen ? 'OXYGEN' : 'ROOM AIR'}{' '}
                {t.oxygen ? <span className="value">{t.oxygenLpm}</span> : ''}
              </span>
              {t.papType ? (
                <span
                  className={`type ${
                    t.papType === 'BiPAP' ? 'type-bipap' : t.papType === 'CPAP' ? 'type-cpap' : ''
                  }`}>
                  {t.papType}{' '}
                  <span className="value">
                    {t.papType === 'APAP'
                      ? `${t.apapMin} / ${t.apapMax}`
                      : t.papType === 'BiPAP'
                        ? `${t.bipapI} / ${t.bipapE}`
                        : t.papType === 'CPAP'
                          ? `${t.cpap}`
                          : ''}
                  </span>
                </span>
              ) : null}
              {t.deviceType === 'Ventilator' && (
                <span className={`type`}>
                  {t.deviceType} <span className="value">{t.ventilatorType}</span>
                </span>
              )}
              {['Implant', 'Dental'].includes(t.deviceType) && (
                <span className={`type`}>{t.deviceType}</span>
              )}
            </li>
          ))
        ) : (
          <p className="noData">No Nights Currently Added.</p>
        )}
      </ul>
    </div>
  )
}

export default TestConditionWidget
