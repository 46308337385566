import { useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'

import { OrderSettingsProvider, useOrderSettings } from './order-settings/useOrderSettings'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'
import Button from 'components/atoms/Button'
import Toast from 'components/atoms/Toast'
import OrderSettings from './order-settings/OrderSettings'
import PatientInformationReview from './PatientInformationReview'
import PatientFormContainer from '../patient/PatientFormContainer'
import PhysicianInfoContainer from '../physician/PhysicianInfoContainer'
import PayerFormContainer from '../payer/PayerFormContainer'
import * as generalHelper from 'helpers/generalHelper'
import {
  useGetPatientLastOrderQuery,
  useCreateOrderMutation,
  useUpdatePayerMutation,
} from 'store/services/dmeOrder'
import { useCreateLabOrderMutation, useUpdateLabOrderPayerMutation } from 'store/services/labOrder'
import { Patient, Physician, PhysicianAddress, Payer, Order } from 'store/types'
import { useAppSelector } from 'hooks/redux'

interface SelectedPhysician {
  physician: Physician
  physicianLocation: PhysicianAddress
}

interface LocationState {
  activeOrders: Order[]
}

interface IProps {
  context: 'LAB' | 'DME'
}

const NewPatientOrder = ({ context }: IProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = Object.fromEntries(new URLSearchParams(location.search))
  const officeId = useAppSelector((state) => state.init.currentOfficeId) || params.officeId
  const state = location.state as LocationState
  const [locationState] = useState<LocationState>(state)
  const { activeOrders } = locationState || {}
  const activeTests = activeOrders?.map((o) => o.testType)

  const { patientId } = useParams()
  const { state: orderSettings } = useOrderSettings(activeTests)
  const [editSection, setEditSection] = useState(null)
  const [reviewFieldValues, setReviewFieldValues] = useState({
    isPatientCorrect: false,
    isPhysicianCorrect: false,
    isInsuranceCorrect: false,
  })
  const [updatedPatient, setUpdatedPatient] = useState<Patient>()
  const [selectedPhysicianOptions, setSelectedPhysicianOptions] =
    useState<SelectedPhysician | null>(null)
  const [payersSelected, setPayers] = useState<Payer[]>()

  const { data: lastPatientOrder, isLoading } = useGetPatientLastOrderQuery(Number(patientId))
  const [createOrder, { isLoading: isUpdating }] = useCreateOrderMutation()
  const [createLabOrder, { isLoading: isLabOrderUpdating }] = useCreateLabOrderMutation()
  const [updatePayer] = useUpdatePayerMutation()
  const [updateLabOrderPayer] = useUpdateLabOrderPayerMutation()
  const { patient, physician, physicianAddress, payers } = lastPatientOrder || {}

  const patientOverEighteen = moment().diff(patient?.dob, 'years') >= 18

  const onResetEditSection = () => setEditSection(null)

  const onPatientSaved = (values: Patient) => {
    onResetEditSection()
    setUpdatedPatient(values)
    setReviewFieldValues((fields) => ({
      ...fields,
      isPatientCorrect: true,
    }))
  }

  const onPhysicianSaved = (options: SelectedPhysician) => {
    setSelectedPhysicianOptions({
      physician: options.physician,
      physicianLocation: options.physicianLocation,
    })
    onResetEditSection()
    setReviewFieldValues((fields) => ({
      ...fields,
      isPhysicianCorrect: true,
    }))
  }

  const onPayersSaved = (payers: Payer[]) => {
    setPayers(payers)
    onResetEditSection()
    setReviewFieldValues((fields) => ({
      ...fields,
      isInsuranceCorrect: true,
    }))
  }

  const isReadyToSubmit =
    reviewFieldValues.isPatientCorrect &&
    reviewFieldValues.isPhysicianCorrect &&
    reviewFieldValues.isInsuranceCorrect &&
    !editSection &&
    orderSettings.isValid &&
    (patientOverEighteen || orderSettings.testType !== 'HST')

  const onSubmit = async () => {
    try {
      let patientData = { ...(updatedPatient || patient) }
      patientData.dob = moment(patientData.dob).format('YYYY-MM-DD')
      const orderPayload = {
        patient: patientData,
        physician: selectedPhysicianOptions?.physician || physician,
        physicianAddress: selectedPhysicianOptions?.physicianLocation || physicianAddress,
        tests: Object.values(orderSettings.tests).map((test, idx) => ({ ...test, night: idx + 1 })),
        testType: orderSettings.testType,
        officeId,
      }
      const payerPayload = payersSelected || payers

      console.log(orderPayload)
      const { data: newOrder } =
        context === 'DME' ? await createOrder(orderPayload) : await createLabOrder(orderPayload)
      Toast({ type: 'success', label: 'New order created!' })
      if (context === 'LAB') {
        if (payerPayload?.length) {
          await updateLabOrderPayer({
            orderId: newOrder.id,
            payers: payerPayload,
          }).unwrap()
        }

        console.log('navigating to lab', `lab/${routes.order.edit}${newOrder.id}?bucket=OPEN`)
        return navigate(`/lab/${routes.order.edit}${newOrder.id}?bucket=OPEN`)
      }
      if (payerPayload?.length) {
        await updatePayer({
          orderId: newOrder.id,
          payers: payerPayload,
        }).unwrap()
      }

      navigate(`${routes.index}${routes.order.edit}${newOrder.id}/RxForm`)
    } catch (error) {
      console.log(`Error submitting new order and patient info: ${error?.data?.message}`)
      Toast({
        type: 'error',
        label: `Error creating new order: ${error?.data?.message}`,
      })
    }
  }

  return (
    <DashboardContent
      title="New Order"
      subtitle="New Order for Existing Patient"
      content={
        <>
          {/* Patient Form */}
          {editSection === 'patient' && (
            <PatientFormContainer
              patient={updatedPatient || patient}
              onCancel={onResetEditSection}
              onSaved={onPatientSaved}
            />
          )}

          {/* Physician Form */}
          {editSection === 'physician' && (
            <PhysicianInfoContainer
              lastPatientOrder={lastPatientOrder}
              physician={selectedPhysicianOptions?.physician || physician}
              physicianLocation={selectedPhysicianOptions?.physicianLocation || physicianAddress}
              onCancel={onResetEditSection}
              onSaved={onPhysicianSaved}
            />
          )}

          {/* Insurance Form */}
          {editSection === 'insurance' && (
            <PayerFormContainer
              orderPayers={payers}
              onCancel={onResetEditSection}
              onSave={onPayersSaved}
              officeId={officeId}
            />
          )}

          {/* Summary */}
          {!editSection && (
            <PatientInformationReview
              onEditSection={setEditSection}
              patient={updatedPatient || patient}
              orderPayers={payersSelected || payers}
              isPatientLoading={isLoading}
              lastPatientOrder={lastPatientOrder}
              physician={selectedPhysicianOptions?.physician || physician}
              physicianAddress={selectedPhysicianOptions?.physicianLocation || physicianAddress}
              isPayersLoading={isLoading}
              isPhysicianLoading={isLoading}
              reviewFieldValues={reviewFieldValues}
              onReviewChange={setReviewFieldValues}
            />
          )}
          <OrderSettings
            disabled={!!editSection}
            disabledRadios={activeTests}
            officeId={officeId}
          />
        </>
      }
      titleActionButton={
        <div className="d-flex flex-column align-items-end">
          <Button
            disabled={!isReadyToSubmit}
            className="btn btn-danger text-nowrap"
            onClick={onSubmit}
            label={generalHelper.t('Create Order')}
          />
          {!patientOverEighteen && orderSettings.testType === 'HST' && (
            <div className="row mt-2">
              <div className="col-sm-12 d-flex justify-content-end">
                <span className="font-weight-bold text-danger">
                  Cannot order HST if patient is under 18
                </span>
              </div>
            </div>
          )}
        </div>
      }
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        {
          label: 'New Order',
          to: `${routes.index}${routes.order.newOrder}`,
        },
      ]}
    />
  )
}

const NewPatientOrderWrapper = ({ context = 'DME' }: IProps) => {
  return (
    <OrderSettingsProvider>
      <NewPatientOrder context={context} />
    </OrderSettingsProvider>
  )
}

export default NewPatientOrderWrapper
