/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useParams } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import styled from '@emotion/styled'
import Spinner from 'react-bootstrap/Spinner'

import Upload from 'components/atoms/Upload'
import TabHeader from 'components/atoms/TabHeader'
import { setLoader } from 'store/slice/loaderSlice'
import routes from '../../constants/routes'
import { useAddFileMutation } from 'store/services/dmeOrder'
import { AddDocumentModal } from './documents/AddDocumentModal'
import * as api from 'helpers/api'
import { DocumentListing } from './documents/DocumentListing'
import config from 'config/app'

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0.55rem 1rem;
`
interface IProps {
  order: any
  stateHelpers: any
}

const visibleFileTypes = ['RX', 'AOB', 'OFFICE_NOTES', 'BRIGHTREE_DOCS']

const DocumentsTab = ({ order, stateHelpers }: IProps) => {
  const { orderId } = useParams()
  const [addDocOpen, setAddDocOpen] = useState(false)
  const [uploadType, setUploadType] = useState('')
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.loader.row)
  const [addFile, result] = useAddFileMutation()

  const uploadRef = useRef<HTMLInputElement>()
  const files = order?.files
    ? Object.values(order.files).filter((i) => visibleFileTypes.includes(i.uploadType))
    : []

  console.log(files)
  const rxUploaded = !!files.find((file) => file.uploadType === 'RX')
  const aobUploaded = !!files.find((file) => file.uploadType === 'AOB')
  const officeNotesUploaded = !!files.find((file) => file.uploadType === 'OFFICE_NOTES')

  const startUpload = (type: string) => () => {
    setUploadType(type)
    uploadRef.current.click()
  }

  const handleUpload = async (acceptedFiles: File[]) => {
    try {
      dispatch(setLoader(true))
      const [file] = acceptedFiles

      const payload = {
        orderId,
        uploadType,
      }
      const { url, key } = await api.get(`/dme/upload-url`, {
        ...payload,
        fileName: file.name,
      })

      console.log(url, key)
      await api.s3Put(url, file)
      await addFile({ ...payload, fileKey: key, fileName: file.name })
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setLoader(false))
    }
  }

  return (
    <div className="container">
      <AddDocumentModal open={addDocOpen} onClose={() => setAddDocOpen(false)} />
      <TabHeader
        title="ALL ORDER UPLOADS"
        back={`${routes.index}${routes.order.edit}${orderId}/AOBForm`}
        actionButtons={[
          // <Button variant="primary" onClick={() => setAddDocOpen(true)}>
          //   Upload Document
          // </Button>,
          <Upload className="invisible" ref={uploadRef} handleUpload={handleUpload} />,
          <DropdownButton
            disabled={loading}
            title={
              <>
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                  />
                )}
                Upload Document
              </>
            }>
            <StyledDropdownItem disabled={rxUploaded} onClick={startUpload('RX')}>
              RX {rxUploaded && <span className="text-muted">(Uploaded)</span>}
            </StyledDropdownItem>
            {order.testType === 'HST' ? (
              <StyledDropdownItem
                disabled={officeNotesUploaded}
                onClick={startUpload('OFFICE_NOTES')}>
                Office Notes {officeNotesUploaded && <span className="text-muted">(Uploaded)</span>}{' '}
              </StyledDropdownItem>
            ) : (
              <StyledDropdownItem disabled={aobUploaded} onClick={startUpload('AOB')}>
                AOB {aobUploaded && <span className="text-muted">(Uploaded)</span>}{' '}
              </StyledDropdownItem>
            )}

            <StyledDropdownItem onClick={startUpload('OTHER')}>Other</StyledDropdownItem>
          </DropdownButton>,
        ]}
      />

      {stateHelpers.isRxPromised && (
        <div className="alert alert-default-warning" role="alert">
          This order has been marked to receive a fax. The RX form now needs to be faxed to
          TestSmarter at:{' '}
          <span className="text-bold" css={{ fontStyle: 'normal' }}>
            {config?.testSmarterFaxNumber}
          </span>
        </div>
      )}
      {!stateHelpers?.isRxProvidedOrPromised && (
        <div className="alert alert-default-danger" role="alert">
          No RX form has been provided for this order.
        </div>
      )}

      <DocumentListing files={files} order={order} />
    </div>
  )
}

export default DocumentsTab
