/** @jsxImportSource @emotion/react */
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import { useState } from 'react'

import CheckboxComponent from 'components/atoms/Checkbox'
import { confirm } from 'components/molecules/Confirmation'
import SessionEditor from '../sessions/SessionEditor'
import OrderTest from 'components/dme/orderTest/OrderTest'
import { IOrderTest, Order } from 'store/types'
import { useUpdateReportRenderMutation } from 'store/services/labOrder'

interface IProps {
  order: Order
  open: boolean
  onClose: () => void
  isLab: boolean
}

export const UpdateReportModal = ({ order, open, onClose }: IProps) => {
  const [changeConditions, setChangeConditions] = useState<boolean>(false)
  const [changeSessions, setChangeSessions] = useState<boolean>(false)
  const [startTimes, setStartTimes] = useState<
    ({ timeStarted: string; timeStopped: string } | null)[]
  >([])
  const [sessionSelection, setSessionSelection] = useState<number[]>([])
  const [orderTestValues, setOrderTestValues] = useState<IOrderTest[]>(order.tests || [])
  const [updateReportRender] = useUpdateReportRenderMutation()

  const canSubmit =
    (!sessionSelection?.length || !sessionSelection?.every((s) => s === -1)) &&
    (changeConditions || changeSessions)

  const onSubmit = () => {
    console.log(orderTestValues, startTimes, sessionSelection)
    updateReportRender({
      orderId: Number(order.id),
      ...(changeConditions && { tests: orderTestValues }),
      ...(changeSessions && { timestampOverrides: startTimes }),
      ...(sessionSelection?.length && { sessions: sessionSelection }),
    })
    onClose()
  }

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>Update Report</Modal.Title>
      </Modal.Header>

      <Modal.Body
        css={{
          '& >div': {
            marginLeft: '0 !important',
          },
        }}>
        <div className="d-flex border-bottom mb-3 pb-2">
          <CheckboxComponent
            checked={changeConditions}
            handleChange={(e) => setChangeConditions(e.target.checked)}
            label="Change Test Conditions"
            parentClassName="mr-3 custom-control"
            id='test-conditions'
          />
          <CheckboxComponent
            id='test-sessions'
            checked={changeSessions}
            handleChange={(e) => setChangeSessions(e.target.checked)}
            label="Change Test Sessions"
          />
        </div>
        {changeConditions && (
          <OrderTest id={order.id} values={orderTestValues} setValues={setOrderTestValues} />
        )}
        {changeSessions && (
          <SessionEditor
            order={order}
            hideSubmit
            onStartTimesChange={setStartTimes}
            onSessionSelectionChange={setSessionSelection}
          />
        )}
        {!changeConditions && !changeSessions && (
          <div className="text-center text-gray">
            <em>Please select what changes are needed.</em>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          variant="primary"
          onClick={() =>
            confirm('', {
              title: 'Override Report Data',
              description: 'Are you sure you want to override the report data?',
              yesAction: 'Yes',
            })
              .then(onSubmit)
              .catch()
          }>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
