/** @jsxImportSource @emotion/react */
import Button from 'react-bootstrap/Button'

import { HstPatientPaperworkDto, Order } from 'store/types'
import { tsOfficePhone } from 'constants/misc'

interface IProps {
  nextStep: () => void
  savePatientPaperwork: (data: Partial<HstPatientPaperworkDto>) => Promise<void>
  order: Order
}

const AOB = ({ nextStep, order, savePatientPaperwork }: IProps) => {
  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-5">
        <h2 className="mt-4">PATIENT ACKNOWLEDGEMENT</h2>
        <div className="mt-3 mb-5 pt-3 border-top">
          <p>
            I understand that my physician ordered this home sleep test for the purpose of
            evaluating my sleep related breathing. I certify with my signature below that I am the
            recipient of this test and TestSmarter provided me with clear instructions for
            performing the test on my own. I further certify that neither I nor any other person
            tampered with the equipment delivered and/or the results therein while in my possession.
            I also understand that if this procedure is not covered by my insurance, I am
            responsible for the full price of $295.00, payable to TestSmarter. Any questions about
            this or any of the policies on the document can be directed to our oce phone:{' '}
            {tsOfficePhone}.
          </p>
        </div>

        <h2>ASSIGNMENT OF BENEFIT</h2>
        <div className="mt-3 mb-5 pt-3 border-top">
          <p>
            TestSmarter Inc. is an approved and credentialed Independent Diagnostic Testing Facility
            (IDTF) that performs overnight diagnostic testing. I hereby authorize TestSmarter to
            bill my insurance on my behalf for the cost of the home sleep test. I understand that I
            may be nancially responsible for any claim denial, deductible, and/or co-pay and agree
            to make such payment to TestSmarter at the time of billing. Furthermore, I understand
            that if I do not have insurance coverage, I acknowledge that I will be personally billed
            and I accept full responsibility for prompt payment for the cost of the test.
          </p>
        </div>
        <h2>RELEASE OF MEDICAL RECORDS</h2>
        <div className="mt-3 mb-5 pt-3 border-top">
          <p>
            I hereby authorize the use or disclosure of any individually identifiable health
            information as described in this section. I understand that the information I authorize
            a person or entity to receive may be no longer protected by federal privacy regulations.
            Specific description of information that may be used/disclosed: MEDICAL RECORDS.
            Persons/organizations authorized to use or disclose the information: TestSmarter Inc.
            Persons/ organizations authorized to receive the information: referring physician. The
            information will only be used/disclosed for the following purpose(s): continuance of
            care. I understand that I may revoke authorization at any time by notifying the facility
            in writing, except to the extent that action has been taken in reliance on the
            authorization. I understand I have the right to request/receive a Notice of Privacy
            Practices.
          </p>
        </div>
        <h2>NOTICE OF PRIVACY PRACTICES</h2>
        <div className="mt-3 mb-5 pt-3 border-top">
          <p>
            You have the right to read our Privacy Policy below prior to signing this document. The
            notice provides a description of the protection of your personal health information
            under HIPAA laws governing the handling of said information. Please read the policies in
            their entirety prior to signing this document.
          </p>
        </div>
        <h2>PRIVACY POLICY</h2>
        <div className="mt-3 mb-5 pt-3 border-top">
          <p>
            TestSmarter Inc. is required to follow the federal Health Insurance Portability and
            Accountability Act (HIPAA) Laws written to protect the confidentiality of your health
            information. The changes in the evolution of computer technology that is used in
            healthcare has prompted the government to seek a way to standardize and protect the
            electronic exchange of health information. TestSmarter Inc. respects your privacy,
            understanding that your personal health information is sensitive. We will not disclose
            your information unless you tell us to do so, or unless the law authorizes or requires
            us to do so. HIPAA protects the privacy of the health information we create and obtain
            in providing our care and services to you. Your protected health information includes
            our test results, diagnosis, treatment, health information from other providers, and
            billing/payment information related to the services. Federal and state laws allow us to
            use and disclose your protected health information for purposes of treatments and health
            care operations. State law requires us to get authorization to disclose this information
            for payment purposes. For complete privacy policy please visit
            www.testsmarter.net/idtf-diagnostic-testing-facility/privacy-policy.
          </p>
        </div>
      </div>
      <div css={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
        <Button
          variant="primary"
          onClick={async () => {
            await savePatientPaperwork({
              orderId: Number(order.id),
              meta: { completedStep: 'aob' },
            })
            nextStep()
          }}>
          Acknowledge
        </Button>
      </div>
    </div>
  )
}

export default AOB
