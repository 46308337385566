import { useEffect } from 'react'

import { useAppSelector } from 'hooks/redux'
import { useOrderSettings } from './useOrderSettings'
import TestCondition from './TestCondition'
import TitleLead from 'components/atoms/TitleLead'
import Radio from 'components/atoms/Radio'
import Select from 'components/atoms/Select'
import DisabledMask from 'components/atoms/DisabledMask'
import * as generalHelper from 'helpers/generalHelper'
import { useGetTestTypesQuery } from 'store/services/testTypes'
import { TestCode } from 'store/types'

interface IProps {
  onChange?: () => void
  disabled?: boolean
  disabledRadios?: TestCode[]
  officeId?: string
}

const OrderSettings = ({ onChange, disabled = false, disabledRadios, officeId }: IProps) => {
  const dmeCurrentOfficeId = useAppSelector((state) => state.init.currentOfficeId)

  const { state: settings, dispatch: dispatchSetting } = useOrderSettings()
  const { data: testTypesRaw } = useGetTestTypesQuery({
    officeId: officeId ?? dmeCurrentOfficeId,
  })

  // console.log('>>>>>>>>>', testTypesRaw)

  const testTypes =
    testTypesRaw &&
    Object.entries(testTypesRaw)
      .filter(([, { available }]) => available)
      .map(([code, data]) => ({
        ...data,
        id: code,
      }))
      .sort((a, b) => (a.order > b.order ? 1 : -1))

  const onTestTypeChange = (e) => {
    dispatchSetting({
      type: 'test-type',
      payload: { testType: e.target.value },
    })
    if (e.target.value === 'HST') {
      dispatchSetting({
        type: 'duration',
        payload: { duration: '2' },
      })
    } else {
      dispatchSetting({
        type: 'duration',
        payload: { duration: '1' },
      })
    }
  }

  const onDurationChange = (e) => {
    dispatchSetting({
      type: 'duration',
      payload: { duration: e.target.value },
    })
  }

  useEffect(() => {
    if (typeof onChange === 'function' && settings.isDirty) {
      onChange(settings)
    }
  }, [settings, onChange])

  useEffect(() => {
    if (testTypes?.length) {
      dispatchSetting({
        type: 'test-type',
        payload: { testType: testTypes[0].id },
      })
      if (testTypes[0].id === 'HST') {
        dispatchSetting({
          type: 'duration',
          payload: { duration: '2' },
        })
      }
    }
  }, [testTypesRaw])

  return (
    <div className="row bg-white border m-2 mb-3 position-relative">
      {disabled && <DisabledMask />}

      <div className="col-sm-4">
        <div className="d-flex flex-column px-2">
          <TitleLead className="ml-2 my-3" label={generalHelper.t('Order Settings')} />
          <hr className="w-100 mt-0" />
          <div className="col-sm-12 mb-2 font-weight-bold textGray">
            Test Type
            <span className="text-danger">*</span>
          </div>
          {(testTypes || []).map((row, index) => {
            const isDisabled = disabledRadios?.includes(row.id as TestCode)
            return (
              <div key={row.id} className={`col-sm-12 ${index === 2 ? 'mb-1' : 'mb-2'}`}>
                <label className="font-weight-normal d-flex align-items-center">
                  <Radio
                    name="testType"
                    value={row.id}
                    onChange={onTestTypeChange}
                    className="mr-2"
                    disabled={isDisabled}
                    checked={settings.testType === row.id && !isDisabled}
                  />
                  <span style={{ opacity: isDisabled ? 0.5 : 1 }}>
                    {generalHelper.formatTestTypes(row.name)} {isDisabled && '(active order)'}
                  </span>
                </label>
              </div>
            )
          })}
          {settings.testType !== 'HST' && (
            <>
              <div className="col-sm-12 mb-2 font-weight-bold textGray">
                Duration
                <span className="text-danger">*</span>
              </div>
              <Select
                noDefault
                name="duration"
                wrapperClassName="mx-2"
                value={settings.duration}
                options={[
                  { key: '1', value: '1 Night' },
                  { key: '2', value: '2 Nights' },
                ]}
                handleChange={onDurationChange}
              />
            </>
          )}
        </div>
      </div>

      {settings.testType !== 'HST' &&
        Array.from(Array(settings.duration)).map((i, index) => (
          <TestCondition key={`condition-${index}`} night={index + 1} />
        ))}
    </div>
  )
}

export default OrderSettings
