/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { TestCode } from 'ts-back-end/src/common/testtypes/test-types.const'
import { useAppSelector } from '../../../hooks/redux'
import {
  useGetMenuOrderCountsQuery,
  useGetOrdersQuery,
  useGetOrderStatsQuery,
  useLazyGetOrdersCsvReportQuery,
} from '../../../store/services/dmeOrder'
import { useGetPhysiciansOrderCountsQuery } from '../../../store/services/physician'
import DataTable from '../../atoms/DataTable'
import { CustomDateInput } from '../../atoms/DateTimePicker'
import ToggleBtnGroup from '../../atoms/ToggleBtnGroup'
import { TimeWrapper } from '../../lab/orderEdit/HstWorkflow/FollowUpForm'
import OrderTableColumns from '../order/OrderTableColumns'
import { Chart } from './Chart'
import styled from '@emotion/styled'
import CountsBlock from './CountsBlock'
import OrderStatsFooter from './OrderStatsFooter'
import PhysicianStats from './PhysicianStats'

const timeRangeOptions = [
  { value: 'select', label: 'Select My Own Date Range' },
  { value: 'this-week', label: 'This Week' },
  { value: 'last-week', label: 'Last Week' },
  { value: 'this-month', label: 'This Month' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'this-year', label: 'This Year' },
  { value: 'last-year', label: 'Last Year' },
]

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 240px);
  padding: 0 10px 10px 10px;

  Table {
    thead:not(.table_filters) {
      background: white;
      position: sticky;
      top: 0px;
      margin: 0 0 0 0;
      z-index: 1;
    }
    td {
      vertical-align: middle;
    }
  }
`

interface IProps {}

export const Stats = (props: IProps) => {
  const [timeRange, setTimeRange] = useState({ value: 'this-month', label: 'This Month' })
  const [dateRange, setDateRange] = useState<{
    dateStart: string | undefined
    dateEnd: string | undefined
  }>({
    dateStart: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString(),
    dateEnd: new Date().toISOString(),
  })

  const supportedTestTypes = useAppSelector((state) =>
    Object.keys(state.init.currentOfficeTests).filter(
      (key) => !!state.init.currentOfficeTests[key]?.available,
    ),
  )
  const [testType, setTestType] = useState<TestCode | null>(null)

  const [customStartDate, setCustomStartDate] = useState<Date | null>(null)
  const [customEndDate, setCustomEndDate] = useState<Date | null>(null)

  const [showDateRangeSettings, setShowDateRangeSettings] = useState(false)
  const [showPhysicianStats, setShowPhysicianStats] = useState(false)
  const [showListingFrom, setShowListingFrom] = useState<
    'SESSIONS_SELECTED' | 'CANCELLED' | 'G1' | 'G2' | 'G3' | null
  >(null)
  const officeId = useAppSelector((state) => state.init.currentOfficeId)

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [sortOptions, setSortOptions] = useState<{ id: string; desc: boolean }>()
  const [searchFilter, setSearchFilter] = useState('')

  // const { data: menuOrderCounts } = useGetMenuOrderCountsQuery({
  //   officeId: Number(officeId),
  //   testType,
  // })

  const {
    data: timeRangedMenuOrderCounts,
    isLoading: orderCountsLoading,
    refetch,
  } = useGetMenuOrderCountsQuery(
    { officeId: Number(officeId), testType: testType || 'POX', ...dateRange },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
    },
  )

  const {
    data: orders,
    isLoading: ordersListingLoading,
    refetch: refetchOrder,
  } = useGetOrdersQuery(
    {
      officeId: Number(officeId),
      type: testType || 'POX',
      buckets: ['SESSIONS_SELECTED', 'CANCELLED'].includes(showListingFrom)
        ? [showListingFrom]
        : [],
      qualificationBuckets: ['G1', 'G2', 'G3'].includes(showListingFrom) ? [showListingFrom] : [],
      page: currentPage,
      size: pageSize,
      sortField: sortOptions?.id,
      sortOrder: sortOptions?.desc ? 'desc' : 'asc',
      ...dateRange,
      ...(searchFilter?.length && { search: searchFilter }),
    },
    {
      pollingInterval: 60000,
    },
  )

  const [getOrdersReport] = useLazyGetOrdersCsvReportQuery()

  const { data: datapoints } = useGetOrderStatsQuery({
    officeId: Number(officeId),
    testType: testType || 'POX',
  })
  const { data: physicianOrderCounts } = useGetPhysiciansOrderCountsQuery({
    officeId: Number(officeId),
  })

  useEffect(() => {
    if (showListingFrom) {
      refetchOrder()
    }
  }, [showListingFrom])

  useEffect(() => {
    if (testType || !supportedTestTypes.length) {
      return
    }

    setTestType(supportedTestTypes[0])
  }, [supportedTestTypes])

  const setDateRangeAndRefetch = (value: string) => {
    const today = new Date()
    const oneDay = 24 * 60 * 60 * 1000

    setShowDateRangeSettings(false)

    switch (value) {
      case 'all-time': {
        setDateRange({ dateStart: undefined, dateEnd: undefined })
        break
      }
      case 'this-week': {
        const firstDayOfWeek = new Date(
          today.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)),
        )

        const utc = new Date(Date.UTC(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate()))

        setDateRange({ dateStart: utc.toISOString(), dateEnd: new Date().toISOString() })
        break
      }
      case 'last-week': {
        const firstDayOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 6))
        const lastDayOfLastWeek = new Date(firstDayOfLastWeek.getTime() + 6 * oneDay)

        const utc1 = new Date(Date.UTC(firstDayOfLastWeek.getFullYear(), firstDayOfLastWeek.getMonth(), firstDayOfLastWeek.getDate()))
        const utc2 = new Date(Date.UTC(lastDayOfLastWeek.getFullYear(), lastDayOfLastWeek.getMonth(), lastDayOfLastWeek.getDate()))

        setDateRange({
          dateStart: utc1.toISOString(),
          dateEnd: utc2.toISOString(),
        })
        break
      }
      case 'last-two-weeks': {
        const firstDayOfLastTwoWeeks = new Date(
          today.setDate(today.getDate() - today.getDay() - 13),
        )
        const lastDayOfLastTwoWeeks = new Date(firstDayOfLastTwoWeeks.getTime() + 13 * oneDay)

        const utc1 = new Date(Date.UTC(firstDayOfLastTwoWeeks.getFullYear(), firstDayOfLastTwoWeeks.getMonth(), firstDayOfLastTwoWeeks.getDate()))
        const utc2 = new Date(Date.UTC(lastDayOfLastTwoWeeks.getFullYear(), lastDayOfLastTwoWeeks.getMonth(), lastDayOfLastTwoWeeks.getDate()))

        setDateRange({
          dateStart: utc1.toISOString(),
          dateEnd: utc2.toISOString(),
        })
        break
      }
      case 'this-month': {
        const firstDayOfThisMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))

        setDateRange({
          dateStart: firstDayOfThisMonth.toISOString(),
          dateEnd: new Date().toISOString(),
        })
        break
      }
      case 'last-month': {
        const firstDayOfLastMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth() - 1, 1))
        const lastDayOfLastMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 0))

        setDateRange({
          dateStart: firstDayOfLastMonth.toISOString(),
          dateEnd: lastDayOfLastMonth.toISOString(),
        })
        break
      }
      case 'last-three-months': {
        const today = new Date()

        const startOfMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))

        const firstDayOfLastThreeMonths = new Date(
          startOfMonth.setMonth(startOfMonth.getMonth() - 3),
        )

        startOfMonth.setMonth(startOfMonth.getMonth() + 3)

        const lastDayOfLastThreeMonths = new Date(startOfMonth.setDate(startOfMonth.getDate() - 1))

        const utc1 = new Date(Date.UTC(firstDayOfLastThreeMonths.getFullYear(), firstDayOfLastThreeMonths.getMonth(), firstDayOfLastThreeMonths.getDate()))
        const utc2 = new Date(Date.UTC(lastDayOfLastThreeMonths.getFullYear(), lastDayOfLastThreeMonths.getMonth(), lastDayOfLastThreeMonths.getDate()))

        setDateRange({
          dateStart: utc1.toISOString(),
          dateEnd: utc2.toISOString(),
        })
        break
      }
      case 'this-year': {
        const firstDayOfThisYear = new Date(Date.UTC(today.getFullYear(), 0, 1))

        setDateRange({
          dateStart: firstDayOfThisYear.toISOString(),
          dateEnd: new Date().toISOString(),
        })
        break
      }
      case 'last-year': {
        const firstDayOfLastYear = new Date(Date.UTC(today.getFullYear() - 1, 0, 1))
        const lastDayOfLastYear = new Date(Date.UTC(today.getFullYear(), 0, 0))

        setDateRange({
          dateStart: firstDayOfLastYear.toISOString(),
          dateEnd: lastDayOfLastYear.toISOString(),
        })
        break
      }
      case 'select': {
        setShowDateRangeSettings(true)
        return
      }
    }

    refetch()
  }

  const [yellow, green, blue, gray, red] = ['#e69c37', '#4ea45f', '#2c83bd', '#b9b9b9', '#d42020']

  return (
    <div>
      <div className={'px-3'}>
        {!showPhysicianStats ? (
          <>
            <p
              className={'m-0 p-0 mb-4 d-flex justify-content-between align-items-center'}
              css={{
                fontSize: '18px',
                '& .btn-group': {
                  button: {
                    fontSize: '18px',
                  },
                },
              }}>
              <ToggleBtnGroup
                options={[
                  {
                    key: 'POX',
                    name: 'Pulse-Oximetry',
                    disabled: !supportedTestTypes.includes('POX'),
                  },
                  {
                    key: 'COX',
                    name: 'Capnography',
                    disabled: !supportedTestTypes.includes('COX'),
                  },
                  {
                    key: 'HST',
                    name: 'Home Sleep Test',
                    disabled: !supportedTestTypes.includes('HST'),
                  },
                ]}
                value={testType}
                onChange={(testType) => setTestType(testType)}
              />
              {testType === 'POX'
                ? 'Pulse-Oximetry'
                : testType === 'COX'
                  ? 'Capnography'
                  : 'Home Sleep Test'}{' '}
              Order Statistics
            </p>
            <p
              className={'m-0 p-0 my-4'}
              css={{
                fontSize: '18px',
              }}>
              Completed Orders by Month
            </p>
            <div>
              <Chart datapoints={datapoints || []} />
            </div>
            {testType === 'POX' && (
              <>
                <p
                  className={'m-0 p-0 mt-4 mb-2'}
                  css={{
                    fontSize: '18px',
                  }}>
                  Order Stats Report by Date Range
                </p>
                <Select
                  className="basic-single mb-2"
                  classNamePrefix="select"
                  isSearchable
                  placeholder="Select Time Range"
                  name="time_range"
                  onChange={(item) => {
                    if (!item) return
                    setTimeRange(item)
                    setDateRangeAndRefetch(item.value)
                  }}
                  options={timeRangeOptions}
                  value={timeRange}
                  css={{
                    width: 300,
                  }}
                />
                <p>
                  {!showDateRangeSettings ? (
                    <>
                      Date Range:{' '}
                      {timeRange.value === 'all-time'
                        ? 'All Time'
                        : `${new Date(dateRange.dateStart!).toLocaleDateString()} - ${new Date(
                            dateRange.dateEnd!,
                          ).toLocaleDateString()}`}
                    </>
                  ) : null}
                </p>
                {showDateRangeSettings ? (
                  <TimeWrapper
                    css={{
                      marginBottom: 20,
                      width: 300,
                    }}>
                    <div style={{ fontWeight: '500' }} className="mb-1">
                      Select Date Range
                    </div>
                    <DatePicker
                      selectsRange={true}
                      startDate={customStartDate}
                      endDate={customEndDate}
                      onChange={(update) => {
                        setCustomStartDate(update[0])
                        setCustomEndDate(update[1])

                        if (update[0] && update[1]) {
                          const utc1 = new Date(Date.UTC(update[0].getFullYear(), update[0].getMonth(), update[0].getDate()))
                          const utc2 = new Date(Date.UTC(update[1].getFullYear(), update[1].getMonth(), update[1].getDate()))

                          setDateRange({
                            dateStart: utc1.toISOString(),
                            dateEnd: utc2.toISOString(),
                          })

                          refetch()
                        }
                      }}
                      customInput={<CustomDateInput />}
                      isClearable
                    />
                  </TimeWrapper>
                ) : null}
                <div className={'d-flex justify-content-between pb-4'} css={{ gap: 15 }}>
                  <CountsBlock
                    title={'COMPLETED ORDERS'}
                    count={timeRangedMenuOrderCounts?.counts?.completed}
                    color={green}
                    active={showListingFrom === 'SESSIONS_SELECTED'}
                    onClick={() =>
                      setShowListingFrom(
                        showListingFrom === 'SESSIONS_SELECTED' ? null : 'SESSIONS_SELECTED',
                      )
                    }
                    loading={orderCountsLoading}
                    containerStyle={{ flex: 1 }}
                  />
                  <div className="" css={{ flex: 5 }}>
                    <div
                      className={'d-flex justify-content-between mb-4'}
                      css={{
                        gap: 15,
                      }}>
                      <CountsBlock
                        title={'GROUP 1'}
                        count={timeRangedMenuOrderCounts?.counts?.group1}
                        color={green}
                        active={showListingFrom === 'G1'}
                        onClick={() => setShowListingFrom(showListingFrom === 'G1' ? null : 'G1')}
                        loading={orderCountsLoading}
                      />
                      <CountsBlock
                        title={'GROUP 2'}
                        count={timeRangedMenuOrderCounts?.counts?.group2}
                        color={green}
                        active={showListingFrom === 'G2'}
                        onClick={() => setShowListingFrom(showListingFrom === 'G2' ? null : 'G2')}
                        loading={orderCountsLoading}
                      />
                      <CountsBlock
                        title={'NON-QUALIFIED'}
                        count={timeRangedMenuOrderCounts?.counts?.nonQualified}
                        color={red}
                        active={showListingFrom === 'G3'}
                        onClick={() => setShowListingFrom(showListingFrom === 'G3' ? null : 'G3')}
                        loading={orderCountsLoading}
                      />
                    </div>
                    <div className={'d-flex justify-content-between'} css={{ gap: 15 }}>
                      <CountsBlock
                        title={'AVARAGE DAYS TO COMPLETE'}
                        count={timeRangedMenuOrderCounts?.avgDaysToComplete}
                        color={gray}
                        loading={orderCountsLoading}
                      />
                      <CountsBlock
                        title={'CANCELLED ORDERS'}
                        count={timeRangedMenuOrderCounts?.counts?.cancelled}
                        color={yellow}
                        active={showListingFrom === 'CANCELLED'}
                        onClick={() =>
                          setShowListingFrom(showListingFrom === 'CANCELLED' ? null : 'CANCELLED')
                        }
                        loading={orderCountsLoading}
                      />
                      <CountsBlock
                        title={'SLEEP STUDY CANDIDATES'}
                        count={null}
                        color={blue}
                        loading={orderCountsLoading}
                      />
                    </div>
                  </div>
                </div>
                {showListingFrom && (
                  <div
                    className={'mb-4'}
                    css={{
                      borderBottom: '2px solid #d6d6d6',
                      padding: 10,
                      background: '#fff',
                      borderTop: `3px solid ${blue}`,
                      borderRadius: 3,
                      boxShadow: '0 1px 3px 1px #e8e5e5',
                      marginBottom: 10,
                    }}>
                    <TableWrapper>
                      <DataTable
                        data={orders}
                        status={ordersListingLoading}
                        tableColumns={OrderTableColumns(
                          ['deviceAssignedAt', 'messages', 'notes', 'labProgress', 'createdAt'],
                          testType,
                        )}
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                        setCurrentPage={setCurrentPage}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        useCustomSortHandler={true}
                        onExport={async () => {
                          await getOrdersReport({
                            officeId: Number(officeId),
                            type: testType,
                            buckets: ['SESSIONS_SELECTED', 'CANCELLED'].includes(showListingFrom)
                              ? [showListingFrom]
                              : [],
                            qualificationBuckets: ['G1', 'G2', 'G3'].includes(showListingFrom)
                              ? [showListingFrom]
                              : [],
                            ...dateRange,
                          }).then((res) => {
                            const csv = res.data?.csv
                            const blob = new Blob([csv], { type: 'text/csv' })

                            const url = URL.createObjectURL(blob)

                            const a = document.createElement('a')

                            a.href = url
                            a.download = `orders-report-${Date.now()}.csv`

                            a.click()
                          })
                        }}
                        setSortOptions={setSortOptions}
                      />
                    </TableWrapper>
                  </div>
                )}
                {/* <div
                  className={'pb-4 mb-4'}
                  css={{
                    borderBottom: '2px solid #d6d6d6',
                  }}>
                  <p
                    className={'m-0 p-0 mt-4 mb-2'}
                    css={{
                      fontSize: '18px',
                    }}>
                    Top Referring Physicians
                  </p>
                  {physicianOrderCounts ? (
                    <>
                      <p>
                        {physicianOrderCounts.month?.fullName || 'Nobody'} was the top referring
                        physician this month with {physicianOrderCounts.month?.amount || 0} orders.{' '}
                        {physicianOrderCounts.lastMonth?.fullName || 'Nobody'} was{' '}
                        {physicianOrderCounts.month?.id === physicianOrderCounts.lastMonth?.id
                          ? 'also'
                          : ''}{' '}
                        last month's top referring physician with{' '}
                        {physicianOrderCounts.lastMonth?.amount || 0} orders.{' '}
                        <span
                          css={{
                            color: '#4298d1',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => setShowPhysicianStats(true)}>
                          Click here to view all physician referral stats.
                        </span>
                      </p>
                      <Button variant="primary" onClick={() => setShowPhysicianStats(true)}>
                        View All Physician Referral Stats
                      </Button>
                    </>
                  ) : (
                    <p css={{ color: gray }}>Loading...</p>
                  )}
                </div> */}
                <div
                  css={{
                    background: 'white',
                    borderTop: `3px solid ${gray}`,
                    borderRadius: 3,
                    padding: 10,
                    boxShadow: '0 1px 3px 1px #e8e5e5',
                    marginBottom: 20,
                  }}>
                  <p
                    css={{
                      fontSize: '18px',
                    }}>
                    Order Statistics
                  </p>
                  <OrderStatsFooter testType={testType} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div
              className={'pb-3 mb-3'}
              css={{
                borderBottom: '2px solid #d6d6d6',
              }}>
              <Button variant="primary" onClick={() => setShowPhysicianStats(false)}>
                <i
                  className="fas fa-arrow-left"
                  css={{
                    marginRight: 5,
                  }}
                />
                Back to Order Stats
              </Button>
            </div>
            <PhysicianStats physicianOrderCounts={physicianOrderCounts} />
          </>
        )}
      </div>
    </div>
  )
}

export default Stats
