export const deviceTypeArray = [
  { name: 'None', value: '' },
  { name: 'PAP', value: 'PAP' },
  { name: 'Ventilator', value: 'Ventilator' },
  { name: 'Dental', value: 'Dental' },
  { name: 'Implant', value: 'Implant' },
]

export const papTypeArray = [
  { value: 'APAP' },
  { value: 'BiPAP' },
  { value: 'CPAP' },
  { value: 'OTHER' },
]

export const ventilatorTypeArray = [{ value: 'Adaptive Support' }, { value: 'Non-invasive' }]

export const oxygenArray = [
  { name: 'Room Air', value: '0' },
  { name: 'Oxygen', value: '1' },
]

export const pressureOptions = [...Array(17).keys()]
  .map((i) => i + 4)
  .map((i) => ({ key: i, value: i }))

export const bipapPressureOptions = [...Array(27).keys()]
  .map((i) => i + 4)
  .map((i) => ({ key: i, value: i }))
