/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { Formik } from 'formik'
import * as Yup from 'yup'

import InputComponent from 'components/atoms/Input'
import {
  useSendPatientPaperworkEmailMutation,
  useCreateCheckoutMutation,
  useSetOrderFollowUpDateMutation,
  useInitiateManualQuestionnaireMutation,
  useBypassStripePaymentMutation,
} from 'store/services/labOrder'
import { useSendLabNoteMutation } from 'store/services/notes'
import { Order, FollowUpNamespace } from 'store/types'
import { toast } from 'react-toastify'
import Toast from '../../../atoms/Toast'
import { confirm } from '../../../molecules/Confirmation'
import { FollowUpForm } from './FollowUpForm'
import PageIcon from 'assets/img/page-icon.svg'

interface IProps {
  order: Order
}

const formValidation = Yup.object().shape({
  followUpDate: Yup.date().required('Required'),
  notes: Yup.string(),
})

const completedStepDescription = {
  patient: 'Patient Information',
  insurance: 'Insurance Information',
  sleepinessScale: 'Sleepiness Scale',
  infoSheet: 'Information Sheet',
  signatureRequests: 'Signature Requests',
  phiDisclosure: 'PHI Disclosure',
  textMsgConsent: 'Text Message Consent',
  policiesResponsibilities: 'Policies & Responsibilities',
  patientRightsResponsibilities: 'Patient Rights & Responsibilities',
  creditCardAgreement: 'Credit Card Agreement',
}

export const PatientScheduling = ({ order }: IProps) => {
  const [email, setEmail] = useState('')
  const [sendPatientPaperworkEmail] = useSendPatientPaperworkEmailMutation()
  const [bypassStripePayment] = useBypassStripePaymentMutation()
  const [initiateManualQuestionnaire] = useInitiateManualQuestionnaireMutation()
  const [createCheckout] = useCreateCheckoutMutation()
  const [setOrderFollowUpDate] = useSetOrderFollowUpDateMutation()
  const [sendLabNote] = useSendLabNoteMutation()

  const followUps = order?.followUpsList
    ?.filter((f) => f.namespace === FollowUpNamespace.HST_PAPERWORK)
    ?.sort((a, b) => moment(b.addedAt).diff(moment(a.addedAt)))

  const followUpDate = followUps?.[0]?.date ? moment(followUps?.[0]?.date).toDate() : ''

  useEffect(() => {
    const checkoutAmount = Number(order.hst?.paymentSession?.amount_total) / 100
    const checkoutAmountCorrect =
      checkoutAmount === Number(order?.copay) ||
      (Number(order?.copay) === 0 && checkoutAmount === 1)
    if (
      order &&
      (!order.hst?.paymentSession ||
        moment().isAfter(moment(order.hst?.paymentSession?.expires_at * 1000)) ||
        !checkoutAmountCorrect)
    ) {
      createCheckout({
        orderId: Number(order.id),
        amount: Number(order?.copay),
      })
    }
  }, [order])

  const onQuestionnaireClick = async () => {
    try {
      await sendPatientPaperworkEmail({
        orderId: Number(order.id),
        email: order.patient.email,
        method: 'email',
      }).unwrap()
      toast.success('Questionnaire sent successfully')
    } catch (e) {
      toast.error('There was a problem sending the questionnaire')
    }
  }

  const onManualQuestionnaireClick = async () => {
    try {
      await confirm('', {
        title: 'Are you sure?',
        description: 'Are you sure you want to proceed with manual questionnaire?',
        yesAction: 'Yes',
        noAction: 'No',
      })
      await initiateManualQuestionnaire({
        orderId: Number(order.id),
      })
    } catch {}
  }

  const onPaymentClick = async () => {
    if (order?.hst?.paymentSession?.url) {
      return window.open(order?.hst?.paymentSession?.url, '_blank')
    }
    try {
      await createCheckout({
        orderId: Number(order.id),
        amount: Number(order?.copay),
      }).unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="w-100">
      <div className="row">
        <div className="col-sm-10 p-0 d-flex align-items-center">
          <h4 className="m-0">Patient Scheduling</h4>
          <Button
            variant="primary"
            className="ml-3"
            onClick={onQuestionnaireClick}
            disabled={
              order?.hst?.paperwork?.meta?.finishedAt ||
              !(order?.patient?.email || order?.patient?.mobilePhone)
            }>
            Send Questionnaire
          </Button>
          <Button
            variant="outline-primary"
            className="ml-2"
            disabled={order?.hst?.paperwork?.meta?.finishedAt}
            onClick={onManualQuestionnaireClick}>
            Manual Questionnaire
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 mt-3 align-items-end">
          <div
            className="p-3 d-flex align-items-end"
            css={{ background: '#F5F5F5', width: '100%', height: '100%' }}>
            <Formik
              enableReinitialize={true}
              validationSchema={formValidation}
              initialValues={{
                followUpDate,
                notes: '',
              }}
              onSubmit={async (values, { resetForm }) => {
                await Promise.all([
                  setOrderFollowUpDate({
                    orderId: Number(order.id),
                    namespace: FollowUpNamespace.HST_PAPERWORK,
                    date: moment(values.followUpDate).format('YYYY-MM-DD'),
                    comment: values.notes,
                  }).unwrap(),
                  await sendLabNote({
                    orderId: Number(order.id),
                    text: `${values.notes} (follow-up: ${moment(values.followUpDate).format(
                      'MM/DD/YYYY',
                    )})`,
                  }).unwrap(),
                ])
                resetForm()
              }}>
              {({ values, setFieldValue, errors, touched }) => (
                <FollowUpForm {...{ values, setFieldValue, errors, touched }} />
              )}
            </Formik>
          </div>
        </div>
        <div className="col-sm-6 p-3 mt-3 align-items-end" css={{ background: '#F5F5F5' }}>
          <div className="d-flex justify-content-between">
            <strong>Send payment link</strong>
            <div>
              <Button variant="secondary" className={'mr-2'} onClick={onPaymentClick}>
                Take Stripe payment
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  confirm('', {
                    title: 'Are you sure?',
                    description: 'This will bypass Stripe payment for this order.',
                    yesAction: 'Yes, proceed',
                    noAction: 'Cancel',
                  })
                    .then(async () => {
                      try {
                        await bypassStripePayment({ orderId: Number(order.id) }).unwrap()
                        Toast({ type: 'success', label: `Stripe payment bypassed.` })
                      } catch (err) {
                        Toast({ type: 'error', label: `Error bypassing Stripe payment.` })
                      }
                    })
                    .catch(() => {})
                }}>
                Bypass Stripe payment
              </Button>
            </div>
          </div>
          <div className="d-flex align-items-end" css={{ '.form-group': { width: '100%' } }}>
            <InputComponent
              label="Email address"
              type="text"
              placeholder="Enter email"
              className="form-control w-100"
              value={email}
              handleChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="primary" className="ml-3">
              Send
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 mt-2">
          <div
            className="d-flex p-3 flex-column"
            css={{ backgroundColor: '#F5F5F5', height: '100%', maxHeight: 170, overflowY: 'auto' }}>
            <h4>Notes</h4>
            {followUps
              ?.filter((i) => i.comment)
              ?.map((f) => (
                <div
                  key={f.id}
                  className="p-2 mt-1"
                  css={{ backgroundColor: '#C2C8CF99', borderRadius: 5 }}>
                  <img src={PageIcon} className="mr-2" alt="icon" />
                  <span className="mr-2" css={{ opacity: 0.5, fontWeight: '600' }}>
                    {moment(f.addedAt).format('MM/DD/YYYY hh:mm a')}
                  </span>
                  Note: {f.comment}. Follow-up Date: {moment(f.date).format('MM/DD/YYYY')}
                </div>
              ))}
          </div>
        </div>
        <div
          className="col-sm-6 d-flex flex-column p-3 mt-2"
          css={{ backgroundColor: '#C2C8CF99' }}>
          <h4>Activity Log</h4>
          {order?.hst?.paperwork?.meta?.sentLinks?.length === 0 ? (
            <span css={{ fontStyle: 'italic' }}>Questionnaire has not yet been sent</span>
          ) : (
            <>
              {order?.hst?.paperwork?.meta?.sentLinks?.map((link) => (
                <span key={link.nonce}>
                  Questionnaire emailed and sent by text message at{' '}
                  {moment(link.sentAt).format('MM/DD/YYYY HH:mm a')}
                </span>
              ))}
              {order?.hst?.paperwork?.meta?.finishedAt ? (
                <span>
                  Questionnaire completed at{' '}
                  {moment(order?.hst?.paperwork?.meta?.finishedAt).format('MM/DD/YYYY HH:mm a')}
                </span>
              ) : order?.hst?.paperwork?.meta?.startedAt ? (
                <span css={{}}>
                  Questionnaire is partially completed to:{' '}
                  {completedStepDescription[order?.hst?.paperwork?.meta?.completedStep]}
                </span>
              ) : (
                <span css={{ fontStyle: 'italic' }}>Questionnaire has not yet been started</span>
              )}
            </>
          )}
          {order?.hst?.paymentSession?.payment_status === 'paid' ? (
            <span>Copay paid and CC data on file.</span>
          ) : (
            <span css={{ fontStyle: 'italic' }}>Awaiting Payment ...</span>
          )}
        </div>
      </div>
    </div>
  )
}
