import { baseApi } from './baseApi'
import { Patient } from 'store/types'
import { HstPatientPaperworkDto } from 'store/types'

export const patientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    startPatientPaperwork: builder.mutation<Patient, number>({
      query: (orderId) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/start-paperwork`,
      }),
      invalidatesTags: (result, error, orderId) => [{ type: 'Order', orderId }],
    }),
    savePatientPaperwork: builder.mutation<void, Partial<HstPatientPaperworkDto>>({
      query: (data) => ({
        method: `PATCH`,
        url: `/lab/orders/${data.orderId}/save-paperwork`,
        body: data,
      }),
      invalidatesTags: (result, error, orderId) => [{ type: 'Order', orderId }],
    }),
    finishPatientPaperwork: builder.mutation<void, { orderId: number; signature: string }>({
      query: ({ orderId, signature }) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/finish-paperwork`,
        body: { orderId, signature },
      }),
      invalidatesTags: (result, error, orderId) => [{ type: 'Order', orderId }],
    }),
  }),
})

export const {
  useStartPatientPaperworkMutation,
  useSavePatientPaperworkMutation,
  useFinishPatientPaperworkMutation,
} = patientApi
