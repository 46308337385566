import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import styled from '@emotion/styled'

import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from '../atoms/DashboardContent'
import PatientUpsert from 'components/dme/orderEdit/views/forms/PatientUpsert'
import Button from 'components/atoms/Button'
import routes from '../constants/routes'
import DataTable from 'components/atoms/DataTable'
import { useGetLabPatientQuery } from 'store/services/patient'
import { useGetLabOrdersQuery } from 'store/services/labOrder'
import OrderTableColumns from '../order/OrderTableColumns'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PatientDetails = () => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const [patientFormDirty, setPatientFormDirty] = useState(false)
  const { data: patient, isLoading: patientLoading } = useGetLabPatientQuery({ id: patientId })
  const { data: orders, isLoading: ordersLoading } = useGetLabOrdersQuery(
    patient
      ? {
          patientId: patient.id,
        }
      : skipToken,
  )

  return (
    <DashboardContent
      title="Patient Details"
      icon="fa fa-user"
      content={
        <>
          <div className="card card-primary mt-3">
            <div className="row p-3">
              <PatientUpsert
                labUser
                unlockedForm
                modalView
                patient={patient ?? {}}
                setFormDirty={setPatientFormDirty}
              />
              <div className="col-sm-12 d-flex justify-content-end">
                <Button
                  className="btn btn-primary"
                  label="Save Changes"
                  type="submit"
                  form="patientUpsert"
                  disabled={!patientFormDirty}
                />
              </div>
            </div>
          </div>
          <div className="card card-primary mt-3">
            <div className="row p-3">
              <StyledTableWrapper
                className="card-body row"
                style={{
                  overflowY:
                    !orders?.items?.length || orders?.items?.length < 10 ? 'unset' : 'auto',
                }}>
                <DataTable
                  data={orders}
                  status={patientLoading || ordersLoading}
                  tableColumns={OrderTableColumns({
                    testType: 'POXCOX',
                    bucket: 'ALL',
                  })}
                  // select={bucketColumnDefaults[tableHelpers.testType === 'HST' ? 'HST' : 'POXCOX']?.[
                  //   tableHelpers.bucket
                  // ]?.some((c) => c === 'select')}
                  pageSize={30}
                  setCurrentPage={() => {}}
                  setSortOptions={() => {}}
                  onRowClick={(row) => {
                    navigate(`${routes.index}${routes.order.edit}${row.original.id}?bucket=ALL`)
                  }}
                />
              </StyledTableWrapper>
            </div>
          </div>
        </>
      }
      breadcrumb={[
        {
          label: 'Patient Directory',
          to: `${routes.index}${routes.patient.root}${routes.patient.directory}`,
        },
        {
          label: `${patient?.lastName}, ${patient?.firstName} ${patient?.middleName}`,
        },
      ]}
    />
  )
}

export default PatientDetails
