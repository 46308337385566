import { ReactElement, SyntheticEvent, forwardRef } from 'react'
import * as generalHelper from 'helpers/generalHelper'

interface IProps {
  label: string
  onClick?: (e: SyntheticEvent) => void
  className?: string
  disabled?: boolean
  icon?: string
  href?: string
  type?: 'reset' | 'button' | 'submit' | undefined
  id?: string
  form?: string
  target?: string
  children?: ReactElement
}

const Button = forwardRef((props: IProps, ref) => (
  <button {...props} ref={ref} className={`btn ${props.className ?? ''}`}>
    {props.children}
    {generalHelper.t(props.label)}
  </button>
))

export default Button
