/** @jsxImportSource @emotion/react */
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import * as generalHelper from 'helpers/generalHelper'
import Button from 'components/atoms/Button'
import { requiredPhoneValidation } from 'helpers/validate'
import { PhysicianForm } from 'components/organisms/physician/PhysicianForm'
import { Physician, PhysicianAddress } from 'store/types'
import { AddressHistory } from './AddressHistory'

const physicianValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  fax: requiredPhoneValidation,
  telephone: requiredPhoneValidation,
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

interface IProps {
  onCancel: () => void
  onSubmit: (physician: Partial<Physician>, address: PhysicianAddress, revertPanel: boolean) => void
  onReject: () => void
  onApprove: () => void
  physician: Physician
  physicianAddress: PhysicianAddress
  physicianHistory: any[]
}

export const ReviewPhysician = ({
  onSubmit,
  onReject,
  onApprove,
  onCancel,
  physician,
  physicianAddress,
  physicianHistory,
}: IProps) => {
  const initialValues = {
    firstName: physician?.firstName ?? '',
    lastName: physician?.lastName ?? '',
    credential: physician?.credential ?? '',
    fax: physicianAddress?.fax ?? '',
    telephone: physicianAddress?.telephone ?? '',
    addressStreetLine1: physicianAddress?.addressStreetLine1 ?? '',
    addressStreetLine2: physicianAddress?.addressStreetLine2 ?? '',
    addressCity: physicianAddress?.addressCity ?? '',
    addressState: physicianAddress?.addressState ?? '',
    addressZip: physicianAddress?.addressZip ?? '',
    uuid: physicianAddress?.uuid ?? '',
  }

  return (
    <div>
      <div className="p-2 border">
        <div className="row border-bottom rounded justify-content-between m-0">
          <div className="col-1 d-flex align-items-center ml-3">
            <i className="text-success fa fa-user-md" />
          </div>
          <div className="col-2 d-flex align-items-center">{physician.npi}</div>
          <div className="col-2 d-flex align-items-center">
            {physician.firstName} {physician.lastName} {physician.credential}
          </div>

          <div className="col-6 pr-0">
            <div className="py-1 mb-2 d-flex">
              <div>
                <div>
                  {physicianAddress?.addressStreetLine1}
                  {', '}
                  {physicianAddress?.addressStreetLine2
                    ? physicianAddress?.addressStreetLine2 + ', '
                    : ''}
                  {physicianAddress?.addressCity}
                  {', '}
                  {physicianAddress?.addressState}
                </div>
                <div className="pb-2">
                  {physicianAddress?.telephone && <strong>{generalHelper.t('Tel')}: </strong>}
                  {physicianAddress?.telephone}
                  {physicianAddress?.fax && (
                    <>
                      <br />
                      <strong>{generalHelper.t('Fax')}: </strong>
                    </>
                  )}
                  {physicianAddress?.fax}{' '}
                </div>
                {physicianAddress.comment && (
                  <div className="text-gray" css={{ fontStyle: 'italic' }}>
                    {physicianAddress.comment}
                  </div>
                )}
              </div>
              <div className="col text-right d-flex align-items-center justify-content-end pr-0">
                <Button
                  className="btn btn-sm btn-outline-primary"
                  label="Cancel"
                  type="button"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 pt-2 pb-1">
          <Button
            className="btn btn-sm btn-danger mr-2"
            label="Reject"
            type="button"
            onClick={onReject}
          />
          <Button
            className="btn btn-sm btn-primary"
            label="Approve"
            type="button"
            onClick={onApprove}
          />
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        validationSchema={physicianValidation}
        initialValues={initialValues}
        onSubmit={({ firstName, lastName, credential, ...address }) =>
          onSubmit({ firstName, lastName, credential }, address, false)
        }>
        {({ isValid, values }) => (
          <Form className="mt-2 border p-2">
            <PhysicianForm includeName />
            <div className="d-flex justify-content-end">
              <Button
                className="mx-1 btn-outline-primary"
                id="btn-ts"
                label="Cancel"
                onClick={onCancel}
              />
              <Button
                className="mx-1 btn-primary"
                id="btn-ts"
                label="Save Changes"
                type="submit"
                disabled={JSON.stringify(initialValues) === JSON.stringify(values) || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
      <AddressHistory
        addressUuid={physicianAddress.uuid}
        physicianHistory={physicianHistory}
        physician={physician}
      />
    </div>
  )
}
