import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ------------------------------------------------------------
export const setLoader = createAsyncThunk('setLoader', async (status: boolean) =>
  status
)

// ------------------------------------------------------------
const initialState = {
  row: false
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setLoader.fulfilled, (state, action) => {
      state.row = action.payload
    })
  }
})

export default localSlice.reducer
