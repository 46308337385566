/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import styled from '@emotion/styled'
import ReactButton from 'react-bootstrap/Button'

import * as generalHelper from 'helpers/generalHelper'
import Modal from 'components/atoms/Modal'
import Tab from 'components/atoms/Tab'
import Button from 'components/atoms/Button'
import PatientTab from './views/Patient.tab'
import InsuranceTab from './views/Insurance.tab'
import PhysicianTab from './views/Physician.tab'
import RxFormTab from './views/RxForm.tab'
import OfficeNotesTab from './views/OfficeNotes.tab'
import HstDeviceTab from './views/HstDevice.tab'
import DocumentsTab from './views/Documents.tab'
import OneShipTab from './views/OneShip.tab'
import AOBFormTab from './views/AOBForm.tab'
import UploadTab from './views/Upload.tab'
import ReportTab from './views/Report.tab'
import SummaryTab from './views/Summary.tab'
import routes from '../constants/routes'
import DeviceModal from './views/device/DeviceModal'
import TestConditionModal from './views/testCondition/TestConditionModal'
import { Note, Order } from 'store/types'
import { useSubmitForVerificationMutation } from 'store/services/dmeOrder'
import orderState from 'helpers/orderStateHelpers'
import { HstDeviceModal } from 'components/lab/orderEdit/NightOwl/HstDeviceModal'
import OneShipModal from './views/oneShip/OneShipModal'

const ActionButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 1rem;
  z-index: 10;
`

const nightOwlFocusStyle = {
  backgroundColor: '#20b2aa59 !important',
  borderColor: '#20b2aa79 !important',
  color: '#1f2d3d !important',
}

interface IProps {
  order: Order
  notes: Note[]
  refetchOrder: () => void
  deviceModalOpen: boolean
  setDeviceModalOpen: (v: boolean) => void
  nightOwlModalOpen: boolean
  setNightOwlModalOpen: (v: boolean) => void
  hstDeviceModalOpen: boolean
  setHstDeviceModalOpen: (v: boolean) => void
  oneShipSupported: boolean
  oneShipModalOpen: boolean
  setOneShipModalOpen: (v: boolean) => void
}

const EditOrderView = (props: IProps) => {
  const {
    order,
    deviceModalOpen,
    setDeviceModalOpen,
    nightOwlModalOpen,
    setNightOwlModalOpen,
    hstDeviceModalOpen,
    setHstDeviceModalOpen,
    oneShipSupported,
    oneShipModalOpen,
    setOneShipModalOpen,
  } = props
  const stateHelpers = orderState(order)
  const navigate = useNavigate()
  const { orderId, tab } = useParams()
  const isPatientValid = stateHelpers?.isPatientValid
  const isPhysicianValid = stateHelpers?.isPhysicianValid
  const isPayerValid = stateHelpers?.isPayerValid
  const isRxValid = stateHelpers?.isRxProvidedOrPromised
  const isOfficeNotesValid = stateHelpers?.isOfficeNotesProvidedOrPromised
  const isHstDeviceValid = stateHelpers?.isHstDeviceValid
  const [submitForVerification] = useSubmitForVerificationMutation()

  const rxFaxPromise = stateHelpers?.isRxPromised
  const officeNotesFaxPromise = stateHelpers?.isOfficeNotesPromised
  const aobFaxPromise = stateHelpers?.isAobPromised
  const hasAob = stateHelpers?.isAobUploaded
  const isAobFailed = stateHelpers?.isAobFailed
  const uploadDisabled = order?.selectedSessions?.length
  const readyForReview = stateHelpers?.isReadyForReview

  const closeHstDeviceModal = useCallback(() => setHstDeviceModalOpen(false), [])

  const firstGroupTabDisabled = () =>
    tab === 'summary' ||
    tab === 'AOBForm' ||
    tab === 'device' ||
    tab === 'Documents' ||
    tab === 'oneship' ||
    tab === 'upload' ||
    tab === 'report'

  const secondGroupTabDisabled = () =>
    tab !== 'summary' &&
    tab !== 'AOBForm' &&
    tab !== 'device' &&
    tab !== 'Documents' &&
    tab !== 'oneship' &&
    tab !== 'upload' &&
    tab !== 'report'

  const handleVerification = () => {
    navigate(`${routes.index}${routes.order.edit}${orderId}/summary`)
  }
  const infoVerification = !firstGroupTabDisabled() && readyForReview

  useEffect(() => {
    if (!order || order.id !== orderId) return
    if (stateHelpers.isOnSecondTabGroup && secondGroupTabDisabled()) {
      navigate(`/dme/orderEdit/${orderId}/summary`, { replace: true })
    } else if (!tab) {
      navigate(`/dme/orderEdit/${orderId}/${order?.testType === 'HST' ? 'RxForm' : 'patient'}`, {
        replace: true,
      })
    }
  }, [tab, order])

  const cancelVerification = () => navigate(`${routes.index}${routes.order.edit}${orderId}/patient`)
  const submitOrderForVerification = () =>
    submitForVerification({ orderId: Number(orderId) })
      .unwrap()
      .then(() => {
        if (order.testType !== 'HST') {
          navigate(`${routes.index}${routes.order.edit}${orderId}/AOBForm`)
        }
      })

  const tabs = [
    {
      key: 'patient',
      disabled: firstGroupTabDisabled() || order?.testType === 'HST',
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Patient'),
      icon: isPatientValid ? 'fa fa-check text-success' : '',
      Content: PatientTab,
    },
    {
      key: 'physician',
      disabled: firstGroupTabDisabled() || order?.testType === 'HST',
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Physician'),
      icon: isPhysicianValid ? 'fa fa-check text-success' : '',
      Content: PhysicianTab,
    },
    {
      key: 'insurance',
      disabled: firstGroupTabDisabled() || order?.testType === 'HST',
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Insurance'),
      icon: isPayerValid ? 'fa fa-check text-success' : '',
      Content: InsuranceTab,
    },
    {
      key: 'RxForm',
      disabled: firstGroupTabDisabled(),
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Rx Form'),
      icon: isRxValid
        ? rxFaxPromise
          ? 'fas fa-exclamation-triangle text-warning'
          : 'fa fa-check text-success'
        : '',
      Content: RxFormTab,
    },
    {
      key: 'officeNotes',
      disabled: firstGroupTabDisabled() || order?.testType !== 'HST',
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Office Notes'),
      icon: isOfficeNotesValid
        ? officeNotesFaxPromise
          ? 'fas fa-exclamation-triangle text-warning'
          : 'fa fa-check text-success'
        : '',
      Content: OfficeNotesTab,
    },
    {
      key: 'hstDevice',
      disabled: true, // this is deprecated and not used for HST or any other test type
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('HST Requirements'),
      icon: isHstDeviceValid ? 'fa fa-check text-success' : '',
      Content: HstDeviceTab,
    },
    {
      key: 'summary',
      disabled: secondGroupTabDisabled(),
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Summary'),
      icon: '',
      Content: SummaryTab,
    },
    {
      key: 'AOBForm',
      disabled: secondGroupTabDisabled() || order?.testType === 'HST',
      disabledTab: secondGroupTabDisabled() || readyForReview,
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('AOB Form'),
      icon: isAobFailed
        ? 'fas fa-exclamation-triangle text-danger'
        : hasAob
        ? 'fa fa-check text-success'
        : aobFaxPromise
        ? 'fas fa-exclamation-triangle text-warning'
        : 'fas fa-exclamation-triangle text-danger',
      iconClass: hasAob ? 'text-success' : aobFaxPromise ? 'text-warning' : 'text-danger',
      Content: AOBFormTab,
    },
    // {
    //   key: 'device',
    //   disabled: secondGroupTabDisabled() || order?.testType === 'HST',
    //   disabledTab: secondGroupTabDisabled() || readyForReview,
    //   to: `${routes.index}${routes.order.edit}${orderId}`,
    //   className: 'font-weight-bold',
    //   labelSmall: generalHelper.t('Device'),
    //   icon: order?.returnDoneDtUTC ? 'fa fa-check text-success' : '',
    //   iconClass: order?.returnDoneDtUTC ? 'text-success' : 'text-danger',
    //   Content: DeviceTab,
    // },
    {
      key: 'Documents',
      disabled: secondGroupTabDisabled(),
      disabledTab: secondGroupTabDisabled() || readyForReview,
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Documents'),
      icon: isRxValid
        ? rxFaxPromise
          ? 'fas fa-exclamation-triangle text-warning'
          : 'fa fa-check text-success'
        : 'fas fa-exclamation-triangle text-danger',
      Content: DocumentsTab,
    },
    {
      key: 'oneship',
      disabled:
        secondGroupTabDisabled() ||
        !order?.thirdPartyDevice ||
        order?.thirdPartyDevice?.deviceType !== 'TESTSMARTER',
      disabledTab: secondGroupTabDisabled() || readyForReview,
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: 'OneShip',
      icon: '',
      Content: OneShipTab,
    },
    {
      key: 'upload',
      disabled: secondGroupTabDisabled() ||
        uploadDisabled ||
        order?.testType === 'HST' ||
        order?.thirdPartyDevice?.deviceType === 'TESTSMARTER',
      disabledTab: secondGroupTabDisabled() || readyForReview,
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Upload'),
      icon: '',
      Content: UploadTab,
    },
    {
      key: 'report',
      disabled: secondGroupTabDisabled(),
      disabledTab: secondGroupTabDisabled() || readyForReview,
      to: `${routes.index}${routes.order.edit}${orderId}`,
      className: 'font-weight-bold',
      labelSmall: generalHelper.t('Report'),
      icon: '',
      Content: ReportTab,
    },
  ]

  if (!tab) {
    return <></>
  }

  const actionBtn = () => {
    if (!firstGroupTabDisabled())
      return (
        infoVerification && (
          <ReactButton onClick={handleVerification} variant="warning" className="pulse-warning">
            Review Submission
          </ReactButton>
        )
      )
    if (stateHelpers.isReadyForReview) {
      return [
        <Button
          onClick={cancelVerification}
          className="mx-1 btn-outline-secondary mr-2"
          label="Cancel"
        />,
        <ReactButton
          onClick={submitOrderForVerification}
          variant="warning"
          className="pulse-warning">
          Submit For Verification
        </ReactButton>,
      ]
    }
    if (stateHelpers.canDmeResubmitForVerification) {
      return [
        <ReactButton
          onClick={submitOrderForVerification}
          variant="warning"
          className="pulse-warning">
          Resubmit For Verification
        </ReactButton>,
      ]
    }
  }

  return (
    <div className="container-fluid">
      <DeviceModal
        order={order}
        open={deviceModalOpen}
        onClose={(closeAction?: () => void) => {
          setDeviceModalOpen(false)
          if (closeAction && typeof closeAction === 'function') {
            closeAction()
          }
        }}
      />
      {/* <NightOwlModal
        order={order}
        open={nightOwlModalOpen}
        onClose={() => setNightOwlModalOpen(false)}
      /> */}
      <OneShipModal
        order={order}
        open={oneShipModalOpen}
        onClose={() => setOneShipModalOpen(false)}
      />
      <HstDeviceModal order={order} open={hstDeviceModalOpen} onClose={closeHstDeviceModal} />
      <Modal
        id="testCondition_modal"
        modalSize="modal-lg"
        title={`Test Condition for Order #${orderId}`}
        content={<TestConditionModal {...props} />}
      />
      <div>
        {stateHelpers.isOnSecondTabGroup &&
          !order?.device?.id &&
          !order?.thirdPartyDevice &&
          !order?.selectedSessions &&
          !(order?.sessions?.sessions?.length && order?.tests?.length === 1) &&
          order.testType !== 'HST' && (
            <div className="row mt-2 mb-4">
              <div className="col-6">
                <ReactButton
                  onClick={() => setDeviceModalOpen(true)}
                  variant="warning"
                  className=""
                  size="lg">
                  Choose Oximeter
                </ReactButton>
              </div>
              {order.testType === 'POX' && oneShipSupported && (
                <div className="col-6">
                  <ReactButton
                    onClick={() => setOneShipModalOpen(true)}
                    size="lg"
                    css={{
                      backgroundColor: '#20b2aa20',
                      borderColor: '#20b2aa40',
                      color: '#1f2d3d',
                      '&:hover': nightOwlFocusStyle,
                      '&:active': nightOwlFocusStyle,
                      '&:focus': nightOwlFocusStyle,
                    }}>
                    Proceed with OneShip
                  </ReactButton>
                </div>
              )}
              {/* {order.testType === 'POX' && nightowlSupported && (
                <div className="col-6">
                  <ReactButton
                    onClick={() => setNightOwlModalOpen(true)}
                    size="lg"
                    css={{
                      backgroundColor: '#20b2aa20',
                      borderColor: '#20b2aa40',
                      color: '#1f2d3d',
                      '&:hover': nightOwlFocusStyle,
                      '&:active': nightOwlFocusStyle,
                      '&:focus': nightOwlFocusStyle,
                    }}>
                    <img src={NightOwlIcon} alt="nightowl" />
                    Proceed with NightOwl
                  </ReactButton>
                </div>
              )} */}
            </div>
          )}
        <div className="row position-relative">
          <ActionButtonContainer>{actionBtn()}</ActionButtonContainer>
          <div className="col-lg-12 col-md-12">
            {order && (
              <Tab
                cardClass="card-default"
                handleTabChange={props.handleTabChange}
                tabs={tabs}
                activeTab={tab}
                tabProps={{ ...props, setDeviceModalOpen, stateHelpers }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditOrderView
