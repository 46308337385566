import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithLogout } from 'helpers/generalHelper'

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: [
    'User',
    'Order',
    'OrderHistory',
    'Patient',
    'Company',
    'Office',
    'OfficeList',
    'OrderCounts',
    'OrderList',
    'Physicians',
    'PhysicianSettings',
    'Messages',
    'Notes',
    'IncomingFax',
    'LabPayer',
    'LabPayerList',
    'PayerHistory',
    'LabDmePayers',
    'DeviceBrands',
    'Devices',
    'Tickets',
    'OneShipCounts',
    'DailyCounts',
  ],
  baseQuery: baseQueryWithLogout,
  endpoints: () => ({}),
})
