import moment from 'moment'
import Button from 'components/atoms/Button'
import { Order } from 'store/types'

interface IProps {
  order: Order
  setOrderVerified: (v: boolean) => void
}

const OrderInfo = ({ order, setOrderVerified }: IProps) => {
  const { patient, office, physician, physicianAddress, state } = order

  if (state?.AOB === 'DONE') {
    return (
      <div className="row mt-4">
        <div className="col">
          <h4>Assignment of Benefits Forms Already Signed</h4>
          <p>We have already received your signed Assignment of Benefits form.</p>
          <a href="https://www.testsmarter.net/" className="btn btn-primary">
            Close this and go to our website
          </a>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col d-flex mb-3">
          <h3>Order Information</h3>
          <Button
            id="btn-ts"
            label="Continue"
            type="button"
            className="btn-sm ml-auto"
            onClick={() => setOrderVerified(true)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="patientInfo">
            Please confirm you are the patient listed below and have requested oxygen from{' '}
            {office.officeName}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>
            <span className="d-block text-bold">{`${patient.firstName} ${patient.lastName}`}</span>
            <span className="d-block">{`Date of Birth: ${moment(patient.dob).format(
              'MM/DD/YYYY',
            )}`}</span>
            <span className="d-block">{`Order Number: ${order.id}`}</span>
            <span className="d-block">{`Date of Order: ${moment(order.confirmedAt).format(
              'MM/DD/YYYY',
            )}`}</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex">
          <p className="mr-5">
            <span className="d-block text-bold">Ordering Physician</span>
            <span className="d-block">{`${physician?.firstName} ${physician?.lastName}`}</span>
            <span className="d-block">{`${physicianAddress?.addressStreetLine1}`}</span>
            <span className="d-block">{`${physicianAddress?.addressStreetLine2 ?? ''}`}</span>
            <span className="d-block">
              {`${physicianAddress?.addressCity}, ${physicianAddress?.addressState} ` +
                `${physicianAddress?.addressZip}`}
            </span>
          </p>
          <p>
            <span className="d-block text-bold">Oxygen Supplier</span>
            <span className="d-block">{office.officeName}</span>
            <span className="d-block">{office.addressStreetLine1}</span>
            <span className="d-block">
              {office.addressCity}, {office.addressState} {office.addressZip?.slice(0, 5)}
            </span>
            <span className="d-block">Tel: {office.telephone}</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>PATIENT ACKNOWLEDGEMENT</h4>
          <p>
            I understand that my physician ordered this overnight oximetry test for the purpose of measuring my oxygen
            saturation levels as it pertains to my pulmonary condition. I certify with my signature below that I am the
            recipient of this test and TestSmarter provided me with clear instructions for performing the test on my
            own. I further certify that neither I nor any other person tampered with the equipment delivered and/or the
            results therein while in my possession. I also understand that if this procedure is not covered by my
            insurance, I am responsible for the full price of $34.00, payable to TestSmarter. Any questions about this
            or any of the policies on the document can be directed to our office phone: (386) 382-4200.
          </p>
          <h4>ASSIGNMENT OF BENEFIT</h4>
          <p>
            TestSmarter Inc. is an approved and credentialed Independent Diagnostic Testing Facility (IDTF) that
            performs overnight diagnostic oximetry testing. I hereby authorize TestSmarter to bill my insurance on my
            behalf for the cost of the overnight oximetry test. I understand that I may be financially responsible for
            any claim denial, deductible, and/or co-pay and agree to make such payment to TestSmarter at the time of
            billing. Furthermore, I understand that if I do not have insurance coverage, I acknowledge that I will be
            personally billed and I accept full responsibility for prompt payment for the cost of the test.
          </p>
          <h4>RELEASE OF MEDICAL RECORDS</h4>
          <p>
            I hereby authorize the release of the results of this test and any related documents to my physician who
            ordered this test and the company listed above as "Home Medical Equipment Supplier". I also authorize the
            "Home Medical Equipment Supplier" to discuss with my physician any present treatments and/or follow-up
            services that may be a result of this test. By signing this form, I authorize the IDTF to release documents
            related to and including results of this test to the "Home Medical Equipment Supplier" listed on this form
            and referring physician. Consistent with Medicare policy, the "Home Medical Equipment Supplier" is not
            involved in the aspects of this procedure other than acting in the capacity of a courier for the testing
            device.
          </p>
          <h4>NOTICE OF PRIVACY PRACTICES</h4>
          <p>
            You have the right to read our Privacy Policy below prior to signing this document. The notice provides a
            description of the protection of your personal health information under HIPAA laws governing the handling of
            said information. Please read the policies in their entirety prior to signing this document.
          </p>
          <h4>PRIVACY POLICY</h4>
          <p>
            TestSmarter Inc. is required to follow the federal Health Insurance Portability and Accountability Act
            (HIPAA) Laws written to protect the confidentiality of your health information. The changes in the evolution
            of computer technology that is used in healthcare has prompted the government to seek a way to standardize
            and protect the electronic exchange of health information. TestSmarter Inc. respects your privacy,
            understanding that your personal health information is sensitive. We will not disclose your information
            unless you tell us to do so, or unless the law authorizes or requires us to do so. HIPAA protects the
            privacy of the health information we create and obtain in providing our care and services to you. Your
            protected health information includes our test results, diagnosis, treatment, health information from other
            providers, and billing/payment information related to the services. Federal and state laws allow us to use
            and disclose your protected health information for purposes of treatments and health care operations. State
            law requires us to get authorization to disclose this information for payment purposes. For complete privacy
            policy please visit www.testsmarter.net/idtf-diagnostic-testing-facility/privacy-policy.
          </p>
        </div>
      </div>
    </>
  )
}

export default OrderInfo
