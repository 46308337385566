import * as api from 'helpers/api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as authHelper from 'helpers/authHelper'
import { getLogout } from './loginSlice'

// ------------------------------------------------------------
export const getInit = createAsyncThunk(
  'getInit',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const data = await api.get('/me')

      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
      if (error?.response?.status === 401) {
        await authHelper.logout()
        dispatch(getLogout())
      }
    }
  },
)

// ------------------------------------------------------------

type Office = {
  id: number
  name: string
}

const initialState = {
  pending: false,
  id: 0,
  userType: '',
  adminOf: null,
  isSsoEnabled: false,
  company: {
    name: '',
    id: '',
  },
  primaryOfficeId: '',
  currentOfficeId: '',
  physicianGroup: '',
  offices: [{ id: 0, officeName: 'office' }],
  currentOfficeTests: {},
  roles: [],
  title: '',
  profile: {
    firstName: '',
    lastName: '',
    email: '',
  },
  rejected: false,
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setCurrentOfficeTests: (state, action) => {
      console.log('action.payload', action.payload)
      state.currentOfficeTests = action.payload
    },
    switchOffice: (state, action) => {
      state.currentOfficeId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInit.pending, (state, action) => {
      state.id = 0
      state.userType = ''
      state.company = {
        name: '',
        id: '',
      }
      state.primaryOfficeId = ''
      state.currentOfficeId = ''
      state.physicianGroup = ''
      state.currentOfficeTests = {}
      state.offices = []
      state.roles = []
      state.title = ''
      state.profile = {
        firstName: '',
        lastName: '',
        email: '',
      }
      state.pending = true
      state.rejected = false
    })
    builder.addCase(getInit.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      if (!action?.payload) return
      state.id = action.payload?.id
      state.company.name = action.payload.company?.companyName
      state.company.id = action.payload.company?.id
      state.profile.firstName = action.payload.firstName
      state.profile.lastName = action.payload.lastName
      state.profile.email = action.payload.email
      state.offices = action.payload.offices
      state.roles = action.payload.roles
      state.title = action.payload.title
      state.physicianGroup = action.payload.physicianGroup
      state.primaryOfficeId = action.payload.primaryOffice
      state.currentOfficeId = action.payload.primaryOffice
      state.userType = action.payload.userType
      state.adminOf = action.payload.adminOf
    })
    builder.addCase(getInit.rejected, (state, action) => {
      state.id = 0
      state.userType = ''
      state.company.name = ''
      state.company.id = ''
      state.primaryOfficeId = ''
      state.currentOfficeId = ''
      state.physicianGroup = ''
      state.offices = []
      state.roles = []
      state.title = ''
      state.profile = {
        firstName: '',
        lastName: '',
        email: '',
      }
      state.pending = false
      state.rejected = true
    })
    builder.addCase(getLogout.fulfilled, (state, action) => {
      return { ...initialState }
    })
  },
})

export const { setCurrentOfficeTests, switchOffice } = localSlice.actions

export default localSlice.reducer
