export type notification = {
  flag: notificationTypes
  label: string
  description: string
}

export enum notificationTypes {
  DM_RECEIVED = 'DM_RECEIVED',
  ORDER_STATUS = 'ORDER_STATUS',
  ORDER_UPDATED = 'ORDER_UPDATED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  FORM_RECEIVED = 'FORM_RECEIVED',
  ORDER_VERIFICATION_STATUS = 'ORDER_VERIFICATION_STATUS',
  OXIMETER_DATA_UPLOADED = 'OXIMETER_DATA_UPLOADED',
  OXIMETER_UPLOAD_COMPLETED = 'OXIMETER_UPLOAD_COMPLETED',
  OXIMETER_UPLOAD_FAILED = 'OXIMETER_UPLOAD_FAILED',
  NEW_REPORT_READY = 'NEW_REPORT_READY',
  REPORT_FAX_DELIVERED = 'REPORT_FAX_DELIVERED',
  REPORT_FAX_FAILED = 'REPORT_FAX_FAILED',
  PATIENT_COMPLETED_ESIGN = 'PATIENT_COMPLETED_ESIGN',
  MONTHLY_STATS = 'MONTHLY_STATS',
  MOBILE_APP_UPLOAD = 'MOBILE_APP_UPLOAD',
  NIGHTOWL_UPLOADED = 'NIGHTOWL_UPLOADED',
  NIGHTOWL_COMPLETED = 'NIGHTOWL_COMPLETED',
}

export const data: Record<string, notification[]> = {
  office: [
    {
      flag: notificationTypes.DM_RECEIVED,
      label: 'Direct Message Received',
      description: 'You have received a direct message from TestSmarter staff.',
    },
    {
      flag: notificationTypes.ORDER_VERIFICATION_STATUS,
      label: 'Order Verification Status',
      description: 'The verification status has changed for an order.',
    },
    {
      flag: notificationTypes.MOBILE_APP_UPLOAD,
      label: 'Mobile App Upload',
      description: 'Your staff has uploaded oximetry data using the TS DME mobile app.',
    },
    {
      flag: notificationTypes.NIGHTOWL_UPLOADED,
      label: 'Patient App Upload',
      description: 'A patient has uploaded their OneShip device.',
    },
    {
      flag: notificationTypes.PATIENT_COMPLETED_ESIGN,
      label: 'AOB eSign Completed',
      description: 'A patient has electronically signed an AOB form.',
    },
  ],
  sales: [
    {
      flag: notificationTypes.NEW_REPORT_READY,
      label: 'New Report Ready',
      description: 'A new report has been generated and will be faxed to the physician.',
    },
    {
      flag: notificationTypes.REPORT_FAX_DELIVERED,
      label: 'Report Fax Delivered',
      description: 'A report has been faxed to the physician.',
    },
    {
      flag: notificationTypes.REPORT_FAX_FAILED,
      label: 'Report Fax Failed',
      description: 'A report was not able to be faxed to the physician.',
    },
  ],
  management: [
    {
      flag: notificationTypes.ORDER_UPDATED,
      label: 'Order Updated',
      description: 'Changes have been made to an order (patient, physician, etc.).',
    },
    {
      flag: notificationTypes.ORDER_CANCELLED,
      label: 'Order Cancelled',
      description: 'An order has been cancelled.',
    },
    {
      flag: notificationTypes.FORM_RECEIVED,
      label: 'Form Received',
      description: 'We have received the Rx or AOB form for an order.',
    },
    {
      flag: notificationTypes.OXIMETER_DATA_UPLOADED,
      label: 'Oximeter Data Uploaded',
      description: 'Your staff has uploaded oximetry data using the TestSmarter website.',
    },
    {
      flag: notificationTypes.MONTHLY_STATS,
      label: 'Montly Stats Email',
      description: 'Monthly stats email for your location(s).',
    },
  ],
}

export const DEFAULT_NOTIFICATION_PREFS = {
  COMPANY_OWNER: {
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
  OFFICE_MANAGER: {
    [`EMAIL_${notificationTypes.DM_RECEIVED}`]: true,
    [`EMAIL_${notificationTypes.ORDER_VERIFICATION_STATUS}`]: true,
    [`EMAIL_${notificationTypes.MOBILE_APP_UPLOAD}`]: true,
    [`EMAIL_${notificationTypes.OXIMETER_DATA_UPLOADED}`]: true,
    [`EMAIL_${notificationTypes.NEW_REPORT_READY}`]: true,
    [`EMAIL_${notificationTypes.REPORT_FAX_FAILED}`]: true,
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
  OFFICE_TEAM_LEAD: {
    [`EMAIL_${notificationTypes.DM_RECEIVED}`]: true,
    [`EMAIL_${notificationTypes.ORDER_VERIFICATION_STATUS}`]: true,
    [`EMAIL_${notificationTypes.MOBILE_APP_UPLOAD}`]: true,
    [`EMAIL_${notificationTypes.OXIMETER_DATA_UPLOADED}`]: true,
    [`EMAIL_${notificationTypes.NIGHTOWL_UPLOADED}`]: true,
    [`EMAIL_${notificationTypes.NIGHTOWL_COMPLETED}`]: true,
    [`EMAIL_${notificationTypes.REPORT_FAX_FAILED}`]: true,
    [`EMAIL_${notificationTypes.ORDER_CANCELLED}`]: true,
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
  RESPIRATORY_THERAPIST: {
    [`EMAIL_${notificationTypes.NEW_REPORT_READY}`]: true,
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
  CSR: {
    [`EMAIL_${notificationTypes.DM_RECEIVED}`]: true,
    [`EMAIL_${notificationTypes.ORDER_VERIFICATION_STATUS}`]: true,
    [`EMAIL_${notificationTypes.MOBILE_APP_UPLOAD}`]: true,
    [`EMAIL_${notificationTypes.OXIMETER_DATA_UPLOADED}`]: true,
    [`EMAIL_${notificationTypes.NIGHTOWL_UPLOADED}`]: true,
    [`EMAIL_${notificationTypes.NIGHTOWL_COMPLETED}`]: true,
    [`EMAIL_${notificationTypes.REPORT_FAX_FAILED}`]: true,
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
  SALES_REPS: {
    [`EMAIL_${notificationTypes.NEW_REPORT_READY}`]: true,
    [`EMAIL_${notificationTypes.REPORT_FAX_FAILED}`]: true,
    [`EMAIL_${notificationTypes.ORDER_CANCELLED}`]: true,
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
  DRIVER: {
    [`EMAIL_${notificationTypes.MONTHLY_STATS}`]: true,
  },
}
