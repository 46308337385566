/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import moment from 'moment'
import { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Select from 'react-select'

import { Order } from 'store/types'
import {
  useCompleteHstOrderMutation,
  useUpdateHstStatusMutation,
  useUploadHstReportMutation,
} from 'store/services/labOrder'
import Upload from 'components/atoms/Upload'
import Toast from 'components/atoms/Toast'
import Dropdown from 'react-bootstrap/Dropdown'
import DateTimePicker from '../../../atoms/DateTimePicker'
import Input from '../../../atoms/Input'
import { confirm } from '../../../molecules/Confirmation'
import { hstStatusOptions } from './DeviceShipping'
import { TimeWrapper } from './FollowUpForm'

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0.55rem 1rem;
`

interface IProps {
  order: Order
}

export const PatientTesting = ({ order }: IProps) => {
  const [hstStatus, setHstStatus] = useState({ value: 'S002', label: 'Device Shipped' })
  const [updateStatus] = useUpdateHstStatusMutation()
  const [uploadReport] = useUploadHstReportMutation()
  const [completeHstOrder] = useCompleteHstOrderMutation()
  const [uploadType, setUploadType] = useState('')
  const [dateOfService, setDateOfService] = useState<string | undefined>(undefined)
  const [ahi, setAhi] = useState<string | undefined>(undefined)
  const uploadRef = useRef<HTMLInputElement>()

  const handleUpload = async (acceptedFiles: File[]) => {
    const [file] = acceptedFiles
    if (!file?.name) return
    const formData = new FormData()
    formData.append('report', file)
    formData.append('orderId', String(order.id))
    formData.append('reportType', uploadType)
    formData.append('dateOfService', dateOfService)

    if (ahi) {
      formData.append('ahi', ahi)
    }

    uploadReport({ orderId: Number(order.id), formData }).then(() => {
      Toast({ type: 'success', label: `Report uploaded!` })
    })
  }

  const startUpload = (type: string) => () => {
    setUploadType(type)
    uploadRef?.current?.click()
  }

  return (
    <div className="w-100 mt-2 pl-3">
      <Upload className="invisible" ref={uploadRef} handleUpload={handleUpload} invisible />
      <div
        className="row"
        css={{
          width: '100%',
        }}>
        <div
          className="p-0 d-flex align-items-center justify-content-between"
          css={{
            width: '100%',
          }}>
          <div
            className="d-flex align-items-end justify-content-between"
            css={{
              width: '100%',
            }}>
            <div className="d-flex align-items-center">
              <Select
                className="basic-single mb-2"
                classNamePrefix="select"
                isSearchable
                placeholder="Select status"
                name="hst_status"
                onChange={(item) => {
                  if (!item) {
                    return
                  }

                  setHstStatus(item)
                }}
                options={hstStatusOptions}
                value={hstStatus}
                css={{
                  width: 300,
                  marginBottom: '0 !important',
                }}
              />
              <Button
                variant="outline-primary"
                onClick={async () => {
                  await updateStatus({
                    orderId: Number(order.id),
                    status: hstStatus.value,
                  })
                }}
                style={{
                  marginLeft: 10,
                }}>
                Set status
              </Button>
            </div>
            <div className="d-flex align-items-end" css={{ minWidth: 500 }}>
              <TimeWrapper>
                <label className="textGray" htmlFor="effectiveDate">
                  Date of Service
                  <span className="text-danger">*</span>
                </label>
                <DateTimePicker
                  value={dateOfService && moment(dateOfService, 'YYYY-MM-DD').toDate()}
                  handleChange={(date) => {
                    setDateOfService(moment(date).format('YYYY-MM-DD'))
                  }}
                  showTimeSelect={false}
                  dateFormat="MMMM d, yyyy"
                />
              </TimeWrapper>
              <div className="col-3 textGray">
                <label htmlFor="ahi">AHI</label>
                <Input
                  type="number"
                  name="ahi"
                  minValue="0"
                  maxValue="200"
                  className="form-control"
                  placeholder="0-200"
                  value={ahi ?? ''}
                  handleChange={(e) => {
                    setAhi(Number(e.target.value) > 200 ? '200' : e.target.value)
                  }}
                />
              </div>
              <DropdownButton
                disabled={!dateOfService}
                title="Upload Report "
                className="ml-2"
                onClick={(e) => e.stopPropagation()}
                css={{ cursor: 'default' }}>
                <StyledDropdownItem onClick={startUpload('PSG')}>PSG</StyledDropdownItem>
                <StyledDropdownItem onClick={startUpload('TITRATION')}>
                  Titration
                </StyledDropdownItem>
                <StyledDropdownItem onClick={startUpload('HST')}>HST</StyledDropdownItem>
                <StyledDropdownItem onClick={startUpload('FAILED_HST')}>
                  Failed HST
                </StyledDropdownItem>
              </DropdownButton>
              {!!order.reportRenderRequests.length && (
                <Button
                  variant="primary"
                  disabled={!!order.communicationScheduledOn}
                  className="ml-2 text-nowrap"
                  onClick={async () => {
                    confirm('', {
                      title: 'Are you sure?',
                      description:
                        'This will mark the order as complete and start the fax process.',
                      yesAction: 'Yes, proceed',
                      noAction: 'Cancel',
                    })
                      .then(async () => {
                        try {
                          await completeHstOrder({ orderId: Number(order.id) })
                          Toast({ type: 'success', label: `Order completed` })
                        } catch (err) {
                          Toast({ type: 'error', label: `Error completing order.` })
                        }
                      })
                      .catch(() => {})
                  }}>
                  Complete Order
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div
          className="col-lg-12 p-3 mt-3 d-flex flex-column align-items-end"
          css={{ background: '#F5F5F5' }}>
          <div className="w-100 mb-3 ml-2" css={{ backgroundColor: 'white' }}>
            <DeviceShippingStepper
              steps={[
                {
                  id: 1,
                  label: '1',
                  title: 'Data uploaded for Night 1',
                  ...getStatus(DreamclearStatusCodes.S004),
                },
                {
                  id: 2,
                  label: '2',
                  title: 'Data uploaded for Night 2',
                  ...getStatus(DreamclearStatusCodes.S005),
                },
                {
                  id: 3,
                  label: '3',
                  title: 'Data uploaded to Ensodata for Night 1',
                  ...getStatus(DreamclearStatusCodes.S008),
                },
                {
                  id: 4,
                  label: '4',
                  title: 'Data uploaded to Ensodata for Night 2',
                  ...getStatus(DreamclearStatusCodes.S009),
                },
                {
                  id: 5,
                  label: '5',
                  title: 'Study is Scored',
                  ...getStatus([DreamclearStatusCodes.S010, DreamclearStatusCodes.S011]),
                },
                {
                  id: 6,
                  label: '6',
                  title: 'Report Downloaded from Ensodata',
                  ...getStatus(DreamclearStatusCodes.S013),
                },
                {
                  id: 7,
                  label: '7',
                  title: 'Interpretation Signed',
                  ...getStatus(DreamclearStatusCodes.S014),
                },
              ]}
            />
          </div>
        </div>
      </div>*/}
    </div>
  )
}
