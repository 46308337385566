/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CastIcon from '@mui/icons-material/Cast'
import TagIcon from '@mui/icons-material/Tag'
import CodeIcon from '@mui/icons-material/Code'
import SettingsApplicationsIcon from '@mui/icons-material/Balcony'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LocalHospital from '@mui/icons-material/LocalHospital'
import TollIcon from '@mui/icons-material/Toll'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined'
import BedIcon from '@mui/icons-material/Bed'
import moment from 'moment'
import { formatPhoneNumber } from 'helpers/format'

import { TooltipWrapper } from 'components/atoms/TooltipWrapper'
import avatar from 'assets/img/avatar/user.svg'
import routes from 'components/lab/constants/routes'
import TestConditionWidget from 'components/dme/orderEdit/views/header/TestConditionWidget'
import { Order } from 'store/types'
import config from 'config/app'
import { confirm } from 'components/molecules/Confirmation'

const testName = {
  HST: 'Home Sleep Test',
  POX: 'Pulse Oximetry',
  COX: 'Capnography',
}
const deviceTypes = {
  NIGHT_OWL: 'NightOwl',
  DREAM_CLEAR: 'Dreamclear',
  HST: 'Home Sleep Device',
}
interface IPatientInfo {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  email?: string
  homePhone?: string
  smartPhone?: string
  id: number
  name: string
  testTitle: string
  profileImageUrl?: string
  dateOfBirth?: string

  order: Order

  testTypeMatch?: boolean
  rxTestType?: string
}

interface IContactInfo {
  officeName: string
  officeId: number
  physicianName: string
}

interface ILocationInfo {
  order: Order
  deviceKind?: string
  serialNumber?: string
  deviceBrand?: string
  coPayAmount: string
  stripeCustomerId: string
  createMobileInvite: () => void
  addNight: (night: number) => void
}

interface PatientHeaderTwoProps {
  order: Order
  onChangeDeviceClick: () => void
  onEditTestConditionsClick: () => void
  createMobileInvite: (params: any) => void
  addNight: (night: number) => void
}

const PatientInfo = ({
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  email,
  homePhone,
  smartPhone,
  id,
  name,
  testTitle,
  order,
  dateOfBirth,
  testTypeMatch,
  rxTestType,
}: IPatientInfo & { onChangeDeviceClick: () => void }) => (
  <Grid
    container
    direction="column"
    sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
    rowGap="5px">
    <Link to={`${routes.index}${routes.patient.root}${id}`}>
      <IconButton sx={{ position: 'absolute', top: '10px', right: '10px', padding: '2px' }}>
        <OpenInNewOutlinedIcon htmlColor="#000000" />
      </IconButton>
    </Link>
    <Grid item container flexWrap="nowrap" columnGap="8px" alignItems="center">
      <img src={avatar} alt="avatar" css={{ width: '25px', height: '25px' }} />
      <Grid container direction="row">
        <Typography sx={{ font: '600 12px/14px Source Sans Pro', marginRight: '12px' }}>
          {name}
        </Typography>
        <TooltipWrapper text={rxTestType || ''} show={!testTypeMatch}>
          <Typography
            sx={{
              font: '600 13px/16px Source Sans Pro',
              color: testTypeMatch ? '#BAC1C9' : '#FF110050',
            }}>
            {testTitle}
          </Typography>
        </TooltipWrapper>
      </Grid>
    </Grid>
    <Grid item container flexWrap="nowrap" justifyContent="space-between">
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '5px',
          font: '600 12px/14px Source Sans Pro',
          color: '#6D757E',
        }}>
        <CalendarMonthIcon htmlColor="#6D757E" />
        Date of Birth:
      </Typography>
      <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>
        {moment(dateOfBirth).format('MM/DD/YYYY')} ({moment().diff(dateOfBirth, 'years')})
      </Typography>
    </Grid>
    <Divider />
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      paddingBottom={'10px'}
      rowGap="5px">
      <Grid container wrap="nowrap" justifyContent="space-between">
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '5px',
            font: '600 12px/14px Source Sans Pro',
            color: '#6D757E',
          }}>
          <EmailOutlinedIcon htmlColor="#6D757E" />
          Email:
        </Typography>
        <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>{email}</Typography>
      </Grid>
      <Grid container wrap="nowrap" justifyContent="space-between">
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '5px',
            font: '600 12px/14px Source Sans Pro',
            color: '#6D757E',
          }}>
          <CallOutlinedIcon htmlColor="#6D757E" /> Home Phone:
        </Typography>
        <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>
          {homePhone && formatPhoneNumber(homePhone)}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" justifyContent="space-between">
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '5px',
            font: '600 12px/14px Source Sans Pro',
            color: '#6D757E',
          }}>
          <SmartphoneOutlinedIcon htmlColor="#6D757E" />
          Smart Phone:
        </Typography>
        <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>
          {smartPhone && formatPhoneNumber(smartPhone)}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start">
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '5px',
            font: '600 12px/14px Source Sans Pro',
            color: '#6D757E',
          }}>
          <LocationOnOutlinedIcon htmlColor="#6D757E" />
          Address:
        </Typography>
        <div>
          <Typography
            component="p"
            sx={{ font: '600 12px/14px Source Sans Pro', textAlign: 'end' }}>
            {addressLine1}
          </Typography>
          <Typography
            component="p"
            sx={{ font: '600 12px/14px Source Sans Pro', textAlign: 'end' }}>
            {addressLine2}
          </Typography>
          <Typography
            component="p"
            sx={{ font: '600 12px/14px Source Sans Pro', textAlign: 'end' }}>
            {city}, {state} {zip}
          </Typography>
        </div>
      </Grid>
    </Grid>
  </Grid>
)

const ContactInfo = ({ officeId, officeName, physicianName }: IContactInfo) => (
  <Grid
    container
    direction="column"
    sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
    justifyContent="space-between">
    <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>{/*Contact Info:*/}</Typography>
    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start">
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '5px',
          font: '600 12px/14px Source Sans Pro',
          color: '#6D757E',
        }}>
        <LocalShippingIcon htmlColor="#6D757E" />
        DME Office:
      </Typography>
      <div>
        <Link to={`${routes.index}${routes.office.root}${routes.upsert}${officeId}`}>
          <Typography
            component="p"
            sx={{ font: '600 12px/14px Source Sans Pro', textAlign: 'end' }}>
            {officeName}
          </Typography>
        </Link>
      </div>
    </Grid>
    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start" mb={2}>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '5px',
          font: '600 12px/14px Source Sans Pro',
          color: '#6D757E',
        }}>
        <LocalHospital htmlColor="#6D757E" />
        Physician:
      </Typography>
      <div>
        <Typography component="p" sx={{ font: '600 12px/14px Source Sans Pro', textAlign: 'end' }}>
          {physicianName}
        </Typography>
      </div>
    </Grid>
  </Grid>
)

const LocationInfo = ({
  deviceKind,
  serialNumber,
  deviceBrand,
  createMobileInvite,
  coPayAmount,
  stripeCustomerId,
  order,
  addNight,
}: ILocationInfo) => {
  const mobileInviteCode = Object.values(order?.mobileInvites || {})
    ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    ?.pop()?.code

  return (
    <Grid
      container
      direction="column"
      sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
      justifyContent="space-between">
      <Grid item container flexWrap="nowrap" alignItems="center" columnGap="5px">
        <IconButton sx={{ borderRadius: '100px', background: '#F4F6F9' }}>
          <CastIcon fontSize="small" htmlColor="#0FA2B4" />
        </IconButton>
        <Grid container direction="column">
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Typography
              sx={{
                font: '600 12px/14px Source Sans Pro',
                color: '#6D757E',
                display: 'flex',
                alignItems: 'center',
                columnGap: '5px',
              }}>
              <DarkModeOutlinedIcon htmlColor="#6D757E" /> Device:
            </Typography>
            <Grid
              columnGap={'2px'}
              container
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="flex-end">
              <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>{deviceKind}</Typography>
              {/* {order?.testType === 'HST' && (
              <IconButton
                onClick={onChangeDeviceClick}
                disableRipple
                sx={{ background: '#2C83BD', padding: '3px' }}>
                <AutorenewOutlinedIcon fontSize="small" htmlColor="#ffffff" />
              </IconButton>
            )} */}
            </Grid>
          </Grid>
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Typography
              sx={{
                font: '600 12px/14px Source Sans Pro',
                color: '#6D757E',
                display: 'flex',
                alignItems: 'center',
                columnGap: '5px',
              }}>
              <TagIcon htmlColor="#6D757E" /> Serial No:
            </Typography>
            <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>{serialNumber}</Typography>
          </Grid>
          {deviceBrand && (
            <Grid container flexWrap="nowrap" justifyContent="space-between">
              <Typography
                sx={{
                  font: '600 12px/14px Source Sans Pro',
                  color: '#6D757E',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '5px',
                }}>
                <SettingsApplicationsIcon htmlColor="#6D757E" /> Model:
              </Typography>
              <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>{deviceBrand}</Typography>
            </Grid>
          )}

          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Typography
              sx={{
                font: '600 12px/14px Source Sans Pro',
                color: '#6D757E',
                display: 'flex',
                alignItems: 'center',
                columnGap: '5px',
              }}>
              <CodeIcon htmlColor="#6D757E" /> Patient App Code:
            </Typography>
            {mobileInviteCode ? (
              <div className="d-flex align-items-center">
                <Button
                  className="mr-3"
                  variant="outline-primary"
                  disabled={!order?.patient?.email}
                  onClick={() =>
                    order &&
                    confirm('', {
                      title: 'Are you sure?',
                      description:
                        "This will re-generate the mobile invite code and send it to the patient's email.",
                      yesAction: 'Regenerate code',
                      noAction: 'Cancel',
                    })
                      .then(() => createMobileInvite())
                      .catch(() => {})
                  }
                  css={{ whiteSpace: 'nowrap', padding: '2px 5px' }}>
                  Generate new
                </Button>
                <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>
                  {mobileInviteCode}
                </Typography>
              </div>
            ) : order?.testType === 'HST' ? (
              <Button
                variant="outline-primary"
                disabled={!order?.patient?.email}
                onClick={() => order && createMobileInvite()}
                css={{ whiteSpace: 'nowrap', padding: '2px 5px' }}>
                Create
              </Button>
            ) : (
              '-'
            )}
          </Grid>

          {order?.testType === 'HST' && (
            <Grid container flexWrap="nowrap" marginTop="5px" justifyContent="space-between">
              <Typography
                sx={{
                  font: '600 12px/14px Source Sans Pro',
                  color: '#6D757E',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '5px',
                }}>
                <BedIcon htmlColor="#6D757E" /> Nights:
              </Typography>
              <div className="d-flex align-items-center">
                <Typography sx={{ font: '600 12px/14px Source Sans Pro' }}>
                  {order?.deviceData?.filter((x) => x?.params?.isValid)?.length || '-'} /{' '}
                  {order?.tests?.length || '-'}
                </Typography>
                <Button
                  className="ml-2"
                  variant="outline-primary"
                  onClick={() =>
                    order &&
                    confirm('', {
                      title: 'Are you sure?',
                      description: 'This will add additional night for the order.',
                      yesAction: 'Yes',
                      noAction: 'Cancel',
                    })
                      .then(() =>
                        addNight(
                          ([...(order?.tests || [])]?.sort((a, b) => b?.night - a?.night)?.[0]
                            ?.night || 0) + 1,
                        ),
                      )
                      .catch((e) => {
                        console.log(e)
                      })
                  }
                  css={{ whiteSpace: 'nowrap', padding: '0 5px' }}>
                  + Add night
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        paddingBottom={'10px'}
        rowGap="5px">
        <Grid container wrap="nowrap" alignItems="flex-start">
          <Grid
            padding="5px"
            container
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ background: '#F5F5F5' }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '5px',
                font: '600 12px/14px Source Sans Pro',
                color: '#6D757E',
              }}>
              <TollIcon htmlColor="#6D757E" />
              Copay amount:
            </Typography>
            <Typography
              component="p"
              sx={{ font: '600 12px/14px Source Sans Pro', textAlign: 'end' }}>
              {coPayAmount}
            </Typography>
          </Grid>
          {stripeCustomerId && (
            <Button
              variant="outline-primary"
              css={{ whiteSpace: 'nowrap', marginLeft: 5 }}
              href={`${config?.stripeCustomerUrl}${stripeCustomerId}`}
              target="_blank">
              Charge CC
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export const PatientHeaderTwo = ({
  order,
  onChangeDeviceClick,
  createMobileInvite,
  addNight,
}: PatientHeaderTwoProps) => {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const address =
    !order?.patient?.deliverySameAsPrimary && order?.patient?.deliveryAddressStreetLine1
      ? {
          addressLine1: order?.patient?.deliveryAddressStreetLine1 || '-',
          addressLine2: order?.patient?.deliveryAddressStreetLine2,
          city: order?.patient?.deliveryAddressCity || '-',
          state: order?.patient?.deliveryAddressState || '-',
          zip: order?.patient?.deliveryAddressZip?.replace(/_/g, '') || '-',
        }
      : {
          addressLine1: order?.patient?.primaryAddressStreetLine1 || '-',
          addressLine2: order?.patient?.primaryAddressStreetLine2,
          city: order?.patient?.primaryAddressCity || '-',
          state: order?.patient?.primaryAddressState || '-',
          zip: order?.patient?.primaryAddressZip?.replace(/_/g, '') || '-',
        }

  const patient = {
    patientInfo: {
      id: order?.patient?.id,
      name: order?.patient?.firstName
        ? `${order?.patient?.firstName} ${order?.patient?.lastName}`
        : '-',
      testTitle: order
        ? `${testName[order?.testType as keyof typeof testName]} #${order?.id}`
        : '-',
      dateOfBirth: order?.patient?.dob || '-',
      email: order?.patient?.email || '-',
      homePhone: order?.patient?.homePhone || '-',
      smartPhone: order?.patient?.mobilePhone || '-',
      ...address,
      rxTestType: order?.rxRecognitionData?.[0]?.data?.data?.ordered_service?.service_name,
      testTypeMatch:
        order?.testType !== 'POX' ||
        !order?.rxRecognitionData?.[0]?.data?.data?.ordered_service?.service_name ||
        (order?.testType === 'POX' &&
          order?.rxRecognitionData?.[0]?.data?.data?.ordered_service?.service_name
            ?.toLowerCase()
            ?.includes('oximetry')),
    },
    contactInfo: {
      officeName: `${order?.office?.officeName} - ${order?.office?.addressCity}, ${order?.office?.addressState}`,
      officeId: order?.officeId,
      physicianName: `${order?.physician?.firstName ?? ''} ${order?.physician?.lastName ?? ''} ${order?.physician?.credential || ''}`,
    },
    locationInfo: {
      deviceKind: order?.thirdPartyDevice
        ? deviceTypes[order?.thirdPartyDevice?.deviceType as keyof typeof deviceTypes]
        : order?.device
          ? order?.device?.brand
          : '-',
      serialNumber: order?.thirdPartyDevice?.nightOwlSerial || order?.device?.serial || '-',
      deviceBrand: order?.device?.brand,

      coPayAmount: order?.copay || order?.copay === 0 ? currency.format(order.copay) : '-',
      stripeCustomerId: order?.hst?.paymentSession?.customer,
    },
  }

  return (
    <Grid container columnGap="15px" flexWrap="nowrap">
      <PatientInfo
        {...patient.patientInfo}
        onChangeDeviceClick={onChangeDeviceClick}
        order={order}
      />

      <ContactInfo {...patient.contactInfo} />

      <LocationInfo
        {...patient.locationInfo}
        order={order}
        addNight={(night) => addNight(night)}
        createMobileInvite={() =>
          createMobileInvite({
            orderId: Number(order?.id),
            method: 'email',
            email: order?.patient?.email,
          })
        }
      />

      {order?.testType !== 'HST' && (
        <Grid
          container
          direction="column"
          sx={{ background: 'white', padding: '10px', position: 'relative' }}
          justifyContent="space-between">
          <TestConditionWidget order={order} />
        </Grid>
      )}
    </Grid>
  )
}
