/** @jsxImportSource @emotion/react */
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import { Formik } from 'formik'
import { useState } from 'react'
import BsButton from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useGetLabDevicesQuery } from 'store/services/devices'
import {
  usePutNightOwlOnHoldMutation,
  useRemoveNightOwlHoldMutation,
  useRevertNightOwlMutation,
  useLabAobSignRequestMutation,
} from 'store/services/labOrder'
import * as api from 'helpers/api'
import { useGetLabPatientQuery } from 'store/services/patient'
import { Order } from 'store/types'
import { HoldDialog } from '../NightOwl/HoldDialog'
import DeviceSelection from './DeviceSelection'
import SendWelcome from './SendWelcome'
import ShippingSection from './ShippingSection'

interface IProps {
  order: Order
  isOrderCompleted: boolean
}

const TestSmarterFulfillment = ({ order, isOrderCompleted }: IProps) => {
  const { orderId } = useParams()
  const { data: patient } = useGetLabPatientQuery({
    id: order.patientId,
  })
  const [holdDialogOpen, setHoldDialogOpen] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [revertTestSmarterFulfillment] = useRevertNightOwlMutation()
  const [putOrderOnHold] = usePutNightOwlOnHoldMutation()
  const [removeOrderFromHold] = useRemoveNightOwlHoldMutation()
  const [sendAobSignRequest] = useLabAobSignRequestMutation()

  const followUpDate = order?.followUpsList?.find((f) => f.namespace === 'TPD')?.date

  const initialValues = {
    sendEmail: true,
    sendSms: false,
    email: patient?.email || '',
    mobilePhone: patient?.mobilePhone?.replace(/^\+1/, '') ?? '',
    searchSerialNumber: '',
    searchMacAddress: '',
    searchLabel: '',
    deviceSerial: null,
  }

  const placeOnHold = (followUpDate: string, note?: string) =>
    putOrderOnHold({
      orderId: Number(orderId),
      followUpDate,
      note,
    })
  const removeHold = () => {
    removeOrderFromHold({ orderId: Number(orderId) })
  }

  const welcomeMessage = Object.values(order?.mobileInvites || {})
    ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    ?.pop()

  const fetchAobTemplatePdf = async () => {
    setLoadingPdf(true)
    const result = await api.get(`/lab/orders/${orderId}/aob-template`, {}, true)
    const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'AOB_Template.pdf')
    document.body.appendChild(link)
    link.click()
    setLoadingPdf(false)
  }
  const aobSignRequest = (method: 'sms' | 'email') => async () => {
    try {
      await sendAobSignRequest({
        orderId: Number(orderId),
        method,
        ...(method === 'sms'
          ? { phone: order?.patient?.mobilePhone }
          : { email: order?.patient?.email }),
      })
      toast.success('AOB request successfully sent.')
    } catch (err) {
      toast.error('Could not send AOB request.')
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={undefined}
      onSubmit={() => {}}>
      {({ values, errors, touched, handleChange, setFieldValue, setFieldTouched, setValues }) => {
        const { data: devices } = useGetLabDevicesQuery({
          serial: order?.thirdPartyDevice?.serial || values.searchSerialNumber,
          mac: values.searchMacAddress,
          label: values.searchLabel,
          brandId: '13,10',
          page: 0,
          size: 100,
        })

        return (
          <div
            css={{
              width: '100%',
              boxSizing: 'border-box',
              padding: '6px 8px',
            }}>
            <HoldDialog
              show={holdDialogOpen}
              onClose={() => setHoldDialogOpen(false)}
              onSubmit={placeOnHold}
            />
            <div
              css={{
                background: '#fff',
                border: '1px solid #ccc',
                borderRadius: 5,
              }}
              className={'p-4'}>
              <p className={'text-lg'}>Device Shipping</p>
              <div className={'d-flex'}>
                <div
                  className={'d-flex align-items-center p-3 mr-2 mb-2'}
                  css={{
                    border: '1px solid #ccc',
                    borderRadius: 3,
                  }}>
                  {followUpDate ? (
                    <>
                      <span>If patient is ready to continue with shipping</span>
                      <BsButton
                        variant={'outline-primary'}
                        onClick={removeHold}
                        className={'d-flex align-items-center ml-4'}>
                        <PauseCircleOutlineIcon className={'mr-2'} /> Remove Order from Hold
                      </BsButton>
                    </>
                  ) : (
                    <>
                      <span>If patient is unreachable or needs time</span>
                      <BsButton
                        variant={'outline-primary'}
                        onClick={() => setHoldDialogOpen(true)}
                        className={'d-flex align-items-center ml-4'}>
                        <PauseCircleOutlineIcon className={'mr-2'} /> Place Order on Hold
                      </BsButton>
                    </>
                  )}
                </div>
                <div
                  className={'d-flex align-items-center p-3 mr-2 mb-2'}
                  css={{
                    border: '1px solid #ccc',
                    borderRadius: 3,
                  }}>
                  <span>If patient cannot proceed with testing</span>
                  <BsButton
                    disabled={isOrderCompleted}
                    variant={'outline-primary'}
                    onClick={() => revertTestSmarterFulfillment({ orderId: Number(orderId) })}
                    className={'d-flex align-items-center ml-4'}>
                    <KeyboardReturnIcon className={'mr-2'} /> Revert to Standard Order
                  </BsButton>
                </div>
                <div
                  className={'d-flex align-items-center p-3 mb-2'}
                  css={{
                    border: '1px solid #ccc',
                    borderRadius: 3,
                  }}>
                  <span>AOB</span>
                  <BsButton
                    disabled={isOrderCompleted}
                    variant={'outline-primary'}
                    onClick={aobSignRequest('sms')}
                    className={'d-flex align-items-center ml-4'}>
                    SMS
                  </BsButton>
                  <BsButton
                    disabled={isOrderCompleted}
                    variant={'outline-primary'}
                    onClick={aobSignRequest('email')}
                    className={'d-flex align-items-center ml-4'}>
                    Email
                  </BsButton>
                  <BsButton
                    disabled={isOrderCompleted || loadingPdf}
                    variant={'outline-primary'}
                    onClick={fetchAobTemplatePdf}
                    className={'d-flex align-items-center ml-4'}>
                    Print
                  </BsButton>
                </div>
              </div>
              <SendWelcome
                testType={order?.testType}
                welcomeMessage={welcomeMessage}
                values={values}
                order={order}
              />
              {welcomeMessage && (
                <DeviceSelection
                  testType={order.testType}
                  devices={devices}
                  startShipping={true}
                  thirdPartyDevice={order?.thirdPartyDevice}
                  values={values}
                  setValues={setValues}
                />
              )}
              {welcomeMessage && order?.thirdPartyDevice?.serial && (
                <ShippingSection order={order} />
              )}
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default TestSmarterFulfillment
