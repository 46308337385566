import { useState } from 'react'
import Radio from '../../atoms/Radio'
import OrderTestForm from './OrderTestForm'
import { IOrderTest } from 'store/types'

interface IProps {
  id: string
  values: any
  setValues: (f: (v: IOrderTest[]) => IOrderTest[]) => void
}

const OrderTestSettings = (props: IProps) => {
  const [currentNight, setCurrentNight] = useState(0)
  const { id, values, setValues } = props

  const addDeleteNight = (type?: string) => {
    if (Array.isArray(values)) {
      const orderTestsCopy = [...values]
      if (type === 'delete') {
        orderTestsCopy.splice(1)
        if (currentNight > 0) {
          setCurrentNight(currentNight - 1)
        }
      } else {
        if (orderTestsCopy.length === 1) {
          orderTestsCopy.push({})
        }
      }
      setValues(orderTestsCopy)
    }
  }

  const setNightValues = (values: IOrderTest) => {
    setValues((prev) => {
      const newVals = [...prev]
      newVals[currentNight] = { ...prev[currentNight], ...values }
      return newVals
    })
  }

  return (
    <form>
      <div className="container-fluid">
        <div className="row form-group">
          <div className="col-sm-6">
            <Radio
              name="nights"
              onChange={() => {
                addDeleteNight('delete')
              }}
              checked={values.length === 1}
              label="1 Night"
            />
          </div>
          <div className="col-sm-6">
            <Radio
              name="nights"
              onChange={() => {
                addDeleteNight()
              }}
              checked={values.length === 2}
              label="2 Nights"
            />
          </div>
        </div>
      </div>
      <div className="container" style={{ width: '100%' }}>
        <div className="card-header border-0 p-0" style={{ borderTop: '0px' }}>
          <ul className="border-bottom-0 nav nav-tabs nav-fill" id="pills-tab" role="tablist">
            {Array.isArray(values) &&
              values.map((row, index) => {
                const radiusStyling = (index) => {
                  return index == currentNight ? '4px 4px 0px 0px' : '4px'
                }
                const tabSpacing = () => {
                  if (values.length === 1) {
                    return ''
                  }
                  if (values.length === 2) {
                    return index === 0 ? '' : 'ml-1'
                  }
                }
                const tabActive = () => {
                  if (values.length === 1) {
                    return 'bg-light border-right border-left border-top'
                  }
                  if (values.length === 2) {
                    return index === currentNight
                      ? 'bg-light border border-bottom-0'
                      : 'border bg-textGrayAccent mb-1'
                  }
                }
                return (
                  <li
                    key={index}
                    id="pills-tab"
                    className={`nav-item ${tabSpacing()} ${tabActive()}`}
                    style={{ borderRadius: `${radiusStyling(index)}` }}>
                    <a
                      className={`py-3 border-0 nav-link ${
                        index == currentNight ? 'border' : 'textGray'
                      }`}
                      style={{ borderRadius: 0 }}
                      id={`custom-tab-night-${index}-tab`}
                      data-toggle="pill"
                      href={`#custom-tab-night-${index}`}
                      role="tab"
                      aria-controls={`custom-tab-night-${index}`}
                      aria-selected="true"
                      onClick={() => {
                        setCurrentNight(index)
                      }}>
                      <span className="font-weight-bold text-black">{`Night ${index + 1}`}</span>
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
        <div className="card-body mb-3 bg-light border" style={{ marginTop: '-1px' }}>
          <div className="tab-content" id="custom-tabs-three-tabContent">
            {Array.isArray(values) &&
              values.map((row, night) => {
                return (
                  <div
                    key={night}
                    className={`tab-pane  ${night === currentNight ? 'show active' : 'fade'}`}
                    id={`custom-tab-night-${night}`}
                    role="tabpanel"
                    aria-labelledby={`custom-tab-night-${night}-tab`}>
                    <OrderTestForm
                      modalId={id}
                      night={night}
                      values={row}
                      setValues={setNightValues}
                    />
                    <div className="row">&nbsp;</div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </form>
  )
}
export default OrderTestSettings
