import { PaginatedQuery, SortableQuery } from 'ts-back-end/dist/ts-back-end/src/api/types/api'
import { baseApi } from './baseApi'
import { Physician, PhysicianGroupDto, PhysicianOrderCounts, PhysicianSettings } from 'store/types'
import {
  ImportPhysicianAddressDto2,
  ImportPhysicianDto2,
  PhysicianGroupOrderStats,
  PhysicianReportItem,
  PhysiciansGroupPhysician,
} from 'ts-back-end/src/business/physician/dto/physician.dto'

type LatestPhysiciansParams = {
  officeId: number | string
  patientId: number | string
  count: number
}

export const physicianApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPhysicians: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/dme/physicians`, params: query }),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Physicians', id }))] : ['Physicians'],
    }),
    getLatestPhysicians: builder.query<Physician[], LatestPhysiciansParams>({
      query: ({ officeId, ...query }) => ({
        url: `/dme/offices/${officeId}/physicians/latest`,
        params: query,
      }),
    }),
    getOfficePhysicians: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/dme/offices/${query.officeId}/physicians/list`, params: query }),
      providesTags: ['PhysicianSettings'],
    }),
    importPhysician: builder.mutation<
      Physician,
      { by?: 'lab' | 'dme'; officeId: string } & ImportPhysicianDto2
    >({
      query: ({ officeId, by = 'dme', ...physician }) => ({
        method: `POST`,
        url: `/${by}/offices/${officeId}/physicians`,
        body: physician,
      }),
    }),
    updatePhysician: builder.mutation<
      Physician,
      { by?: 'lab' | 'dme'; officeId: string } & Partial<Physician>
    >({
      query: ({ officeId, by = 'dme', ...physician }) => ({
        method: `PATCH`,
        url: `/${by}/offices/${officeId}/physicians/${physician.id}`,
        body: physician,
      }),
    }),

    createPhysicianAddress: builder.mutation<
      Physician,
      { by?: 'lab' | 'dme'; officeId: string; physicianId: string } & ImportPhysicianAddressDto2
    >({
      query: ({ officeId, physicianId, by = 'dme', orderId, ...address }) => ({
        method: `POST`,
        url: `/${by}/offices/${officeId}/physicians/${physicianId}/addresses`,
        body: address,
      }),
    }),
    updatePhysicianAddress: builder.mutation<
      Physician,
      {
        by?: 'lab' | 'dme'
        officeId: string
        orderId: number
        physicianId: string
        addressUuid: string
      } & ImportPhysicianAddressDto2
    >({
      query: ({ officeId, physicianId, by = 'dme', orderId, ...address }) => ({
        method: `PATCH`,
        url: `/${by}/offices/${officeId}/physicians/${physicianId}/addresses/${address.uuid}`,
        body: address,
        invalidatesTags: (result, error, { orderId: id, physicianId }) => [
          { type: 'Order', id },
          { type: 'Physicians', id: Number(physicianId) },
        ],
      }),
    }),

    updatePhysicianSettings: builder.mutation<Physician, PhysicianSettings>({
      query: (params) => ({
        method: `PATCH`,
        url: `/dme/offices/${params.officeId}/physicians/${params.id}/settings`,
        body: params,
      }),
      invalidatesTags: (result, error, { id }) => ['PhysicianSettings'],
    }),

    getPhysiciansOrderCounts: builder.query<PhysicianOrderCounts, { officeId: number }>({
      query: ({ officeId, ...query }) => ({
        url: `/dme/offices/${officeId}/physicians/order-counts`,
        params: query,
      }),
    }),

    getPhysiciansStats: builder.query<
      PhysicianOrderCounts,
      SortableQuery<string, PaginatedQuery<{ search: string; officeId: number }>>
    >({
      query: ({ officeId, ...query }) => ({
        url: `/dme/offices/${officeId}/physicians/stats`,
        params: query,
      }),
    }),
    getPhysicianGroups: builder.query<{ id: number; groupName: string }[], { search?: string }>({
      query: ({ ...query }) => ({
        url: `/physician-group/all`,
        params: query,
      }),
    }),
    getPhysicianGroupsList: builder.query<
      PhysicianGroupDto[],
      SortableQuery<string, PaginatedQuery<{ search?: string }>>
    >({
      query: ({ ...query }) => ({
        url: `/physician-group/list`,
        params: query,
      }),
    }),
    getPhysicianGroup: builder.query<PhysicianGroupDto, { id: number }>({
      query: ({ id }) => ({
        url: `/physician-group/${id}`,
      }),
    }),
    getPhysicianGroupPhysicians: builder.query<PhysiciansGroupPhysician[], { id: number }>({
      query: ({ id }) => ({
        url: `/physician-group/${id}/physicians`,
      }),
      providesTags: ['Physicians'],
    }),
    createPhysicianGroup: builder.mutation<PhysicianGroupDto, Omit<PhysicianGroupDto, 'id'>>({
      query: (data) => ({
        method: `POST`,
        url: `/physician-group/create`,
        body: data,
      }),
    }),
    updatePhysicianGroup: builder.mutation<PhysicianGroupDto, PhysicianGroupDto>({
      query: (data) => ({
        method: `PUT`,
        url: `/physician-group/${data.id}`,
        body: data,
      }),
      invalidatesTags: () => ['Physicians']
    }),
    getPhysicianReports: builder.query<
      PhysicianReportItem[],
      SortableQuery<string, PaginatedQuery<{ search?: string; testType?: string; npi?: string }>>
    >({
      query: ({ ...query }) => ({
        url: `/physician-group/physician-reports`,
        params: query,
      }),
    }),
    getPhysicianGroupStats: builder.query<PhysicianGroupOrderStats, {}>({
      query: ({ ...query }) => ({
        url: `/physician-group/order-stats`,
        params: query,
      }),
    }),
  }),
})

export const {
  useGetPhysiciansQuery,
  useGetOfficePhysiciansQuery,
  useGetLatestPhysiciansQuery,
  useCreatePhysicianAddressMutation,
  useUpdatePhysicianAddressMutation,
  useUpdatePhysicianSettingsMutation,
  useImportPhysicianMutation,
  useUpdatePhysicianMutation,
  useGetPhysiciansOrderCountsQuery,
  useGetPhysiciansStatsQuery,
  useGetPhysicianGroupsQuery,
  useGetPhysicianGroupsListQuery,
  useGetPhysicianGroupQuery,
  useGetPhysicianGroupPhysiciansQuery,
  useCreatePhysicianGroupMutation,
  useUpdatePhysicianGroupMutation,
  useGetPhysicianReportsQuery,
  useGetPhysicianGroupStatsQuery,
} = physicianApi
