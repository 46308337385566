import { ReactNode } from 'react'
import moment from 'moment'

import Link from 'components/atoms/Link'
import groups from 'constants/orderResultGroups'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import { TestCode } from 'store/types'
import { formatPhoneNumber } from 'helpers/format'

export const printIcon = (cell, row, idx: number, context: 'LAB' | 'DME', testType: TestCode) => {
  // console.log(cell, row)
  let icon
  if (cell.icon) {
    if (cell.icon_text) {
      icon = <small style={{ fontSize: '0.9rem' }}>{cell.icon}</small>
    } else {
      if (
        cell.name === 'UPLOAD' &&
        testType !== 'HST' &&
        ['G1', 'G1A', 'G2', 'G3'].includes(row.progress.UPLOAD)
      ) {
        icon = <small style={{ fontSize: '0.9rem' }}>{row.progress.UPLOAD}</small>
      } else {
        icon = <i className={`fa fa-fw ${cell.icon}`}></i>
      }
    }
  }

  const classMap = {
    PENDING: 'pending border-secondary',
    PROVIDED: 'provided',
    PENDING_REVIEW: 'provided',
    ON_REVIEW: 'provided',
    PROMISED: 'incomplete border-white',
    WAITING: 'incomplete',
    FAILED: 'failed',
    WARNING: 'failed',
    DONE: 'verified',
    DONE_RX: 'verified',
    G1: 'verified',
    G2: 'verified',
    G1A: 'verified',
    G3: 'dark',
  }
  const cellState =
    cell.name === 'ON'
      ? row.officeNotes === 'DONE' || row.officeNotes === 'DONE_RX'
        ? row.progress?.RX === 'DONE'
          ? 'DONE'
          : 'PROVIDED'
        : row.officeNotes === 'PROMISED'
        ? 'PROMISED'
        : 'PENDING'
      : row.progress?.[cell.name]
  const contentClass = classMap[cellState] ?? 'secondary'

  const Tooltip = renderTooltip(cell, row, context, testType)
  const Component = cell.to ? Link : 'div'

  return (
    <Component
      className={`${!!contentClass ? '' : 'disabled'} btn btn-sm mr-1 ${
        contentClass == '' ? 'border border-secondary' : ''
      } btn-${contentClass}`}
      to={cell.to}>
      {Tooltip ? <Tooltip>{icon}</Tooltip> : icon}
    </Component>
  )
}

const tooltipProgressDesc = {
  PENDING: 'not yet uploaded or faxed in',
  PROVIDED: 'uploaded',
  PENDING_REVIEW: 'uploaded pending review',
  ON_REVIEW: 'uploaded and under review',
  PROMISED: 'awaiting receipt of fax',
  WAITING: 'not yet uploaded or faxed in',
  FAILED: 'failed lab verification',
  WARNING: 'failed lab verification',
  DONE: 'uploaded and approved',
}

const renderTooltip = (
  section: any,
  data: any,
  context: 'LAB' | 'DME',
  testType: TestCode,
): null | (() => ReactNode) => {
  let tooltip: { customContent: JSX.Element; customHeader?: JSX.Element } | null = null
  if (section.name === 'COMMUNICATION') {
    tooltip = data?.faxing?.length && {
      customContent: (
        <div className="p-2">
          {data?.faxing?.map((fax) => (
            <p className="m-0 mb-1">
              {fax.status} {groups[fax.reportGroup]}:<br />{' '}
              {moment(fax.when).format('MM/DD/YYYY HH:mm a')}
            </p>
          ))}
        </div>
      ),
      customHeader: <>Fax Status</>,
    }
  } else if (section.name === 'UPLOAD') {
    const { progress } = data
    tooltip =
      data?.faxing?.length && testType !== 'HST'
        ? {
            customContent: (
              <div className="p-2">
                {data?.faxing?.map((fax) => (
                  <p className="m-0">
                    Report #{fax.reportId}:{' '}
                    {groups[fax.reportGroup] ? groups[fax.reportGroup] : 'unknown'}
                  </p>
                ))}
              </div>
            ),
            customHeader: <>Report Status</>,
          }
        : {
            customContent: (
              <div className="px-3 py-1">
                {progress.UPLOAD === 'PENDING' &&
                  (testType === 'HST' ? 'Report pending' : 'Device not yet uploaded')}
                {progress.UPLOAD === 'WAITING' &&
                  (testType === 'HST'
                    ? 'Report pending'
                    : 'Uploaded received pending session selection')}
                {progress.UPLOAD === 'G1' &&
                  (testType === 'HST' ? 'Report completed' : 'Device uploaded')}
              </div>
            ),
          }
  } else if (section.name === 'PATIENT') {
    const { patient } = data || {}
    const address = patient?.address

    tooltip = patient && {
      customContent: (
        <div className="px-3 py-1">
          {address?.primaryAddressStreetLine1 ?? ''}
          <br />
          {address?.primaryAddressCity ?? ''}, {address?.primaryAddressState ?? ''}{' '}
          {address?.primaryAddressZip ?? ''} <br />
          {patient.homePhone ? formatPhoneNumber(patient.homePhone) : ''}
        </div>
      ),
      customHeader: (
        <>
          Patient: {patient?.firstName} {patient?.lastName}
        </>
      ),
    }
  } else if (section.name === 'PHYSICIAN') {
    const { physician } = data || {}
    tooltip = {
      customContent: (
        <div className="px-3 py-1">
          {physician?.lastName
            ? `Dr. ${physician.firstName} ${physician.lastName}`
            : 'Physician not yet assigned to this order'}
        </div>
      ),
    }
  } else if (section.name === 'PAYER') {
    const { payers } = data || {}
    tooltip = payers?.length
      ? {
          customContent: (
            <div className="px-3 py-1">
              {payers?.map((payer) => (
                <p className="m-0">
                  {payer.dmeName}{' '}
                  {context === 'LAB' && (
                    <>
                      <i className="fa fa-arrow-right" />{' '}
                      {payer.name ?? payer.names?.[0] ?? 'Not Assigned'}
                    </>
                  )}
                </p>
              ))}
            </div>
          ),
        }
      : null
  } else if (section.name === 'RX') {
    const { progress } = data || {}
    tooltip = {
      customContent: <div className="px-3 py-1">RX form {tooltipProgressDesc[progress.RX]}.</div>,
    }
  } else if (section.name === 'AOB' && testType !== 'HST') {
    const { progress } = data || {}
    tooltip = {
      customContent: <div className="px-3 py-1">AOB form {tooltipProgressDesc[progress.AOB]}.</div>,
    }
  }

  if (!tooltip) return null
  return ({ children }) => <PopoverWrapper {...tooltip}>{children}</PopoverWrapper>
}
