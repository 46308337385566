/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react'
import { Routes, Route, useParams, useLocation, useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import moment from 'moment'

import HstVerify from './Verify'
import PatientDetails from './PatientDetails'
import SleepinessScale from './SleepinessScale'
import AOB from './AOB'
import SignatureRequests from './SignatureRequests'

import { HstPaperworkStepper } from './ProgressBar'
import { getSteps } from './getSteps'
import { useLazyGetOrderQuery } from 'store/services/dmeOrder'
import {
  useStartPatientPaperworkMutation,
  useSavePatientPaperworkMutation,
  useFinishPatientPaperworkMutation,
} from 'store/services/patientPaperwork'
import { useCreateCheckoutMutation } from 'store/services/labOrder'
import { HstPatientPaperworkDto } from 'store/types'

const HstLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { orderId } = useParams()
  const showStepper = useMediaQuery('(min-width:600px)')
  const [getOrder, { currentData: order, data: test }] = useLazyGetOrderQuery()
  const [startPatientPaperwork] = useStartPatientPaperworkMutation()
  const [savePatientPaperwork] = useSavePatientPaperworkMutation()
  const [finishPatientPaperwork] = useFinishPatientPaperworkMutation()
  const [createCheckout] = useCreateCheckoutMutation()
  const initialRedirect = useRef(false)

  const paymentComplete = order?.state?.HST?.REQUIRED?.RESULT?.SCHEDULING?.PAYMENT === 'DONE'

  const steps = getSteps('OS')
  const activeStep = steps.find((step) => step.path && location.pathname.endsWith(step.path))

  const nextStep = () => navigate(steps[(activeStep?.idx || 0) + 1].path)

  useEffect(() => {
    if (orderId && localStorage.getItem('access_token')) {
      getOrder(Number(orderId))
    }
  }, [orderId, getOrder])

  useEffect(() => {
    if (order && !order.hst?.paperwork?.meta?.startedAt) {
      startPatientPaperwork(Number(order.id))
    }
    if (
      order &&
      (!order.hst?.paymentSession ||
        moment().isAfter(moment(order.hst?.paymentSession?.expires_at * 1000)))
    ) {
      createCheckout({
        orderId: Number(order.id),
        amount: Number(order?.copay),
      })
    }
    if (order && !initialRedirect.current) {
      initialRedirect.current = true
      if (paymentComplete && order.hst?.paperwork?.meta?.finishedAt) {
        navigate('/landing/paperwork/complete')
      } else if (
        order.hst?.paperwork?.meta?.finishedAt &&
        !paymentComplete &&
        order.hst?.paymentSession?.url &&
        moment().isBefore(moment(order.hst?.paymentSession?.expires_at * 1000))
      ) {
        window.location.href = order.hst?.paymentSession?.url
      } else if (order.hst?.paperwork?.meta?.completedStep) {
        const lastCompletedStep = steps.findIndex(
          (step) => step.completedStep === order.hst?.paperwork?.meta?.completedStep,
        )
        navigate(steps[lastCompletedStep + 1].path)
      }
    }
  }, [order, startPatientPaperwork])

  const savePaperwork = async (data: HstPatientPaperworkDto) => {
    try {
      await savePatientPaperwork(data).unwrap()
    } catch (err) {
      console.log('err', err)
      if (err.status === 401) {
        localStorage.removeItem('access_token')
        navigate('/')
      }
    }
  }

  const finishPaperwork = async (data) => {
    try {
      await finishPatientPaperwork(data).unwrap()
    } catch (err) {
      console.log('err', err)
      if (err.status === 401) {
        localStorage.removeItem('access_token')
        navigate('/')
      }
    }
  }

  const props = {
    nextStep,
    order,
    savePatientPaperwork: savePaperwork,
  }

  return (
    <div className="">
      {showStepper ? (
        <HstPaperworkStepper
          activeStep={activeStep?.idx || 0}
          steps={steps.map((step) => step.label)}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center w-100 text-success mb-3"
          css={{ fontSize: '1.3rem' }}>
          Step {(activeStep?.idx || 0) + 1} of {steps.length}
        </div>
      )}
      <Routes>
        <Route path={`patient-details`} element={<PatientDetails {...props} />} />
        <Route path={`sleepiness-scale`} element={<SleepinessScale {...props} />} />
        <Route path={`aob`} element={<AOB {...props} />} />
        <Route
          path={`signature-requests`}
          element={<SignatureRequests {...props} finishPaperwork={finishPaperwork} />}
        />

        <Route path={``} element={<HstVerify {...props} getOrder={getOrder} />} />
      </Routes>
    </div>
  )
}

export default HstLayout
