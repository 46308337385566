import { useEffect } from 'react'
import Radio from 'components/atoms/Radio'
import Select from 'components/atoms/Select'
import Input from 'components/atoms/Input'
import { handleChange } from 'components/atoms/Upsert'
import {
  deviceTypeArray,
  oxygenArray,
  papTypeArray,
  ventilatorTypeArray,
  pressureOptions,
  bipapPressureOptions,
} from 'components/dme/constants/testDeviceParams'
import { IOrderTest } from 'store/types'

interface IProps {
  modalId: string
  night: number
  values: IOrderTest
  setValues: (v: IOrderTest) => void
}

const OrderTestForm = (props: IProps) => {
  const modalId = props.modalId ?? 'new_order'
  const { night, values, setValues } = props

  useEffect(() => {
    if (values) {
      if (values.deviceType === 'PAP' && values.ventilatorType) {
        setValues({ ventilatorType: undefined })
      } else if (values.deviceType === 'PAP' && values.papType) {
        if (
          values.papType === 'APAP' &&
          (values.bipapI > 0 || values.bipapE > 0 || values.cpap > 0)
        ) {
          setValues({
            bipapI: 0,
            bipapE: 0,
            cpap: 0,
          })
        } else if (
          values.papType === 'BiPAP' &&
          (values.apapMin > 0 || values.apapMax > 0 || values.cpap > 0)
        ) {
          setValues({
            apapMin: 0,
            apapMax: 0,
            cpap: 0,
          })
        }
        if (
          values.papType === 'CPAP' &&
          (values.apapMin > 0 || values.apapMax > 0 || values.bipapI > 0 || values.bipapE > 0)
        ) {
          setValues({
            apapMin: 0,
            apapMax: 0,
            bipapI: 0,
            bipapE: 0,
          })
        }
      } else if (values.deviceType === 'Ventilator' && values.papType) {
        setValues({
          papType: undefined,
          apapMin: 0,
          apapMax: 0,
          bipapI: 0,
          bipapE: 0,
          cpap: 0,
        })
      } else if (values.deviceType === 'Dental' && (values.ventilatorType || values.papType)) {
        setValues({
          ventilatorType: undefined,
          papType: undefined,
          apapMin: 0,
          apapMax: 0,
          bipapI: 0,
          bipapE: 0,
          cpap: 0,
        })
      } else if (values.oxygen === 0 && values.oxygenLpm) {
        setValues({ oxygenLpm: undefined })
      } else if (values.deviceType === '' && (values.ventilatorType || values.papType)) {
        console.log(values)
        setValues({
          ventilatorType: undefined,
          papType: undefined,
          apapMin: 0,
          apapMax: 0,
          bipapI: 0,
          bipapE: 0,
          cpap: 0,
        })
      }
    }
  }, [values])

  return (
    <>
      <div className="row">
        <div className="col-sm-12 mb-1">
          <strong>Conditions</strong>
        </div>
      </div>
      {oxygenArray.map((row, index) => {
        return (
          <div key={index} className={`row align-items-center ${index === 0 ? 'mb-3' : ''}`}>
            <Radio
              key={index.toString()}
              wrapperClassName={`${index === 0 ? 'col' : 'col-sm-3'}`}
              name={`oxygen${night}`}
              id={`${row.name}${night}${modalId}`}
              label={row.name}
              value={row.value}
              checked={
                row.value === values.oxygen?.toString() ||
                row.value === (!!values.oxygen ? '1' : '0')
              }
              onChange={(event) => {
                const newVal = Number(event.target.value)
                setValues({ oxygen: newVal })
                setTimeout(() => setValues({ oxygen: newVal }), 100)
              }}
            />
            <div className="col">
              {values.oxygen === 1 && index === 1 && (
                <div className="col-sm-12">
                  <div className="row align-items-center">
                    <div className="col-sm-1">@</div>
                    <div className="col-sm-4">
                      <Input
                        minValue="0"
                        type="number"
                        name="oxygenLpm"
                        value={values.oxygenLpm}
                        handleChange={(event) => {
                          if (event.target.value >= 0) {
                            handleChange(event, values, setValues)
                          }
                        }}
                      />
                    </div>
                    <div className="col-sm-6 ml-0 my-auto">
                      <p className="m-0" style={{ lineHeight: '12px' }}>
                        LPM
                        <br />
                        <small>(Liters Per Minute)</small>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}

      <div className="row mt-3">
        <div className="col-sm-12 mb-1">
          <strong>Device Type</strong>
        </div>
      </div>
      <div className="row">
        {deviceTypeArray.map((row, index) => {
          return (
            <Radio
              wrapperClassName="col-sm-3"
              name={`deviceType${night}`}
              id={`${row.name}${night}${modalId}`}
              label={row.name}
              value={row.value}
              checked={
                values.deviceType === row.value || (!values.deviceType && row.name === 'None')
              }
              onChange={(event) => {
                handleChange({ deviceType: event.target.value }, values, setValues)
              }}
              key={index.toString()}
            />
          )
        })}
      </div>

      {values.deviceType === 'PAP' && (
        <>
          <div className="row mt-3">
            <div className="col-sm-6 mb-1">
              <strong>PAP Type</strong>
            </div>
            {!values.papType && <div className="col-sm-6">&nbsp;</div>}
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="btn-group" role="group">
                {papTypeArray.map((row, index) => {
                  return (
                    <button
                      onClick={() => {
                        setValues({
                          ...values,
                          papType: row.value,
                        })
                      }}
                      key={index}
                      type="button"
                      className={`btn btn-outline-primary ${
                        values.papType === row.value ? 'active' : ''
                      }`}>
                      {row.value}
                    </button>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-12 mb-1">
              <strong>
                {values.papType === 'APAP'
                  ? 'Min - Max Pressure (cmH20)'
                  : values.papType === 'BiPAP'
                    ? 'Inhalation - Exhalation Pressure (cmH20)'
                    : values.papType === 'CPAP'
                      ? 'Pressure (cmH20)'
                      : ''}
              </strong>
            </div>
            {!values.papType && <div className="col-sm-6">&nbsp;</div>}
          </div>
          <div className="row">
            {values.papType === 'APAP' && (
              <>
                <div className="col-sm-3">
                  <Select
                    name="apapMin"
                    value={values.apapMin}
                    options={pressureOptions}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <Select
                    name="apapMax"
                    value={values.apapMax}
                    options={pressureOptions}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
              </>
            )}
            {values.papType === 'BiPAP' && (
              <>
                <div className="col-sm-3">
                  <Select
                    name="bipapI"
                    value={values.bipapI}
                    options={bipapPressureOptions}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <Select
                    name="bipapE"
                    value={values.bipapE}
                    options={bipapPressureOptions}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
              </>
            )}
            {values.papType === 'CPAP' && (
              <>
                <div className="col-sm-3">
                  <Select
                    name="cpap"
                    value={values.cpap}
                    options={pressureOptions}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
              </>
            )}
            {values.papType === 'OTHER' && (
              <>
                <div className="col-sm-12">
                  <Input
                    name="otherPapComments"
                    label="Comments"
                    value={values.otherPapComments}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {values.deviceType === 'Ventilator' && (
        <>
          <div className="row mt-3">
            <div className="col-sm-12 mb-1">
              <strong>Ventilator Type</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="btn-group" role="group"></div>
              {ventilatorTypeArray.map((row, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      setValues({
                        ...values,
                        ventilatorType: row.value,
                      })
                    }}
                    type="button"
                    className={`btn btn-outline-primary ${
                      values.ventilatorType === row.value ? 'active' : ''
                    }`}>
                    {row.value}
                  </button>
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default OrderTestForm
