/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { skipToken } from '@reduxjs/toolkit/query'
import moment from 'moment'

import { useAppSelector } from 'hooks/redux'
import { CustomDateInput } from '../../atoms/DateTimePicker'
import { TimeWrapper } from '../../lab/orderEdit/HstWorkflow/FollowUpForm'
import { Chart } from './Chart'
import styled from '@emotion/styled'
import CountsBlock from './CountsBlock'
import Button from 'components/atoms/Button'
import { useGetOneShipCountsQuery, useGetDailyCountsQuery } from 'store/services/managementReports'
import { useGetCompaniesQuery, useGetLabOfficesQuery } from 'store/services/office'

const timeRangeOptions = [
  { value: 'select', label: 'Select My Own Date Range' },
  { value: 'this-week', label: 'This Week' },
  { value: 'last-week', label: 'Last Week' },
  { value: 'this-month', label: 'This Month' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'this-year', label: 'This Year' },
  { value: 'last-year', label: 'Last Year' },
]

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 240px);
  padding: 0 10px 10px 10px;

  Table {
    thead:not(.table_filters) {
      background: white;
      position: sticky;
      top: 0px;
      margin: 0 0 0 0;
      z-index: 1;
    }
    td {
      vertical-align: middle;
    }
  }
`

const initialSearchVals = {
  search: '',
  companyId: '',
  regionUuid: '',
  officeId: '',
}

interface SearchForm {
  companyId: string
  officeId: string
}

export const Stats = () => {
  const [companySearch, setCompanySearch] = useState('')
  const [values, setValues] = useState<SearchForm>(initialSearchVals)
  const [timeRange, setTimeRange] = useState({ value: 'this-month', label: 'This Month' })
  const [dateRange, setDateRange] = useState<{
    dateStart: string | undefined
    dateEnd: string | undefined
  }>({
    dateStart: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString(),
    dateEnd: new Date().toISOString(),
  })

  const { data: companies } = useGetCompaniesQuery({
    name: companySearch || undefined,
  })
  const { data: offices } = useGetLabOfficesQuery(
    values.companyId ? { companyId: values.companyId } : skipToken,
  )
  const companyOptions = companies?.map((company) => ({
    label: company.companyName,
    value: company.id,
  }))
  const officesOptions =
    useMemo(
      () =>
        offices
          ?.filter((o) => !values.regionId || o.companyRegionUuid === values.regionId)
          .map((office) => ({
            label: `${office.officeName} (${office.addressCity}, ${office.addressState})`,
            value: office.id,
          })),
      [offices, values],
    ) || []

  const [customStartDate, setCustomStartDate] = useState<Date | null>(null)
  const [customEndDate, setCustomEndDate] = useState<Date | null>(null)

  const [showDateRangeSettings, setShowDateRangeSettings] = useState(false)

  const { data: dailyCountsCompleted, refetch: refetchCompleted } = useGetDailyCountsQuery({
    from: dateRange.dateStart!,
    to: dateRange.dateEnd!,
    dateMode: 'day',
    groupBy: 'faxedAt',
    ...(values.companyId && { companyIds: [values.companyId] }),
    ...(values.officeId && { officeIds: [values.officeId] }),
  })
  const dailyCountsCompletedData = useMemo(
    () =>
      dailyCountsCompleted?.length
        ? dailyCountsCompleted?.map((item: any) => ({
            label: moment(item.date, 'YYYY-MM-DD').format('MMM D'),
            value: item.total,
          }))
        : [],
    [dailyCountsCompleted],
  )

  const { data: dailyCountsCreated, refetch: refetchCreated } = useGetDailyCountsQuery({
    from: dateRange.dateStart!,
    to: dateRange.dateEnd!,
    dateMode: 'day',
    groupBy: 'createdAt',
    ...(values.companyId && { companyIds: [values.companyId] }),
    ...(values.officeId && { officeIds: [values.officeId] }),
  })

  const dailyCountsCreatedData = useMemo(
    () =>
      dailyCountsCreated?.length
        ? dailyCountsCreated?.map((item: any) => ({
            label: moment(item.date, 'YYYY-MM-DD').format('MMM D'),
            value: item.total,
          }))
        : [],
    [dailyCountsCreated],
  )

  const { data: oneShipCounts, refetch: refetchOneShip } = useGetOneShipCountsQuery({
    shippedFrom: dateRange.dateStart!,
    shippedTo: dateRange.dateEnd!,
    ...(values.companyId && { companyIds: [values.companyId] }),
    ...(values.officeId && { officeIds: [values.officeId] }),
  })
  const { data: brightreeCounts, refetch: refetchBrightree } = useGetOneShipCountsQuery({
    billedFrom: dateRange.dateStart!,
    billedTo: dateRange.dateEnd!,
  })

  const [nonAdaptBTCounts, adaptBTCounts] = useMemo(
    () =>
      brightreeCounts?.orders?.reduce(
        (acc, item) => {
          if (!item.isBrightree) return acc
          return item.companyId !== 51 ? [acc[0] + 1, acc[1]] : [acc[0], acc[1] + 1]
        },
        [0, 0],
      ) || [],
    [brightreeCounts],
  )

  const refetchAll = () => {
    refetchCompleted()
    refetchCreated()
    refetchOneShip()
    refetchBrightree()
  }

  const setDateRangeAndRefetch = (value: string) => {
    const today = new Date()
    const oneDay = 24 * 60 * 60 * 1000

    setShowDateRangeSettings(false)

    switch (value) {
      case 'all-time': {
        setDateRange({ dateStart: undefined, dateEnd: undefined })
        break
      }
      case 'this-week': {
        const firstDayOfWeek = new Date(
          today.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)),
        )

        const utc = new Date(Date.UTC(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate()))

        setDateRange({ dateStart: utc.toISOString(), dateEnd: new Date().toISOString() })
        break
      }
      case 'last-week': {
        const firstDayOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 6))
        const lastDayOfLastWeek = new Date(firstDayOfLastWeek.getTime() + 6 * oneDay)

        const utc1 = new Date(Date.UTC(firstDayOfLastWeek.getFullYear(), firstDayOfLastWeek.getMonth(), firstDayOfLastWeek.getDate()))
        const utc2 = new Date(Date.UTC(lastDayOfLastWeek.getFullYear(), lastDayOfLastWeek.getMonth(), lastDayOfLastWeek.getDate()))

        setDateRange({
          dateStart: utc1.toISOString(),
          dateEnd: utc2.toISOString(),
        })
        break
      }
      case 'last-two-weeks': {
        const firstDayOfLastTwoWeeks = new Date(
          today.setDate(today.getDate() - today.getDay() - 13),
        )
        const lastDayOfLastTwoWeeks = new Date(firstDayOfLastTwoWeeks.getTime() + 13 * oneDay)

        const utc1 = new Date(Date.UTC(firstDayOfLastTwoWeeks.getFullYear(), firstDayOfLastTwoWeeks.getMonth(), firstDayOfLastTwoWeeks.getDate()))
        const utc2 = new Date(Date.UTC(lastDayOfLastTwoWeeks.getFullYear(), lastDayOfLastTwoWeeks.getMonth(), lastDayOfLastTwoWeeks.getDate()))

        setDateRange({
          dateStart: utc1.toISOString(),
          dateEnd: utc2.toISOString(),
        })
        break
      }
      case 'this-month': {
        const firstDayOfThisMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))

        setDateRange({
          dateStart: firstDayOfThisMonth.toISOString(),
          dateEnd: new Date().toISOString(),
        })
        break
      }
      case 'last-month': {
        const firstDayOfLastMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth() - 1, 1))
        const lastDayOfLastMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 0))

        setDateRange({
          dateStart: firstDayOfLastMonth.toISOString(),
          dateEnd: lastDayOfLastMonth.toISOString(),
        })
        break
      }
      case 'last-three-months': {
        const today = new Date()

        const startOfMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))

        const firstDayOfLastThreeMonths = new Date(
          startOfMonth.setMonth(startOfMonth.getMonth() - 3),
        )

        startOfMonth.setMonth(startOfMonth.getMonth() + 3)

        const lastDayOfLastThreeMonths = new Date(startOfMonth.setDate(startOfMonth.getDate() - 1))

        const utc1 = new Date(Date.UTC(firstDayOfLastThreeMonths.getFullYear(), firstDayOfLastThreeMonths.getMonth(), firstDayOfLastThreeMonths.getDate()))
        const utc2 = new Date(Date.UTC(lastDayOfLastThreeMonths.getFullYear(), lastDayOfLastThreeMonths.getMonth(), lastDayOfLastThreeMonths.getDate()))

        setDateRange({
          dateStart: utc1.toISOString(),
          dateEnd: utc2.toISOString(),
        })
        break
      }
      case 'this-year': {
        const firstDayOfThisYear = new Date(Date.UTC(today.getFullYear(), 0, 1))

        setDateRange({
          dateStart: firstDayOfThisYear.toISOString(),
          dateEnd: new Date().toISOString(),
        })
        break
      }
      case 'last-year': {
        const firstDayOfLastYear = new Date(Date.UTC(today.getFullYear() - 1, 0, 1))
        const lastDayOfLastYear = new Date(Date.UTC(today.getFullYear(), 0, 0))

        setDateRange({
          dateStart: firstDayOfLastYear.toISOString(),
          dateEnd: lastDayOfLastYear.toISOString(),
        })
        break
      }
      case 'select': {
        setShowDateRangeSettings(true)
        return
      }
    }

    refetchAll()
  }

  const [yellow, green, blue, gray, red] = ['#e69c37', '#4ea45f', '#2c83bd', '#b9b9b9', '#d42020']

  return (
    <div>
      <div className={'px-3'}>
        <div className="row px-4 mb-4">
          <div className="col-3">
            <p
              className={'m-0 p-0 pt-4 mb-2'}
              css={{
                fontSize: '18px',
              }}>
              Order Stats Report by Date Range
            </p>
            <Select
              className="basic-single mb-2"
              classNamePrefix="select"
              isSearchable
              placeholder="Select Time Range"
              name="time_range"
              onChange={(item) => {
                if (!item) return
                setTimeRange(item)
                setDateRangeAndRefetch(item.value)
              }}
              options={timeRangeOptions}
              value={timeRange}
              css={{
                width: 300,
              }}
            />
            <p>
              {!showDateRangeSettings ? (
                <>
                  Date Range:{' '}
                  {timeRange.value === 'all-time'
                    ? 'All Time'
                    : `${new Date(dateRange.dateStart!).toLocaleDateString()} - ${new Date(
                        dateRange.dateEnd!,
                      ).toLocaleDateString()}`}
                </>
              ) : null}
            </p>
            {showDateRangeSettings ? (
              <TimeWrapper
                css={{
                  marginBottom: 20,
                  width: 300,
                }}>
                <div style={{ fontWeight: '500' }} className="mb-1">
                  Select Date Range
                </div>
                <DatePicker
                  selectsRange={true}
                  startDate={customStartDate}
                  endDate={customEndDate}
                  onChange={(update) => {
                    setCustomStartDate(update[0])
                    setCustomEndDate(update[1])

                    if (update[0] && update[1]) {
                      const utc1 = new Date(Date.UTC(update[0].getFullYear(), update[0].getMonth(), update[0].getDate()))
                      const utc2 = new Date(Date.UTC(update[1].getFullYear(), update[1].getMonth(), update[1].getDate()))

                      setDateRange({
                        dateStart: utc1.toISOString(),
                        dateEnd: utc2.toISOString(),
                      })

                      refetchAll()
                    }
                  }}
                  customInput={<CustomDateInput />}
                  isClearable
                />
              </TimeWrapper>
            ) : null}
          </div>
          <div className="col-3 d-flex flex-column justify-content-center">
            <label css={{ fontWeight: 'normal !important' }}>Company</label>
            <Select
              className="mb-3"
              isClearable
              name="companyId"
              value={companyOptions?.find((c) => c?.value == values.companyId) ?? null}
              options={companyOptions}
              onInputChange={(v) => v && setCompanySearch(v)}
              onChange={(event) => {
                console.log(event)
                setValues((p) => ({ ...p, companyId: event?.value }))
              }}
            />
          </div>
          <div className="col-3 d-flex flex-column justify-content-center">
            <label css={{ fontWeight: 'normal !important' }}>Office</label>
            <Select
              className="mb-3"
              isClearable
              name="officeId"
              value={officesOptions.find((c) => c?.value == values.officeId) ?? null}
              options={officesOptions}
              onChange={(event) => {
                setValues((p) => ({ ...p, officeId: event?.value }))
              }}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <Button
              label="Clear"
              className="btn btn-default mx-2 mt-2"
              onClick={() => {
                setValues(initialSearchVals)
                setCompanySearch('')
              }}
            />
          </div>
        </div>

        <p
          className={'m-0 p-0 my-4'}
          css={{
            fontSize: '18px',
          }}>
          Completed Orders by Day
        </p>
        <div css={{ marginBottom: '15px' }}>
          <Chart datapoints={dailyCountsCompletedData} />
        </div>
        <p
          className={'m-0 p-0 my-4'}
          css={{
            fontSize: '18px',
          }}>
          Created Orders by Day
        </p>
        <div>
          <Chart datapoints={dailyCountsCreatedData} />
        </div>

        <div
          className="d-flex justify-content-between pb-4 mt-4"
          css={{ gap: 15, marginTop: '15px', minHeight: '150px' }}>
          <CountsBlock
            title={'OneShip Orders Shipped'}
            count={oneShipCounts?.totals?.shipped || 'N/A'}
            color={green}
            loading={false}
            containerStyle={{ flex: 1 }}
          />
          <CountsBlock
            title={'Brightree Orders Completed (Adapt)'}
            count={adaptBTCounts || 'N/A'}
            color={green}
            loading={false}
            containerStyle={{ flex: 1 }}
          />
          <CountsBlock
            title={'Brightree Orders Completed (Non-Adapt)'}
            count={nonAdaptBTCounts || 'N/A'}
            color={green}
            loading={false}
            containerStyle={{ flex: 1 }}
          />
        </div>
      </div>
    </div>
  )
}

export default Stats
