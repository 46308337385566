/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react'
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom'
import { pick } from 'lodash'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from 'formik'
import Toast from 'components/atoms/Toast'
import Select from 'react-select'

import { confirm } from 'components/molecules/Confirmation'
import {
	useDeleteUserMutation,
	useSendResetPasswordEmailMutation, useCreatePhysicianGroupUserMutation, useUpdateLabUserMutation,
} from 'store/services/users'
import * as Yup from "yup";
import {useGetPhysicianGroupsQuery} from "../../../store/services/physician";
import DashboardContent from '../atoms/DashboardContent'
import { useGetLabUserQuery } from 'store/services/users'
import routes from '../constants/routes'
import { skipToken } from '@reduxjs/toolkit/query/react'

interface FormValues {
	firstName: '',
	lastName: '',
	email: '',
	physicianGroup: '',
}

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	physicianGroup: '',
}

const validation = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	physicianGroup: Yup.number().required('Required'),
	email: Yup.string()
		.required('Required')
		.email('Invalid email'),
})

const PhysicianGroupUserUpsert = () => {
	const navigate = useNavigate()
	const { userId } = useParams()
	const [searchParams] = useSearchParams()
	const [physicianGroupsSearch, setPhysicianGroupsSearch] = useState('')
	const [createPhysicianGroupUser] = useCreatePhysicianGroupUserMutation()
	const { data: physicianGroups } = useGetPhysicianGroupsQuery({
		search: physicianGroupsSearch || undefined,
	})
	const [updateUser] = useUpdateLabUserMutation()
	const [deleteUser] = useDeleteUserMutation()
	const [sendResetPasswordEmail] = useSendResetPasswordEmailMutation()
	const [physicianGroup, setPhysicianGroup] = useState<any>(searchParams.get('physicianGroup'))

	const physicianGroupOptions = physicianGroups?.map((physicianGroup) => ({
		label: physicianGroup.groupName,
		value: physicianGroup.id,
	}))

	const { data: user } = useGetLabUserQuery(userId ? { id: Number(userId) } : skipToken)

	const formInit = useMemo(
		() => ({
			...initialValues,
			physicianGroup,
			...(user && {
				...pick(user, ['email', 'firstName', 'lastName']),
				physicianGroup: user?.physicianGroup,
			}),
		}),
		[user],
	)

	return (
		<div>
			<DashboardContent
				icon="fas fa-people"
				title={userId ? 'Edit User' : 'Create User'}
				subtitle={
					<>
						<Link to={`${routes.index}${routes.physicianGroupUsers.root}${routes.physicianGroupUsers.directory}`}>
							{'< Back to User List'}
						</Link>
						<div css={{ position: 'absolute', top: '15px', right: '15px', display: 'flex' }}>
							{userId && (
								<>
									<Button
										variant="outline-danger"
										css={{ marginRight: 10 }}
										onClick={() =>
											confirm('user')
											.then(async () => {
												await deleteUser({ id: Number(userId) }).unwrap()
												navigate(
													`${routes.index}${routes.physicianGroupUsers.root}${routes.physicianGroupUsers.directory}`,
												)
											})
											.catch(() => {})
										}>
										Delete User
									</Button>
									{!user?.isSsoEnabled && (
										<Button
											variant="outline-danger"
											css={{}}
											onClick={() => {
												confirm('', {
													title: 'Are you sure?',
													description: 'This will send a password reset email to this user.',
													yesAction: 'Yes, proceed',
													noAction: 'Cancel',
												})
												.then(async () => {
													try {
														await sendResetPasswordEmail({ email: user?.email }).unwrap()
														Toast({ type: 'success', label: `Password reset email sent.` })
													} catch (err) {
														Toast({ type: 'error', label: `Error sending password reset email.` })
													}
												})
												.catch(() => {})
											}}>
											Reset Password
										</Button>
									)}
								</>
							)}
						</div>
					</>
				}
				breadcrumb={[
					{
						label: 'Users',
						to: `${routes.index}${routes.physicianGroupUsers.root}${routes.physicianGroupUsers.directory}`,
					},
					{ label: userId ? 'Edit' : 'New' },
				]}
				content={
					<Formik
						enableReinitialize
						validationSchema={validation}
						initialValues={formInit}
						onSubmit={async (values) => {
							const common = {
								physicianGroup: values.physicianGroup,
								firstName: values.firstName,
								lastName: values.lastName,
								email: values.email
							}

							console.log(common)

							try {
								if (userId) {
									await updateUser({
										id: Number(userId),
										...common,
									}).unwrap()
									Toast({ type: 'success', label: `User updated!` })
								} else {
									await createPhysicianGroupUser({
										...common,
									}).unwrap()
									Toast({ type: 'success', label: `User created!` })
								}
								navigate(`${routes.index}${routes.physicianGroupUsers.root}${routes.physicianGroupUsers.directory}`)
							} catch (err) {
								Toast({ type: 'error', label: err.data.message })
							}
						}}>
						{({ values, errors, touched, handleChange, handleSubmit, setFieldValue, setValues }) => (
							<Form id="userUpsert">
								<div className="card-body row">
									<div className="col-md-9">
										<div className="card card-primary p-2 mb-0">
											<div
												className="card-header d-flex"
												css={{
													color: 'black !important',
													backgroundColor: 'transparent !important',
													justifyContent: 'space-between',
													'&::after': {
														display: 'none',
													},
												}}>
												<h3 className="card-title">PHYSICIAN GROUP USER INFORMATION</h3>
											</div>
											<div className={`container bg-white mb-3 px-1 p-0`}>
												<div className={`container pt-3 pb-2`}>
													<div className="row mb-3">
														<div className="col-6">
															<label className="text-gray">
																Physician Group <span className="text-danger">*</span>
															</label>
															<Select
																isClearable
																onInputChange={(v) => v && setPhysicianGroupsSearch(v)}
																name="physicianGroup"
																value={
																	physicianGroupOptions?.find(
																		(c) => c?.value == values.physicianGroup,
																	) ?? null
																}
																options={physicianGroupOptions}
																onChange={(event) => {
																	const val = event?.value
																	setPhysicianGroup(val)
																	setFieldValue('physicianGroup', val)
																}}
															/>
															{touched.physicianGroup && errors.physicianGroup && (
																<div className="text-danger">Required</div>
															)}
														</div>
													</div>
													<div className="row mb-1">
														<div className="col-6 form-group textGray">
															<label htmlFor="firstName">
																First Name
																<span className="text-danger">*</span>
															</label>
															<Field
																type="text"
																name="firstName"
																className="form-control"
																placeholder="First Name"
																value={values.firstName}
																onChange={handleChange}
																autoComplete="one-time-code"
															/>
															{touched.firstName && errors.firstName && (
																<div className="text-danger">{errors.firstName}</div>
															)}
														</div>
														<div className="col-6 form-group textGray">
															<label htmlFor="lastName">
																Last Name
																<span className="text-danger">*</span>
															</label>
															<Field
																type="text"
																name="lastName"
																className="form-control"
																placeholder="Last Name"
																value={values.lastName}
																onChange={handleChange}
																autoComplete="one-time-code"
															/>
															{touched.lastName && errors.lastName && (
																<div className="text-danger">{errors.lastName}</div>
															)}
														</div>
													</div>
													<div className="row mb-3">
														<div className="col-6 form-group textGray">
															<label htmlFor="email">Email</label>
															<span className="text-danger">*</span>
															<Field
																type="text"
																name="email"
																className="form-control"
																placeholder="Email"
																value={values.email}
																onChange={handleChange}
															/>
															{touched.email && errors.email && (
																<div className="text-danger">{errors.email}</div>
															)}
														</div>
													</div>
												</div>
												<div className="col-sm-12 pt-3 pr-0 border-top">
                          <div className="col-sm-12 d-flex justify-content-end">
														<Button type="submit" form="userUpsert" variant="primary">
															Save Changes
														</Button>
                          </div>
                        </div>
											</div>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				}
			/>
		</div>
	)
}

export default PhysicianGroupUserUpsert
