/** @jsxImportSource @emotion/react */
import { SyntheticEvent, ReactNode, useRef } from 'react'
import { Field, FormikErrors, FormikTouched } from 'formik'

import { TooltipWrapper } from 'components/atoms/TooltipWrapper'
import { CheckboxInput } from 'components/atoms/Checkbox'
import Button from 'components/atoms/Button'
import TabHeader from 'components/atoms/TabHeader'
import states from 'constants/states'
import suffixes from '../constants/suffixes'
import MaskInput from 'components/atoms/MaskInput'
import InputMask from 'components/atoms/InputMask'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import { getAddressFields } from './utils/userFormUtils'
import * as generalHelper from 'helpers/generalHelper'
import NavigateWarning from 'components/atoms/NavigateWarning'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import { useRecognitionState } from 'helpers/rxRecognitionHelper'
import { Order } from 'store/types'

interface FormValues {
  firstName: string
  lastName: string
  middleName?: string
  suffix: string
  dob: string
  gender: string
  language: string
  ssn: string
  homePhone: string
  mobilePhone: string
  email: string
  heightFt: string
  heightIn: string
  weight: string
  primaryAddressStreetLine1: string
  primaryAddressStreetLine2: string
  primaryAddressCity: string
  primaryAddressState: string
  primaryAddressZip: string
  deliverySameAsPrimary: boolean
  deliveryAddressStreetLine1: string
  deliveryAddressStreetLine2: string
  deliveryAddressCity: string
  deliveryAddressState: string
  deliveryAddressZip: string
}

interface IProps {
  values: FormValues
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  handleChange: (e: SyntheticEvent) => void
  setFieldValue: (k: string, v: string) => void
  titleLabel?: string
  handleView: () => void
  route: string
  initialValues: { [key: string]: string }
  isNewOrder?: boolean
  actionButtons: ReactNode
  modalView: boolean
  context: 'LAB' | 'DME'
  promptOnNavigation?: boolean
  order?: Order
  disabledValues?: FormikTouched<FormValues>
}

const PatientForm = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  titleLabel,
  handleView,
  route,
  initialValues,
  isNewOrder,
  actionButtons,
  modalView,
  context,
  promptOnNavigation,
  order,
  disabledValues = {},
}: IProps) => {
  const submitRef = useRef(null)
  // A hacky solution to check if form is rendered to a tab or not
  const inTab = window.location.href.indexOf('patientEdit') > 0
  const hasErrors = Object.keys(errors || {}).length > 0
  const formIsDirty = JSON.stringify(initialValues) === JSON.stringify(values)
  const { patientFormMarkup, rxPatientData, rxContactData } = useRecognitionState(order)

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(!!promptOnNavigation)

  return (
    <div
      className={`container bg-white mb-3 px-3 ${inTab ? 'tsBoxBorder tsBoxShadow' : 'p-0'}`}
      css={patientFormMarkup}>
      <NavigateWarning
        showDialog={showPrompt}
        content={
          <div css={{ lineHeight: '1.3rem' }}>
            {hasErrors
              ? 'Patient form is not completed.  Do you want to fix the errors before navigating away?'
              : 'You have unsaved changes. Do you want to save before navigating away?'}
          </div>
        }
        cancelNavigation={confirmNavigation}
        confirmNavigation={() => {
          if (hasErrors) {
            return cancelNavigation()
          }

          submitRef.current?.click()
          confirmNavigation()
        }}
        alternateConfirmLabel={hasErrors ? 'Finish form' : 'Save'}
      />
      {!modalView ? (
        isNewOrder ? (
          <>
            <TabHeader
              title="Patient Information"
              next={route || ''}
              actionButtons={actionButtons}
            />
            <hr className="mt-0" />
          </>
        ) : (
          <TabHeader
            title="Patient Information"
            next={route || ''}
            actionButtons={[
              <Button
                className="mx-1"
                id="btn-ts"
                label="Save Changes"
                type="submit"
                disabled={formIsDirty}
                ref={submitRef}
              />,
            ]}
          />
        )
      ) : (
        <></>
      )}

      {/* Patient General Information Section */}
      <div className={`container ${isNewOrder ? '' : 'pt-3 pb-2'}`}>
        <div className="row">
          <div className="col-3 form-group textGray">
            <TooltipWrapper text={`Rx: ${rxPatientData?.first_name}`} show={!!rxPatientData}>
              <label htmlFor="firstName">
                First Name
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>

            <Field
              disabled={disabledValues.firstName}
              type="text"
              name="firstName"
              className="form-control"
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
              autoComplete="one-time-code"
            />
            {touched.firstName && errors.firstName ? (
              <div className="text-danger">{errors.firstName}</div>
            ) : (
              ''
            )}
          </div>
          <div className="col-3 form-group textGray">
            <TooltipWrapper text={`Rx: ${rxPatientData?.middle_name}`} show={!!rxPatientData}>
              <label htmlFor="middleName">Middle Name / Initial</label>
            </TooltipWrapper>
            <Field
              disabled={disabledValues.middleName}
              type="text"
              name="middleName"
              className="form-control"
              placeholder="Middle Name"
              value={values.middleName}
              onChange={handleChange}
              autoComplete="one-time-code"
            />
          </div>
          <div className="col-3 form-group textGray">
            <TooltipWrapper text={`Rx: ${rxPatientData?.last_name}`} show={!!rxPatientData}>
              <label htmlFor="lastName">
                Last Name
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <Field
              disabled={disabledValues.lastName}
              type="text"
              name="lastName"
              className="form-control"
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
              autoComplete="one-time-code"
            />
            {touched.lastName && errors.lastName ? (
              <div className="text-danger">{errors.lastName}</div>
            ) : (
              ''
            )}
          </div>
          <div className="col-3 form-group textGray">
            <label htmlFor="suffix">Suffix</label>
            <Field
              className="form-control"
              as="select"
              name="suffix"
              disabled={disabledValues.suffix}>
              {suffixes?.map((s, i) => (
                <option key={i} value={s.value}>
                  {s.name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-3 form-group textGray">
            <TooltipWrapper text={`Rx: ${rxPatientData?.DOB}`} show={!!rxPatientData}>
              <label htmlFor="dob">
                Date Of Birth
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <InputMask
              disabled={disabledValues.dob}
              value={values.dob ?? ' '}
              className="form-control"
              name="dob"
              onChange={handleChange}
              mask="99/99/9999"
            />
            {touched.dob && errors.dob ? <div className="text-danger">Required</div> : ''}
          </div>
          <div className="col-3 form-group textGray">
            <TooltipWrapper text={`Rx: ${rxPatientData?.gender}`} show={!!rxPatientData}>
              <label htmlFor="gender">
                Gender <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <Field
              className="form-control"
              as="select"
              name="gender"
              placeholder="Select Gender"
              disabled={disabledValues.gender}>
              <option value="">Select a Gender</option>
              <option value="F">Female</option>
              <option value="M">Male</option>
            </Field>
            {touched.gender && errors.gender ? <div className="text-danger">Required</div> : ''}
          </div>
          {/* <div className="col-3 form-group textGray">
            <label htmlFor="ssn">Social Security Number</label>
            <MaskInput
              value={values.ssn}
              onChange={handleChange}
              name="ssn"
              mask={ssnMask}
              placeholder="###-##-####"
            />
          </div> */}
          {context === 'LAB' && (
            <>
              <div className="col-3 form-group textGray">
                <label htmlFor="height">Height</label>
                <div className="d-flex" css={{ gap: '5px', flexWrap: 'wrap' }}>
                  <div className="input-group" css={{ minWidth: 65, flex: 1 }}>
                    <Field
                      type="number"
                      name="heightFt"
                      className="form-control"
                      placeholder="Feet"
                      min={1}
                      value={values.heightFt}
                      onChange={handleChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">ft</span>
                    </div>
                  </div>
                  <div className="input-group" css={{ minWidth: 65, flex: 1 }}>
                    <Field
                      type="number"
                      name="heightIn"
                      className="form-control"
                      placeholder="Inches"
                      min={0}
                      value={values.heightIn}
                      onChange={handleChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">in</span>
                    </div>
                  </div>
                  {touched.heightFt && errors.heightFt ? (
                    <div className="text-danger">{errors.heightFt}</div>
                  ) : (
                    ''
                  )}
                  {touched.heightIn && errors.heightIn ? (
                    <div className="text-danger">{errors.heightIn}</div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-3 form-group textGray">
                <label htmlFor="weight">Weight</label>
                <div className="input-group">
                  <Field
                    type="number"
                    name="weight"
                    className="form-control"
                    placeholder="Weight"
                    min={0}
                    value={values.weight}
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">lbs</span>
                  </div>
                </div>
                {touched.weight && errors.weight ? (
                  <div className="text-danger">{errors.weight}</div>
                ) : null}
              </div>
            </>
          )}
        </div>

        <div className="row">
          <div className="col-3 form-group textGray">
            <TooltipWrapper
              text={`Rx: ${rxContactData?.residential_address?.line_1}`}
              show={!!rxPatientData}>
              <label htmlFor="primaryAddressStreetLine1">
                {generalHelper.t('Primary Address')}
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <GoogleAutocomplete
              id="address"
              name="address"
              onInputChanged={(v) => setFieldValue('primaryAddressStreetLine1', v)}
              onChange={(place) => {
                const { address, city, state, zip } = getAddressFields(place, 'address')
                console.log(address, city, state, zip)
                setFieldValue('primaryAddressStreetLine1', address)
                setFieldValue('primaryAddressCity', city)
                setFieldValue('primaryAddressState', state)
                setFieldValue('primaryAddressZip', zip)
              }}
            />
            {touched.primaryAddressStreetLine1 && errors.primaryAddressStreetLine1 ? (
              <div className="text-danger">{errors.primaryAddressStreetLine1}</div>
            ) : null}
          </div>
          {/* <div className="col-12 form-group textGray">
            <Field
              type="text"
              name="primaryAddressStreetLine2"
              className="form-control"
              placeholder="Suite / Apt"
            />
          </div> */}
          <div className="col-3 form-group textGray">
            <TooltipWrapper
              text={`Rx: ${rxContactData?.residential_address?.city}`}
              show={!!rxPatientData}>
              <label htmlFor="primaryAddressCity">
                City
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <Field
              type="text"
              name="primaryAddressCity"
              className="form-control"
              placeholder="City"
            />
            {touched.primaryAddressCity && errors.primaryAddressCity ? (
              <div className="text-danger">{errors.primaryAddressCity}</div>
            ) : null}
          </div>
          <div className="col-3 form-group textGray">
            <TooltipWrapper
              text={`Rx: ${rxContactData?.residential_address?.state}`}
              show={!!rxPatientData}>
              <label htmlFor="primaryAddressState">
                State
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <Field
              className="form-control"
              as="select"
              name="primaryAddressState"
              placeholder="State">
              {states &&
                states.map((s, i) => (
                  <option key={i} value={s.id}>
                    {s.name}
                  </option>
                ))}
            </Field>
            {touched.primaryAddressState && errors.primaryAddressState ? (
              <div className="text-danger">{errors.primaryAddressState}</div>
            ) : null}
          </div>
          <div className="col-3 form-group textGray">
            <TooltipWrapper
              text={`Rx: ${rxContactData?.residential_address?.zip_code}`}
              show={!!rxPatientData}>
              <label htmlFor="primaryAddressZip">
                Zip Code
                <span className="text-danger">*</span>
              </label>
            </TooltipWrapper>
            <MaskInput
              value={values.primaryAddressZip}
              onChange={handleChange}
              name="primaryAddressZip"
              mask={zipMask}
              placeholder="#####-####"
            />

            {touched.primaryAddressZip && errors.primaryAddressZip ? (
              <div className="text-danger">{errors.primaryAddressZip}</div>
            ) : null}
          </div>
        </div>

        {!inTab ? (
          <div className="row">
            <div className="col-3 py-2 form-group textGray">
              <TooltipWrapper text={`Rx: ${rxContactData?.phone}`} show={!!rxPatientData}>
                <label htmlFor="homePhone">
                  Home Phone
                  <span className="text-danger">*</span>
                </label>
              </TooltipWrapper>
              <MaskInput
                value={values.homePhone}
                onChange={handleChange}
                name="homePhone"
                mask={phoneNumberMask}
                placeholder="(###) ###-####"
                autoComplete="off"
              />
              {touched.homePhone && errors.homePhone ? (
                <div className="text-danger">{errors.homePhone}</div>
              ) : (
                ''
              )}
            </div>
            <div className="col-9 py-2" css={{ background: '#e0e0e0', borderRadius: '5px' }}>
              <div className="row pl-2">
                <div className="col-4 form-group textGray">
                  <label htmlFor="mobilePhone">Mobile Phone</label>
                  <MaskInput
                    value={values.mobilePhone}
                    onChange={handleChange}
                    name="mobilePhone"
                    mask={phoneNumberMask}
                    placeholder="(###) ###-####"
                    autoComplete="off"
                  />
                  {touched.mobilePhone && errors.mobilePhone ? (
                    <div className="text-danger">{errors.mobilePhone}</div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-8 form-group textGray">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={values.email}
                    autoComplete="off"
                  />
                  {touched.email && errors.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : (
                    ''
                  )}
                </div>
                * Mobile Phone or Email Address is required to send AOB to patient for electronic
                signature
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      {/* Patient Contact Information Section */}
      {inTab ? (
        <div className="container py-2 px-3">
          <div className="row pb-3">
            <div className="text-bold col-12">{titleLabel || 'Patient Contact Information'}</div>
          </div>
          <div className="row">
            <div className="col-6 form-group textGray">
              <label htmlFor="homePhone">
                Home Telephone
                <span className="text-danger">*</span>
              </label>
              <MaskInput
                value={values.homePhone}
                onChange={handleChange}
                name="homePhone"
                mask={phoneNumberMask}
                placeholder="(###) ###-####"
                autoComplete="off"
              />
              {touched.homePhone && errors.homePhone ? (
                <div className="text-danger">{errors.homePhone}</div>
              ) : null}
            </div>
            <div className="col-6 form-group textGray">
              <label htmlFor="mobilePhone">Smart Mobile Telephone</label>
              <MaskInput
                value={values.mobilePhone}
                onChange={handleChange}
                name="mobilePhone"
                mask={phoneNumberMask}
                placeholder="(###) ###-####"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 form-group textGray">
              <label htmlFor="email">Email Address</label>
              <Field
                type="text"
                name="email"
                className="form-control"
                placeholder="E-Mail Address"
                autoComplete="off"
              />
              {touched.email && errors.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </div>
            <div className="col-3 form-group textGray">
              <label className="d-block mb-2">Language</label>
              <div className="form-check form-check-inline">
                <Field
                  className="form-check-input"
                  type="radio"
                  id="English"
                  name="language"
                  value="English"
                />
                <label className="form-check-label" htmlFor="English">
                  English
                </label>
              </div>
              <div className="form-check form-check-inline textGray">
                <Field
                  className="form-check-input"
                  type="radio"
                  id="Spanish"
                  name="language"
                  value="Spanish"
                />
                <label className="form-check-label" htmlFor="Spanish">
                  Spanish
                </label>
              </div>
              {touched.language && errors.language ? (
                <div className="text-danger">{errors.language}</div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className={`container ${isNewOrder ? '' : 'py-2 px-3'} mt-2`}>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="text-bold textGray col">
                {titleLabel || 'Device Delivery Address'}
              </div>
              <div className="col-6 form-check ml-2">
                <Field
                  id="deliverySameAsPrimary"
                  name="deliverySameAsPrimary"
                  component={CheckboxInput}
                  label="Same as Primary"
                />
              </div>
            </div>
          </div>
        </div>
        {values && !values.deliverySameAsPrimary ? (
          <div className="row">
            <div className="col-3 form-group textGray" css={{ paddingTop: 24 }}>
              <GoogleAutocomplete
                id="mail_address"
                name="mail_address"
                onInputChanged={(v) => setFieldValue('deliveryAddressStreetLine1', v)}
                onChange={(place) => {
                  const { address, city, state, zip } = getAddressFields(place, 'mail_address')
                  setFieldValue('deliveryAddressStreetLine1', address)
                  setFieldValue('deliveryAddressCity', city)
                  setFieldValue('deliveryAddressState', state)
                  setFieldValue('deliveryAddressZip', zip)
                }}
              />
            </div>
            {/* <div className="col-3 form-group textGray">
                    <Field
                      type="text"
                      name="deliveryAddressStreetLine2"
                      className="form-control"
                      placeholder="Suite / Apt"
                    />
                  </div> */}
            <div className="col-3 form-group textGray">
              <label htmlFor="deliveryAddressCity">City</label>
              <Field
                type="text"
                name="deliveryAddressCity"
                className="form-control"
                placeholder="City"
              />
            </div>
            <div className="col-3 form-group textGray">
              <label htmlFor="deliveryAddressState">State</label>
              <Field
                className="form-control"
                as="select"
                name="deliveryAddressState"
                placeholder="State">
                {states.map((s, i) => (
                  <option key={i} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-3 form-group textGray">
              <label htmlFor="deliveryAddressZip">Zip Code</label>
              <MaskInput
                value={values.deliveryAddressZip}
                onChange={handleChange}
                name="deliveryAddressZip"
                mask={zipMask}
                placeholder="#####-####"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PatientForm
