import classnames from 'classnames'

import { isInReview } from 'helpers/orderStateHelpers'
import { orderStatusDefinitions } from './definitions'
import { printIcon } from './renderHelpers'
import { Order, TestCode } from 'store/types'

interface IProps {
  context: 'LAB' | 'DME'
  orderData: Order
  testType: TestCode
}

export const OrderProgressBar = ({ context, orderData = {}, testType }: IProps) => {
  const orderStatus = orderStatusDefinitions(context, orderData, testType)
  const { progress, helm } = orderData
  if (!progress) {
    return null
  }

  const isUnderReview = (index: number) => {
    if (index > 1) {
      return false
    }
    if (index === 1) {
      return isInReview(progress?.AOB, helm)
    }
    return ['PATIENT', 'PHYSICIAN', 'PAYER', 'RX'].reduce((agg, curr) => {
      agg = agg || isInReview(progress[curr], helm)
      return agg
    }, false)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
      }}>
      {orderStatus.map((cellGroup, idx) => {
        const underReview = isUnderReview(idx)

        return (
          <>
            {cellGroup[0].prefix && (
              <span className="mx-1" style={{ color: '#C2C8CF' }}>
                {cellGroup[0].prefix}
              </span>
            )}

            <div
              key={`order_icon-wrapper--${idx}`}
              className={classnames('order_icon-wrapper', {
                'order_icon-wraper--pending': underReview,
                'order_icon-wraper--none': !underReview,
              })}
              style={{ display: 'flex' }}>
              {cellGroup.map((cell, idx) => printIcon(cell, orderData, idx, context, testType))}
            </div>

            {cellGroup[0].suffix && (
              <span className="mx-1" style={{ color: '#C2C8CF' }}>
                {cellGroup[0].suffix}
              </span>
            )}
          </>
        )
      })}
    </div>
  )
}
