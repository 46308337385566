import {
  AssignThirdPartyDeviceDto,
  BucketValue,
  CreateOrder,
  DeviceDeliveryScheduledDto,
  DeviceDto,
  Order,
  PatientRelationship,
  Payer,
  SelectedDeviceSessionsDto,
  TestCode,
  UploadType,
} from 'store/types'
import { ChartDatapoint } from '../../components/dme/stats/Chart'
import { baseApi } from './baseApi'

type PhysicianAssignment = {
  orderId: number
  physicianId: number
  addressUuid: string
}

type PayerAssignment = {
  orderId: string
  payers?: Payer[]
}

export type AobRequestPayload = {
  orderId: number
  method: 'sms' | 'email'
  phone?: string
  email?: string
}

export type SignAobRequest = {
  orderId: number
  signature: string
  relationship: PatientRelationship
  signatureDateTime: string
  signedBy?: string
  email: string
}

type OrderListRequest = {
  type: TestCode
  bucket: BucketValue
  page: number
  size: number
  office: number
}

export const orderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query<Order, number>({
      query: (id) => ({ url: `/dme/orders/${id}` }),
      providesTags: (result, error, id) => [{ type: 'Order', id }],
      keepUnusedDataFor: 5,
    }),
    getOrders: builder.query<Order[], OrderListRequest>({
      query: ({ type, ...query }) => ({ url: `/dme/orders/${type}/list`, params: query }),
      providesTags: ['OrderList'],
    }),
    getOrdersCsvReport: builder.query<string, Omit<OrderListRequest, "page" | "size">>({
      query: ({ type, ...query }) => ({ url: `/dme/orders/${type}/csv-report`, params: query }),
      providesTags: ['OrderList'],
    }),
    createOrder: builder.mutation<Order, CreateOrder>({
      query: (order) => ({
        method: `POST`,
        url: `/dme/orders`,
        body: order,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    updateOrder: builder.mutation<Order, Order>({
      query: (order) => ({
        method: `PATCH`,
        url: `/dme/orders/${order.id}`,
        body: order,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    assignPhysician: builder.mutation<Order, PhysicianAssignment>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/physician`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'Physicians' },
      ],
    }),
    modifyPhysician: builder.mutation<Order, PhysicianAssignment>({
      query: (params) => ({
        method: `PATCH`,
        url: `/dme/orders/${params.orderId}/physician`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'Physicians' },
      ],
    }),
    removePhysician: builder.mutation<Order, { orderId: string }>({
      query: ({ orderId }) => ({
        method: `DELETE`,
        url: `/dme/orders/${orderId}/physician`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    updatePayer: builder.mutation<Payer, PayerAssignment>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/payers`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    addFile: builder.mutation<Order, { orderId: number; uploadType: string; fileKey: string }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/files`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    getFile: builder.query<string, { orderId: number; fileKey: string }>({
      query: ({ orderId, ...query }) => ({
        url: `/dme/orders/${orderId}/files`,
        params: query,
      }),
      providesTags: ['Order'],
    }),
    removeFile: builder.mutation<Order, { orderId: number; fileKey: string }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/dme/orders/${params.orderId}/files`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    changeFileCategory: builder.mutation<
      Order,
      { orderId: number; fileKey: string; toUploadType: string }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/dme/orders/${params.orderId}/files/change-category`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    promiseFile: builder.mutation<Order, { orderId: number; promiseType: UploadType }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/promises`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    updateOfficeNotesStatus: builder.mutation<Order, { orderId: number; provided: boolean }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/office-notes`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    submitForVerification: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/confirm-request`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
      ],
    }),
    sendAobSignRequest: builder.mutation<Order, AobRequestPayload>({
      query: (params) => ({
        method: `POST`,
        url: `/dme/orders/${params.orderId}/aob-request`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    getAobSignJwt: builder.query<Order, { orderId: number; aobId: number; dob: string }>({
      query: ({ orderId, aobId, dob }) => ({
        url: `/dme/orders/${orderId}/aob-request/${aobId}`,
        params: { dob },
      }),
    }),
    signAob: builder.mutation<Order, SignAobRequest>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/aob-signature`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    updateDeviceNightOwl: builder.mutation<Order, AssignThirdPartyDeviceDto>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/night-owl`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
      ],
    }),
    updateDeviceDeliveryMethod: builder.mutation<
      Order,
      { orderId: number; method: 'patient' | 'driver' }
    >({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/delivery-method`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
      ],
    }),
    updateDeviceDeliverySchedule: builder.mutation<Order, DeviceDeliveryScheduledDto>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/delivery-schedule`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
      ],
    }),
    updateDeviceReturnMethod: builder.mutation<
      Order,
      { orderId: number; method: 'patient' | 'driver' }
    >({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/return-method`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    updateDeviceReturnSchedule: builder.mutation<Order, { orderId: number; dateTimeFrom: string }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/return-schedule`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
      ],
    }),
    updateDeviceDeliveryDone: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/delivery-done`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    updateDeviceReturnDone: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/return-done`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
      ],
    }),
    getAvailableDevices: builder.query<DeviceDto[], number>({
      query: (orderId) => ({ url: `/dme/orders/${orderId}/devices` }),
    }),
    assignDevice: builder.mutation<Order, { orderId: number; deviceId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/device`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
      ],
    }),
    removeDevice: builder.mutation<Order, { orderId: number; deviceId?: number }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/dme/orders/${params.orderId}/device`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'Devices' },
      ],
    }),
    selectSessions: builder.mutation<Order, SelectedDeviceSessionsDto>({
      query: (params) => ({
        method: `POST`,
        url: `/dme/orders/${params.orderId}/selected-sessions`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    getOrderCounts: builder.query<any[], { officeId: number }>({
      query: (query) => ({ url: `/dme/orders/counts`, params: query }),
      providesTags: ['OrderCounts'],
    }),
    getMenuOrderCounts: builder.query<any, { officeId: number, testType: string }>({
      query: (query) => ({ url: `/dme/orders/menu-counts`, params: query }),
      providesTags: ['OrderCounts'],
    }),
    getOfficeOrderStats: builder.query<any[], { testType: string }>({
      query: (query) => ({ url: `/dme/orders/office-stats`, params: query }),
      providesTags: ['OrderCounts'],
    }),
    getOfficeOrderCounts: builder.query<any, { testType: string }>({
      query: (query) => ({ url: `/dme/orders/office-counts`, params: query }),
      providesTags: ['OrderCounts'],
    }),
    getOrderStats: builder.query<ChartDatapoint[], { officeId: number, testType: string }>({
      query: (query) => ({ url: `/dme/orders/stats`, params: query })
    }),
    getPatientLastOrder: builder.query<Order, number>({
      query: (patientId: number) => ({ url: `/dme/patients/${patientId}/last-order` }),
    }),
    updateHstDevice: builder.mutation<
      Order,
      { orderId: number; deviceType: string; email: string; mobilePhone: string }
    >({
      query: (params) => ({
        method: `PUT`,
        url: `/dme/orders/${params.orderId}/hst-device`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    cancelDmeOrder: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `PUT`,
        url: `/dme/orders/${orderId}/cancel`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    restoreDmeOrder: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `PUT`,
        url: `/dme/orders/${orderId}/restore`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    deleteDeviceData: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `DELETE`,
        url: `/dme/orders/${orderId}/device-data`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    initiateTsFulfillment: builder.mutation<Order, { orderId: number, mobilePhone: string, email: string }>({
      query: (params) => ({
        method: `POST`,
        url: `/dme/orders/${params.orderId}/initiate-ts-fulfillment`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
  }),
})

export const {
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useAssignPhysicianMutation,
  useModifyPhysicianMutation,
  useRemovePhysicianMutation,
  useUpdatePayerMutation,
  useAddFileMutation,
  useRemoveFileMutation,
  usePromiseFileMutation,
  useChangeFileCategoryMutation,
  useUpdateOfficeNotesStatusMutation,
  useSubmitForVerificationMutation,
  useSendAobSignRequestMutation,
  useGetAobSignJwtQuery,
  useSignAobMutation,
  useGetOrdersQuery,
  useLazyGetOrdersCsvReportQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useUpdateDeviceNightOwlMutation,
  useUpdateDeviceDeliveryMethodMutation,
  useUpdateDeviceDeliveryScheduleMutation,
  useUpdateDeviceReturnMethodMutation,
  useUpdateDeviceReturnScheduleMutation,
  useUpdateDeviceDeliveryDoneMutation,
  useUpdateDeviceReturnDoneMutation,
  useGetAvailableDevicesQuery,
  useAssignDeviceMutation,
  useRemoveDeviceMutation,
  useSelectSessionsMutation,
  useGetOrderCountsQuery,
  useGetMenuOrderCountsQuery,
  useGetOfficeOrderStatsQuery,
  useGetOfficeOrderCountsQuery,
  useGetOrderStatsQuery,
  useGetPatientLastOrderQuery,
  useUpdateHstDeviceMutation,
  useCancelDmeOrderMutation,
  useRestoreDmeOrderMutation,
  useDeleteDeviceDataMutation,
  useInitiateTsFulfillmentMutation,
} = orderApi
