/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import styled from '@emotion/styled'
import Spinner from 'react-bootstrap/Spinner'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import * as generalHelper from 'helpers/generalHelper'
import Upload from 'components/atoms/Upload'
import Button from 'components/atoms/Button'
import TitleLead from 'components/atoms/TitleLead'
import TabHeader from 'components/atoms/TabHeader'
import Link from 'components/atoms/Link'
import { setLoader } from 'store/slice/loaderSlice'
import routes from '../../constants/routes'
import { confirm } from 'components/molecules/Confirmation'
import config from 'config/app'
import * as api from 'helpers/api'
import {
  useAddFileMutation,
  useRemoveFileMutation,
  usePromiseFileMutation,
  useUpdateOfficeNotesStatusMutation,
} from 'store/services/dmeOrder'
import { Order } from 'store/types'
import { DocumentListing } from './documents/DocumentListing'

const Checkbox = styled.input`
  margin-top: 0.1rem;
  margin-left: -1.5rem;
`

interface IProps {
  order: Order
}

const faxRxStatus =
  `This order has been marked to receive a fax. The RX ` +
  `form now needs to be faxed to TestSmarter at: ${config?.testSmarterFaxNumber}`

const RxForm = ({ order }: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [officeNotesInRx, setOfficeNotesInRx] = useState(false)
  const loading = useAppSelector((state) => state.loader.row)
  const { orderId } = useParams()
  const [addFile, result] = useAddFileMutation()
  const [removeFile] = useRemoveFileMutation()
  const [promiseFile] = usePromiseFileMutation()
  const [updateOfficeNotesStatus] = useUpdateOfficeNotesStatusMutation()
  const files = order?.files ? Object.values(order.files).filter((f) => f.uploadType === 'RX') : []

  const allFiles = order?.files ? Object.values(order.files || {}) : []
  const fileCount = allFiles.length

  const officeNotesUploaded = order?.files
    ? !!Object.values(order.files).filter((f) => f.uploadType === 'OFFICE_NOTES')?.length
    : false

  useEffect(() => {
    setOfficeNotesInRx(order?.officeNotesInRx)
  }, [order])

  const handleUpload = async (acceptedFiles: File[]) => {
    try {
      dispatch(setLoader(true))
      console.log(acceptedFiles)
      const [file] = acceptedFiles

      const payload = {
        orderId,
        uploadType: 'RX',
      }
      const { url, key } = await api.get(`/dme/upload-url`, {
        ...payload,
        fileName: file.name,
      })

      console.log(url, key)
      await api.s3Put(url, file)
      await addFile({ ...payload, fileKey: key, fileName: file.name })
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setLoader(false))
    }
  }

  const handleRemoveFile = ({ key }) => {
    if (key) {
      confirm('file').then(() => {
        removeFile({ orderId, fileKey: key })
      })
    }
  }

  const headers = [
    // {
    //   columnName: 'File ID',
    //   columnSize: 2,
    //   template: files => (
    //     <>
    //       <span className="border border-secondary rounded p-2">
    //         {files.id}
    //       </span>
    //     </>
    //   ),
    // },
    {
      columnName: 'Document Name',
      columnSize: 5,
      template: (file) => (
        <>
          {(file.url || true) && (
            <a target="_blank" href={file.url} rel="noreferrer">
              {file.filename}
            </a>
          )}
        </>
      ),
    },
    {
      columnName: 'Date',
      columnSize: 5,
      template: (file) => <span>{`${moment(file.uploadDate).format('h:mm a MMM Do, YYYY')}`}</span>,
    },
    {
      columnName: 'Action',
      columnSize: 2,
      template: (file) => (
        <>
          <Link
            to="#"
            label="Remove"
            className="btn btn- btn-sm btn-outline-danger"
            onClick={() => {
              handleRemoveFile(file)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <>
      <div className="container">
        <TabHeader
          title="Prescription Forms"
          back={`${routes.index}${routes.order.edit}${orderId}/insurance`}
          actionButtons={
            order
              ? [
                  files.length ? (
                    order.testType === 'HST' ? (
                      <Button
                        label="Next"
                        onClick={() =>
                          navigate(`${routes.index}${routes.order.edit}${orderId}/officeNotes`)
                        }
                        className="mr-3 btn-primary"
                        css={{ minWidth: '80px' }}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    <>
                      {!order?.promisedFiles?.RX && (
                        <Button
                          label="Fax RX Form"
                          onClick={() => promiseFile({ orderId, promiseType: 'RX' })}
                          className="mr-3 btn-outline-secondary"
                        />
                      )}
                      <Upload
                        disabled={loading}
                        label="Upload RX Form"
                        className="btn btn-primary"
                        handleUpload={handleUpload}>
                        {loading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-1"
                          />
                        )}
                      </Upload>
                    </>
                  ),
                ]
              : []
          }
        />
      </div>
      <div className="container p-4">
        {order?.isBrightree && fileCount > 1 ? (
          <>
            <span>
              <i className="fas fa-exclamation-triangle text-warning mr-2" />
              <strong>
                You submitted multiple documents from Brightree. Please indicate which document is
                the Rx form:
              </strong>
            </span>
            <DocumentListing order={order} files={allFiles} />
          </>
        ) : (
          <div className="row">
            <div className="col-sm-12 mb-2">
              <TitleLead label="Uploaded files" />
            </div>
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row border-bottom my-2 py-2">
                  {Array.isArray(headers) &&
                    headers.map((row, index) => {
                      return (
                        <div
                          className={`col${row?.columnSize ? '-' + row.columnSize : ''} textGray`}
                          key={index}>
                          {row?.columnName}
                        </div>
                      )
                    })}
                </div>
                {Array.isArray(files) &&
                  files.map((dataRow, dataIndex) => {
                    return (
                      <div className="row border rounded py-3" key={dataIndex}>
                        {Array.isArray(headers) &&
                          headers.map((row, index) => {
                            return (
                              <div
                                className={`col${
                                  row?.columnSize ? '-' + row.columnSize : ''
                                } m-auto`}
                                key={index}>
                                {row?.template(dataRow)}
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                {files?.length === 0 && !order?.promisedFiles?.RX && (
                  <div className="card">
                    <div className="card-body text-center font-italic text-muted">
                      {generalHelper.t('No Files have been uploaded to this order')}.
                    </div>
                  </div>
                )}
                {files?.length === 0 && order?.promisedFiles?.RX && (
                  <div className="card">
                    <div className="card-body text-center font-italic text-muted">
                      {faxRxStatus}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {order?.testType === 'HST' && !officeNotesUploaded && (
          <div className="p-1 form-check ml-4 mt-4">
            <Checkbox
              type="checkbox"
              value={officeNotesInRx}
              disabled={files?.length === 0}
              name="payer2"
              className="form-check-input"
              checked={officeNotesInRx}
              onChange={(event) => {
                setOfficeNotesInRx(event.target.checked)

                updateOfficeNotesStatus({
                  orderId: Number(orderId),
                  provided: event.target.checked,
                })
              }}
              id="payer2-checkbox"
            />
            <label className="form-check-label" htmlFor="payer2-checkbox">
              Office notes are included in RX documents
            </label>
          </div>
        )}
      </div>
    </>
  )
}

export default RxForm
